import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { NdisFilesService } from '../ndis-files.service';

@Component({
  selector: 'app-ndis-document-widget',
  templateUrl: './ndis-document-widget.component.html',
  styleUrls: ['./ndis-document-widget.component.scss']
})
export class NdisDocumentWidgetComponent implements OnInit {

  isLoading = false
  isUpdate = false

  @Input() document!: any
  @Input() ndisTradeId!: string
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() isUpdatingEvent = new EventEmitter<any>();
  documentStatus = DocumentStatus
  
  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private uploadService: UploadService,
    private ndisDocumentService: NdisFilesService
  ) { }

  ngOnInit(): void {
  }

  updateOnSuccessEvent(updatedDocument: any) {
    this.document = updatedDocument
    this.isUpdate = false
    this.isUpdatingEvent.emit(false)
  }

  onCancelEvent(event: any) {
    this.isUpdate = false
    this.isUpdatingEvent.emit(false)
  }

  updateDocument(document: any) {
    this.isUpdate = true
    this.isUpdatingEvent.emit(true)
  }

  removeDocumentConfirm(document: any) {
    const dialogRef = this.dialog.open(SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        const isArchived = true
        this.deleteDocument(document, isArchived)
      }
    });
  }

  private deleteDocument(document: any, isArchived: boolean) {
    this.isLoading = true;
    this.ndisDocumentService.deleteDocument(document.id, isArchived).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeDocumentEvent.emit(document.id)
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
