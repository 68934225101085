import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusinessFilesCreateModel, BusinessFilesUpdateModel } from './business-files.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessFilesService {

  private endpoint = environment.endpointURL + '/api/BusinessDocumentNew';

  constructor(private http: HttpClient) { }

  public createBusinessDocumentById(data: BusinessFilesCreateModel): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.post<any>(url, data);
  }

  public deleteBusinessDocument(documentId: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}?id=${documentId}&isArchived=${isArchived}`;
    return this.http.delete<any>(url);
  }

  public updateBusinessDocument(data: BusinessFilesUpdateModel): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.put<any>(url, data);
  }

  public getBusinessDocumentListById(businessInfoId: string): Observable<Document> {
    const url = `${this.endpoint}/GetByBusinessInfoId?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public updateBusinessDocumentStatus(id: string, status: number): Observable<any> {
    const url = `${this.endpoint}/Status?id=${id}&status=${status}`;
    return this.http.patch(url, id);
  }

  public changeBusinessDocumentAnswer(data: any): Observable<any> {
    const url = `${this.endpoint}/ChangeBusinessDocumentAnswer`;
    return this.http.patch(url, data);
  }
}
