import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { AlertService } from '../../../services/alert/alert.service';
import { SharedLocationService } from '../shared-location.service';
import { LocationList } from 'src/app/modules/onboarding/shared/models/location/location.model';

@Component({
  selector: 'app-shared-add-location-widget',
  templateUrl: './shared-add-location-widget.component.html',
  styleUrls: ['./shared-add-location-widget.component.scss']
})
export class SharedAddLocationWidgetComponent implements OnInit{

  @Input() isEditOnly!: boolean
  @Input() locationIndex!: number;
  @Input() addedLocationData?: LocationList | null;

  @Output() onLocationStatusChangeEvent = new EventEmitter<boolean>();
  @Output() onLocationSearchEvent = new EventEmitter<any>();
  @Output() addLocationEvent = new EventEmitter<any>();
  @Output() editLocationEvent = new EventEmitter<any>();
  @Output() removeLocationEvent = new EventEmitter<any>();

  @Output() onRadiusChangeEvent = new EventEmitter<any>();
  @Output() onLocationUpdateEvent = new EventEmitter<any>();

  @ViewChild('search') public searchElementRef!: ElementRef;

  formStateLocation!: FormGroup;
  currentMapSearchData!: any;
  
  isEmptyValues = true;
  isValid = false
  isAdded = false
  isEditing = false
  isSearchSuccess = false

  // MAP Related
  address!: string;
  private geoCoder: any;
  currentLatitude!: number;
  currentLongitude!: number;

  // Detect if user clicked out side of current form
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      if (!this.isEmptyValues && this.isAdded && !this.isEditing) {
        this.disableForm() // Disable form if clicked outside
      }
    }
  }

  constructor(
    private eRef: ElementRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private alertService: AlertService,
    private sharedLocationService: SharedLocationService
  ) {}

  ngOnInit(): void {
    this.formStateLocation = this.fb.group({
      index: [ this.locationIndex, Validators.required],
      name: [ '', Validators.required],
      raduis: ['', Validators.required],
      geoLocation: this.fb.group({
        latitude: [''],
        longitude: [''],
      }),
    });

    if (this.addedLocationData) {
      this.setData()
    }

    this.onFormStatusChnages()
    this.onformValueChnages()
    this.onMapsAPILoader()
    this.onMapDataChangeEvent()
  }

  setData() {
    this.isAdded = true
    this.formStateLocation.controls['name'].setValue(this.addedLocationData?.name)
    this.formStateLocation.controls['raduis'].setValue(this.toKm(this.addedLocationData?.raduis))
    this.formStateLocation.controls['geoLocation'].setValue({
      latitude: this.addedLocationData?.geoLocation.latitude, 
      longitude: this.addedLocationData?.geoLocation.longitude
    })
    this.currentLatitude = this.addedLocationData?.geoLocation.latitude || 1
    this.currentLongitude = this.addedLocationData?.geoLocation.longitude || 1
    this.disableForm()
  }

  onMapDataChangeEvent() {
    this.sharedLocationService.currentLocationData.subscribe( data => {
      this.currentMapSearchData = data
      if (data && (this.currentMapSearchData?.index == this.locationIndex)) {
        this.enableForm()
        this.isSearchSuccess = true
        this.isEmptyValues = false
        this.setAddress(this.currentMapSearchData.latitude, this.currentMapSearchData.longitude)
      }
    })
  }

  // convenience getter
  get f() { return this.formStateLocation.controls; }

  onFormStatusChnages() { // To determine form is VALID or not
    this.formStateLocation.statusChanges.subscribe( status => { 
      this.isValid = (status === 'VALID')
      const canSave = this.isEmptyValues ? true : (this.isValid && this.isAdded)
      this.onLocationStatusChangeEvent.emit(canSave)
    })
  }

  onformValueChnages() { // To determine form is EMPTY or not
    this.formStateLocation.valueChanges.subscribe( value => {

      if (value.raduis) {
        this.onRadiusChangeEvent.emit(value.raduis)
      }
      
      const hasValue = (value.name || value.raduis)
      this.isEmptyValues = (hasValue === undefined)
    })
  }

  onMapsAPILoader() {
    const defaultBounds = {
      north: -28.15702,
      south: -37.50528,
      east: 159.105444,
      west: 140.999279,
    };

    var options = {
      bounds: defaultBounds,
      // componentRestrictions: {country: "at"},
      // fields: ["address_components", "geometry", "icon", "name"],
      // strictBounds: false,
      // types: ["establishment"],
    };

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);
      autocomplete.addListener("place_changed", () => {

        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.currentLatitude = place.geometry.location.lat();
          this.currentLongitude = place.geometry.location.lng();
          this.onSearchChanges(this.currentLatitude, this.currentLongitude)
          this.setAddress(this.currentLatitude, this.currentLongitude)
        });
      });
    });
  }

  onSearchChanges(latitude: number, longitude: number, radius?: number) {
    this.isSearchSuccess = true
    const location = {
      lat: latitude,
      lgt: longitude,
      radius: radius ? radius : 1,
    }
    this.onLocationSearchEvent.emit(location)
  }

  setAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results : any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          this.formStateLocation.controls['name'].setValue(this.address)
          this.formStateLocation.controls['raduis'].setValue(1)
          this.formStateLocation.controls['geoLocation'].setValue({latitude: latitude, longitude: longitude})
          this.sharedLocationService.clearSharedData()
        } else {
          this.alertService.error('No results found')
        }
      } else {
        this.alertService.error('Unable to set the location due to: ' + status)
      }
    });
  }

  resetForm() { // Clear entered values
    this.formStateLocation.controls['name'].setValue('')
    this.formStateLocation.controls['raduis'].setValue('')
    this.onLocationSearchEvent.emit(null)
    this.isEmptyValues = true
  }

  addLocation() {
    this.addLocationEvent.emit(this.formStateLocation.getRawValue())
    this.isAdded = true
    this.isSearchSuccess = false
    this.isEmptyValues = true
    this.disableForm()
  }

  updateLocation() {
    this.onLocationUpdateEvent.emit(this.formStateLocation.getRawValue())
    this.isAdded = true
    this.isSearchSuccess = false
    this.isEmptyValues = true
    this.isEditing = false
    this.disableForm()
  }

  editLocation() {
    this.isEditing = true
    this.enableRadius()
    this.editLocationEvent.emit(this.formStateLocation.getRawValue())
  }

  resetEditForm() { // Clear entered values
    this.setData()
    this.disableRadius()
    this.isEditing = false
  }

  removeLocation() {
    this.removeLocationEvent.emit(this.locationIndex)
  }

  disableForm() { // Clear entered values
    this.formStateLocation.controls['name'].disable()
    this.formStateLocation.controls['raduis'].disable()
  }

  enableForm() { // Clear entered values
    if (!this.isEditOnly) {
      this.formStateLocation.controls['name'].enable()
    }
    this.formStateLocation.controls['raduis'].enable()
  }

  disableRadius() {
    this.formStateLocation.controls['raduis'].disable()
  }

  enableRadius() {
    this.formStateLocation.controls['raduis'].enable()
  }

  // ** Support functions ** //
  toKm(value?: number) : number {
    return value ? value / 1000 : 0
  }
}
