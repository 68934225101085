import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as AWS from 'aws-sdk';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentCategoryType, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { LiabilityDocumentListModel, LiabilityDocumentUpdateModel } from '../liability-files.model';
import { LiabilityFilesService } from '../liability-files.service';


@Component({
  selector: 'app-liability-file-upload-popup',
  templateUrl: './liability-file-upload-popup.component.html',
  styleUrls: ['./liability-file-upload-popup.component.scss'],
  providers: [DatePipe]
})
export class LiabilityFileUploadPopupComponent implements OnInit {

  formLiabilitydoc!: FormGroup;
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  documentStatus !: DocumentStatus;

  submitted = false;
  isLoading = false;
  hasExpiry = false;
  isOtherDoc = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  imagePath!: string;
  currentUserId!: string;
  minExpireData = new Date();
  maxExpireData = new Date();
  minToDate = new Date();
  maxToDate = new Date();

  liabilityDocumentUpdateData = new LiabilityDocumentUpdateModel()

  errorMessageFileType: string =''

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private indemnityService: LiabilityFilesService,
    @Inject(MAT_DIALOG_DATA) public data: { isUpdate: boolean, documentData: any, businessInfoId : string },
    public dialogRef: MatDialogRef<LiabilityFileUploadPopupComponent>
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId;

    // // From - To | Date Default Min Max
    // this.minToDate = new Date;
    // this.maxToDate = this.today;
    // this.minExpireData = new Date('2020/01/01');
    // this.maxExpireData = this.today;

    this.formLiabilitydoc = this.formBuilder.group({
      businessInfoId: [ this.data.businessInfoId],
      group: [ this.documentGroup.Mandatory, Validators.required],
      documentCategory: [this.documentCategory.PublicLiabilityCertificate, Validators.required],
      
      name: [ '', Validators.required],
      link: ['', Validators.required],
      documentType: ['', Validators.required],
      publicLiabilityAmount: ['', [ Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(1000000000.00)]],
      productsLiabilityAmount: ['', [ Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(1000000000.00)]],
      fromDate: [''],
      expireDate: [''],
      policyNumber: [''],
      insuranceProvider: [''],
    });
    
    if (this.data.isUpdate) {
      this.setDocumentUpdateFormData(this.data.documentData);
    }
  }

  // Upload selected image
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    // If invalid
    if (this.formLiabilitydoc.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.data.documentData?.link === this.formLiabilitydoc.value.link) {
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }

  get f() {
    return this.formLiabilitydoc.controls;
  }

  saveForm() {
    if (this.data.isUpdate) {
      this.updateBusinessDocument(this.formLiabilitydoc.getRawValue());
    } else {
      this.createBusinessDocument(this.formLiabilitydoc.getRawValue());
    }
  }

  // Create business document
  private createBusinessDocument(formData: any) {
    this.setDocumentUpdateData(formData)
    this.isLoading = true;
    this.indemnityService.createPublicLiabilityById(this.liabilityDocumentUpdateData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit business document
  private updateBusinessDocument(formData: any) {
    this.setDocumentUpdateData(formData)
    this.isLoading = true;
    this.indemnityService.updatePublicLiability(this.liabilityDocumentUpdateData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Set Document Create / Update Data
  setDocumentUpdateData(documentData: LiabilityDocumentUpdateModel) {
    this.liabilityDocumentUpdateData = documentData
    this.liabilityDocumentUpdateData.publicLiabilityAmount = Math.floor(documentData.publicLiabilityAmount * 100) // to cent
    this.liabilityDocumentUpdateData.productsLiabilityAmount = Math.floor(documentData.productsLiabilityAmount * 100) // to cent
    this.liabilityDocumentUpdateData.expireDate = this.convertDate(documentData.expireDate)!
    this.liabilityDocumentUpdateData.fromDate = this.convertDate(documentData.fromDate)!
  }

  setDocumentUpdateFormData(document: LiabilityDocumentListModel) {
    this.formLiabilitydoc.addControl('id', new FormControl(document.id) );
    this.formLiabilitydoc.controls['documentType'].setValue(document.documentType);
    this.formLiabilitydoc.controls['name'].setValue(document?.name);
    this.formLiabilitydoc.controls['link'].setValue(document.link);

    let liabilityAmount = document.publicLiabilityAmount ? Math.floor(document.publicLiabilityAmount / 100) : ''
    let productsAmount = document.productsLiabilityAmount ? Math.floor(document.productsLiabilityAmount / 100) : ''
    this.formLiabilitydoc.controls['publicLiabilityAmount'].setValue(liabilityAmount);
    this.formLiabilitydoc.controls['productsLiabilityAmount'].setValue(productsAmount);

    const toDate = document?.fromDate ? new Date(document?.fromDate) : null
    this.formLiabilitydoc.controls['fromDate'].setValue(toDate);
    const expDate = document?.expireDate ? new Date(document?.expireDate) : null
    this.formLiabilitydoc.controls['expireDate'].setValue(expDate);

    this.formLiabilitydoc.controls['policyNumber'].setValue(document?.policyNumber);
    this.formLiabilitydoc.controls['insuranceProvider'].setValue(document?.insuranceProvider);
  }
    
  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formLiabilitydoc.value.name
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const fileLocation = `Trade/${this.data.businessInfoId}/Documents/Public_Liability/`+fileName+'.'+ fileExtension;

    const params = {
      Bucket: environment.config.location,
      Key: fileLocation,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formLiabilitydoc.patchValue({
        link: response.Key,
      });

      this.saveForm();
      return true;
    });
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();
    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.formLiabilitydoc.patchValue({
      link: this.fileName,
    });
    // set file extention / doc type
    const currrentDocType = this.getDocumentType(this.fileData.name)
    this.formLiabilitydoc.controls['documentType'].setValue(currrentDocType);
    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };

    const parts  = this.fileName.split(".");
    const fileType = parts[parts.length - 1];
    
    if (fileType != 'png' && fileType != 'jpg' && fileType != 'jpeg' && fileType != 'pdf' && fileType != 'doc' && fileType != 'docx') {
      this.alertService.error('This file type is not allowed');
      this.errorMessageFileType = 'This file type is not allowed';
    } else {
      this.errorMessageFileType = ''
    }
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // Convert Date
  convertDate(date: string) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  clearDate() {
    this.formLiabilitydoc.controls['expireDate'].setValue(null);
  }

  clearFormDate() {
    this.formLiabilitydoc.controls['fromDate'].setValue(null);
  }
}