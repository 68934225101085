import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceArea } from '../../models/location/location.model';
import { StateCoordinates } from 'src/app/modules/onboarding/shared/models/location/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private endpoint = environment.endpointURL + '/api/Location';

  constructor( private http: HttpClient ) { }

  public createServiceArea(data: ServiceArea): Observable<any> {
    const url = `${this.endpoint}/ServiceArea`;
    return this.http.post<any>(url, data);
  }

  public updateServiceArea(data: ServiceArea): Observable<any> {
    const url = `${this.endpoint}/ServiceArea`;
    return this.http.put<any>(url, data);
  }

  public serviceAreaEnableDisable(data: any): Observable<any> {
    const url = `${this.endpoint}/ServiceAreaEnableDisable`;
    return this.http.put<any>(url, data);
  }

  public getServiceAreaList(businessInfoId: string, isArchived?: boolean): Observable<any> {
    const url = `${this.endpoint}/ServiceArea`;
    let params = new HttpParams();
    params = businessInfoId ? params.append('businessInfoId', businessInfoId.toString()) : params;
    params = (typeof isArchived === "boolean") ? params.append('isArchived', isArchived.toString()) : params;
    return this.http.get<any>(url, { params });
  }

  public deleteServiceArea(saID: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}/ServiceArea?id=${saID}&isArchived=${isArchived}`;
    return this.http.delete<any>(url);
  }

  public getStateCoordinate(): Observable<StateCoordinates[]>{
    const url = '/assets/json/australia-state-coordinates.json';
    return this.http.get<StateCoordinates[]>(url);
  }
}