import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuleMatrixService {

  private maintenanceJob = environment.endpointURL + '/api/ModuleMatrix';

  constructor(private http: HttpClient) { }

  public getModulesByUserId(userId: string): Observable<any> {
    const url = `${this.maintenanceJob}/GetModulesByUserId?userId=${userId}`;
    return this.http.get<any>(url);
  }

  public completedModuleMatrix(tradeModuleId: string, pdfLink?: string): Observable<any> {
    const url = `${this.maintenanceJob}/CompletedModuleMatrix?tradeModuleId=${tradeModuleId}&pdfLink=${pdfLink ? pdfLink : null}`;
    return this.http.patch<any>(url, tradeModuleId);
  }
}



