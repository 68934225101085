import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private endpoint = environment.endpointURL+'/api';
  private endpointBusinessCreaditCard = environment.endpointURL+'/api/BusinessCreditCard';
  private endpointUserCard = environment.endpointURL+'/api/UserCard';

  constructor(private http: HttpClient) { }

  // Get Stripe Intent
  public setupIntent(id: string) {
    const url = `${this.endpoint}/Stripe/SetupIntent?userId=${id}`;
    return this.http.post<any>(url, id);
  }

  // Get Stripe By Business InfoId
  public setupIntentByBusinessInfoId(businessInfoId: string) {
    const url = `${this.endpoint}/Stripe/SetupIntentBusiness?businessInfoId=${businessInfoId}`;
    return this.http.post<any>(url, businessInfoId);
  }
  
  // ---- Business Credit Card --- //
  public getByBusinessInfoId(businessInfoId : string) {
    const url = `${this.endpointBusinessCreaditCard}/GetByBusinessInfoId?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public createBusinessCreaditCard(data: any) {
    const url = `${this.endpointBusinessCreaditCard}`;
    return this.http.post<any>(url, data);
  }

  public deleteBusinessCreaditCard(id: string): Observable<any> {
    const url = `${this.endpointBusinessCreaditCard}?cardId=${id}`;
    return this.http.delete<any>(url);
  }

  public setDefaultBusinessCreaditCard(data: any) {
    const url = `${this.endpointBusinessCreaditCard}/SetDefault`;
    return this.http.patch<any>(url, data);
  }

  // --- User Cards --- //
  public createUserCard(data: any){
    const url = `${this.endpointUserCard}`;
    return this.http.post<any>(url, data)
  }

  public getUserCardById(id: string) {
    const url = `${this.endpointUserCard}?userId=${id}`;
    return this.http.get<any>(url)
  }

  public updateUserCardDefault(data: any){
    const url = `${this.endpointUserCard}/SetDeaultCard`;
    return this.http.put<any>(url, data)
  }

  public patchExpDate(data: any){
    const url = `${this.endpointUserCard}`;
    return this.http.patch<any>(url, data)
  }

  public deleteUserCardById(id: string) {
    const url = `${this.endpointUserCard}?cardId=${id}`;
    return this.http.delete<any[]>(url)
  }
}
