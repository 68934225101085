<div class="uploads d-flex align-items-center justify-content-between mb-2">
  <span class="uploads-txt text-md text-uppercase font-bold text-secondary">Uploads</span>
  <img (click)="viewDocumentInfo()" class="me-auto ms-1 pointer" src="/assets/icons/common/info.svg">
  <button 
      *ngIf="isEditable" 
      mat-flat-button 
      class="btn primary w-mid" 
      (click)="uploadDocument()">Please Upload
  </button>
</div>

<div class="documents-list">
  <div class="document-wr" *ngFor="let document of serviceDocumentList; index as i" [ngClass]="{'border-bottom': serviceDocumentList.length !== i+1}">
    <app-shared-service-document-widget 
        (updateDocumentEvent)="updateDocumentEvent($event)" 
        (removeDocumentEvent)="removeDocumentEvent($event)" 
        [serviceDocument]="document"
        [businessId] = "businessInforId"
        [isEditable]="isEditable">
    </app-shared-service-document-widget>
  </div>

  <div *ngIf="(serviceDocumentList?.length === 0)">
    <div *ngIf="isEditable; else placeHolder" class="border bg-light text-center p-3">
      <span class="text-secondary">Please add documents</span>
    </div>
    <ng-template #placeHolder>
      <div class="border bg-light text-center p-3">
        <span class="text-secondary"><i>No documents</i></span>
      </div>
    </ng-template>
  </div>
</div>