export class NdisFiles {}

export class NdisDocumentCreate {
  ndisTradeId!: string;
  state!: number;
  name!: string;
  link!: string;
  expireDate!: string;
  documentStatus!: number;
  isServiceAgreement!: boolean;
  isAcceptedServiceAgreement!: boolean;
}

export class NDISDocumentUpdate {
  id!: string;
  state!: number;
  name!: string;
  link!: string;
  expireDate!: string;
  documentStatus!: number;
}
