<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header font-bold mb-0 mt-1">{{data?.isUpdate ? 'Update Document' : 'Upload Document'}}</p>
  <button mat-icon-button mat-dialog-close><mat-icon>cancel</mat-icon></button>
</div>
<mat-divider></mat-divider>

<div class="popup-wr p-4">
  <form class="mt-0" [formGroup]="formLiabilitydoc" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
            <mat-label>Document name</mat-label>
            <input matInput placeholder="Please type" formControlName="name" required>
            <mat-error *ngIf="submitted && f['name'].errors?.['required']">
              Please enter document name.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 mb-3">
          <h4 class="form-section-title font-bold mb-2">Period of Cover</h4>
          <div class="row">
            <div class="col-6 mb-4">
              <mat-form-field class="w-100 pointer" appearance="legacy">
                <mat-label>From</mat-label>
                <input class="pointer" matInput [matDatepicker]="picker_from" formControlName="fromDate" readonly (click)="picker_from.open()">
                <mat-datepicker-toggle matSuffix [for]="picker_from"></mat-datepicker-toggle>
                <mat-datepicker #picker_from></mat-datepicker>
                <mat-hint class="pointer text-color primary text-uppercase" (click)="clearFormDate()">[clear]</mat-hint>
                <mat-error *ngIf="submitted && f['fromDate'].errors?.['required']">
                  Please select from date.
                </mat-error>
              </mat-form-field>
            </div>
    
            <div class="col-6 mb-4">
              <mat-form-field class="w-100 pointer" appearance="legacy">
                <mat-label>To</mat-label>
                <input class="pointer" matInput [matDatepicker]="picker" formControlName="expireDate" [min]="minExpireData" readonly (click)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint class="pointer text-color primary text-uppercase" (click)="clearDate()">[clear]</mat-hint>
                <mat-error *ngIf="submitted && f['expireDate'].errors?.['required']">
                  Please select to date.
                </mat-error>
              </mat-form-field>
            </div>
    
            <div class="col-6 mb-3">
              <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Policy Number</mat-label>
                <input matInput placeholder="Please type" formControlName="policyNumber" >
              </mat-form-field>
            </div>
    
            <div class="col-6 mb-3">
              <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Name of Insurance Provider</mat-label>
                <input matInput placeholder="Please type" formControlName="insuranceProvider">
              </mat-form-field>
            </div>
          </div>
        </div>
        

        <div class="col-12 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
            <mat-label>Public Liability ($)</mat-label>
            <input matInput placeholder="Please type" formControlName="publicLiabilityAmount" currencyMask>
            <mat-error *ngIf="f['publicLiabilityAmount'].errors?.['pattern']">
              Please enter valid amount.
            </mat-error>
            <mat-error *ngIf="f['publicLiabilityAmount'].errors?.['max']">
              Cannot go higher than 1 Billion
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 mb-3">
          <mat-form-field class="w-100" appearance="legacy">
            <mat-label>Products Liability ($)</mat-label>
            <input matInput placeholder="Please type" formControlName="productsLiabilityAmount" currencyMask>
            <mat-error *ngIf="f['productsLiabilityAmount'].errors?.['pattern']">
              Please enter valid amount.
            </mat-error>
            <mat-error *ngIf="f['productsLiabilityAmount'].errors?.['max']">
              Cannot go higher than 1 Billion
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 mb-3">
          <mat-form-field class="w-100 pointer" appearance="legacy">
            <mat-label>Document</mat-label>
              <input class="pointer" (click)="fileInput.click()" formControlName="link" matInput placeholder="Attach document to upload" readonly required>
              <mat-icon matSuffix (click)="fileInput.click()">file_upload</mat-icon>
              <mat-error *ngIf="submitted && f['link'].errors?.['required']">
                Please select document
              </mat-error>
              <mat-error *ngIf="errorMessageFileType != ''">
                {{errorMessageFileType}}
              </mat-error>

              <input
                hidden
                id="file"
                type="file"
                accept="image/png, image/jpeg, .pdf, .doc, .docx"
                name="image"
                #fileInput
                (change)="fileProgress($event)"
              />
          </mat-form-field>
        </div>

      </div>
    </div>
    <div class="action-btns d-flex align-items-center justify-content-end w-100 mt-3 mr-4">
      <button class="btn primary w-mid" mat-flat-button [disabled]="isLoading">Save</button>
      <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
    </div>
  </form>
</div>
