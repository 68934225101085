import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    ImageCroppedEvent,
    ImageTransform,
    Dimensions,
    base64ToFile,
} from 'ngx-image-cropper';
import { AlertService } from '../../../services/alert/alert.service';

@Component({
    selector: 'app-shared-image-cropper-popup',
    templateUrl: './shared-image-cropper-popup.component.html',
    styleUrls: ['./shared-image-cropper-popup.component.scss'],
})
export class SharedImageCropperPopupComponent implements OnInit {

    @ViewChild('browse') fileInputRef!: ElementRef;

    isLoading = false;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    croppedImageFile: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    selectedFileName!: string;
    
    constructor(
    public dialogRef: MatDialogRef<SharedImageCropperPopupComponent>,
    private alertService: AlertService
    ) {}

    ngOnInit(): void {}

    fileChangeEvent(event: any): void {
        let allowedFileTypes = ['png', 'jpg', 'jpeg']
        let fileName = event?.target.files[0].name.replace(/.*[\/\\]/, '');
        let ext = this.getExtension(fileName)
        if (allowedFileTypes.includes(ext)) {
            this.selectedFileName = fileName
            this.imageChangedEvent = event;
        } else {
            this.alertService.error('Please select an image')
            this.fileInputRef.nativeElement.value = '';
        }
    }

    getExtension(filename: string) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }
    

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageFile = base64ToFile(this.croppedImage);
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
    }

    loadImageFailed() {
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH,
        };
    }

    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    zoomIn() {
        this.scale += 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation,
        };
    }

    save() {
        this.dialogRef.close(this.croppedImageFile);
    }
}
