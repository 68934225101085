import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedLocationService {

  private dataSource = new BehaviorSubject<any>(null);
  currentLocationData = this.dataSource.asObservable();

  constructor() { }

  setLocationData(newLocation: any) { 
    this.dataSource.next(newLocation);
  }

  clearSharedData() {
    this.dataSource.next(null);
  }
}
