import { Pipe, PipeTransform } from '@angular/core';
import { StateType } from 'src/app/core/enums/main.enum';

@Pipe({
  name: 'getStateNameByType'
})
export class GetStateNameByTypePipe implements PipeTransform {

  stateType = StateType

  transform(stateType: number ): string {
    let state = '--'

    switch (stateType) {
      case this.stateType.Victoria:
        state = 'Victoria'
        break;
      case this.stateType.NewSouthWales:
        state = 'New South Wales'
        break;
      case this.stateType.SouthAustralia:
        state = 'South Australia'
        break;
      case this.stateType.Queensland:
        state = 'Queensland'
        break;
      case this.stateType.WesternAustralia:
        state = 'Western Australia'
        break;
      case this.stateType.Tasmania:
        state = 'Tasmania State'
        break;
      case this.stateType.NorthernTerritory:
        state = 'Northern Territory State'
        break;
      case this.stateType.AustralianCapitalTerritory:
        state = 'Australian Capital Territory'
        break;
    }

    return state;
  }


}
