
<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-1">
    <p class="popup-header mb-0 mb-0">Add Invoice</p>
    <button mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
</div>
<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-0 py-0">
    <p class="text-secondary"><small>Please fill the necessary information to get your invoice paid via Paid Now</small></p>
</div>

<mat-divider></mat-divider>

  <mat-stepper [linear]="isLinear" #stepper>

    <!-- Step 01 -->
    <mat-step [stepControl]="firstFormGroup">
        <div class="p-0">
            <mat-divider></mat-divider>

            <div class="d-flex justify-content-between align-items-center m-0 ps-0 pe-0 py-2">
                <p class="popup-header mb-0 mb-0">Please fill out the Invoice Details.</p>
            </div>

            <form class="row mt-0" [formGroup]="firstFormGroup" (ngSubmit)="validateAndMoveToNext(stepper, firstFormGroup)">
                <ng-template matStepLabel>
                    <h6 class="m-0 ps-1 pe-0 py-0 text-color primary">Step 01</h6><p class="m-0 ps-1 pe-0 py-0">Invoice Details</p>
                </ng-template>
                
                <div class="col-6 mb-3">
                    <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Job Reference No</mat-label>
                        <input formControlName="jobReferenceNo" matInput placeholder="Please type">
                        <mat-error *ngIf="f['jobReferenceNo'].errors?.['required']">
                          Please enter Job Reference No.
                        </mat-error>
                    </mat-form-field>
                </div>
          
                <div class="col-6">
                    <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Invoice Amount</mat-label>
                        <input formControlName="invoiceAmount" matInput placeholder="Please type" (change)="calPayable()">
                        <mat-error *ngIf="f['invoiceAmount'].errors?.['required']">
                          Please enter Invoice Amount.
                        </mat-error>
                        <mat-error *ngIf="f['invoiceAmount'].errors?.['pattern']">
                            Please enter a valid Amount.
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <div class="col-lg-6">
                    <mat-form-field class="w-100" appearance="legacy">
                      <mat-label>Payment Period</mat-label>
                      <mat-select formControlName="paymentPeriod" matNativeControl require>
                          <mat-option *ngFor="let paymentPeriod of paymentPeriodList" [value]="paymentPeriod.value">
                              {{paymentPeriod.viewValue}}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="f['paymentPeriod'].errors?.['required']">
                        Please select Payment Period.
                      </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-lg-6">
                    <mat-form-field (click)="fileInput.click()" class="w-100">
                      <mat-label>Invoice</mat-label>
                      <input style="" formControlName="link" matInput placeholder="Upload Invoice">
                      <span class="link primary pointer"  matSuffix> [ BROWSE ]</span>
                    </mat-form-field>
                    <input hidden id="file" type="file" accept="image/png, image/jpeg, .pdf, .doc, .docx" name="image" #fileInput
                      (change)="fileProgress($event)" />
                </div>

                <div class="col-12">
                    <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Invoice Description</mat-label>
                        <input formControlName="description" matInput placeholder="Please type" required>
                        <mat-error *ngIf="f['description'].errors?.['required']">
                            Please enter Description.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="action-btns text-right w-100 mt-1 mr-2 mb-3 d-flex align-items-center justify-content-end">
                    <!-- <button mat-button matStepperNext>Next</button> -->
                    <button class="btn primary shadow-none small-btn ml-auto" mat-button type="submit">Next Step</button>
                    <!-- <mat-spinner class="ms-3" diameter="25" *ngIf="isLoading"></mat-spinner> -->
                </div>
            </form>
        </div>
      
    </mat-step>

    <!-- Step 02 -->
    <mat-step [stepControl]="secondFormGroup">
        <div class="p-0">
            <mat-divider></mat-divider>

            <div class="d-flex justify-content-between align-items-center m-0 ps-0 pe-0 py-2">
                <p class="popup-header mb-0 mb-0">Please fill out the Supervisor Details.</p>
            </div>

            <form class="row mt-0" [formGroup]="secondFormGroup" (ngSubmit)="validateAndMoveToNext(stepper, secondFormGroup)">
                <ng-template matStepLabel>
                    <h6 class="m-0 ps-1 pe-0 py-0 primary" [ngClass]="{'text-color' : stepNo >= 2}">Step 02</h6><p class="m-0 ps-1 pe-0 py-0">Supervisor Details</p>
                </ng-template>

                <div class="col-6">
                    <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Supervisor Name</mat-label>
                        <input formControlName="supervisorName" matInput placeholder="Please type">
                        <mat-error *ngIf="f2['supervisorName'].errors?.['required']">
                          Please enter Supervisor Name.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Supervisor Email</mat-label>
                        <input formControlName="supervisorEmail" matInput placeholder="Please type">
                        <mat-error *ngIf="f2['supervisorEmail'].errors?.['required']">
                          Please enter Supervisor Email.
                        </mat-error>
                        <mat-error *ngIf="f2['supervisorEmail'].errors?.['pattern'] && !f2['supervisorEmail'].errors?.['required']">
                            Please enter a valid Supervisor.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="w-100" appearance="legacy">
                        <mat-label>Supervisor Mobile</mat-label>
                        <input formControlName="supervisorMobile" matInput placeholder="Please type">
                        <mat-error *ngIf="f2['supervisorMobile'].errors?.['required']">
                          Please enter Supervisor Mobile.
                        </mat-error>
                        <mat-error class="custom-error mt-1" *ngIf="f2['supervisorMobile'].errors?.['pattern'] && !f2['supervisorMobile'].errors?.['required']">
                            Please enter a valid Supervisor Mobile.
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <div class="action-btns text-right w-100 mt-1 mr-2 mb-3 d-flex align-items-center justify-content-end">
                    <!-- <button mat-button matStepperPrevious>Back</button> -->
                    <!-- <button mat-button matStepperNext>Next</button> -->
                    <button class="btn primary shadow-none small-btn ml-auto mb-3" mat-button type="submit">Preview</button>
                    <!-- <mat-spinner class="ms-3" diameter="25" *ngIf="isLoading"></mat-spinner> -->
                </div>
            </form>
        </div>
        
    </mat-step>

    <!-- Step 03 -->
    <mat-step>
        <div class="p-0">
            <mat-divider></mat-divider>

            <ng-template matStepLabel>
                <h6 class="m-0 ps-1 pe-0 py-0 primary" [ngClass]="{'text-color' : stepNo >= 3}">Step 03</h6><p class="m-0 ps-1 pe-0 py-0">Preview</p>
            </ng-template>

            <div class="d-flex justify-content-between align-items-center m-0 ps-0 pe-0 py-4">
                <p class="popup-header mb-1">Preview your invoice before submitting.</p>
            </div>

            <div class="tab-inner">

                <section class="basic-info pb-0 px-0 py-0">
                    <div class="info d-flex align-items-center mb-0">
                      <p class="p-label m-0 d-flex justify-content-between text-uppercase mb-1">Job Reference No</p>
                    </div>

                    <div class="d-flex align-items-center py-0">
                        <span class="d-block info-text">{{firstFormGroup.value.jobReferenceNo}}</span>
                    </div>

                    <div class="info d-flex align-items-center mt-2">
                        <p class="p-label m-0 d-flex justify-content-between text-uppercase">Invoice Description</p>
                    </div>

                    <div class="info d-flex align-items-center md-1 mt-0">
                        <span class="d-block info-text">{{firstFormGroup.value.description}}</span>
                    </div>


                    <div class="info d-flex align-items-center mt-2">
                        <p class="p-label m-0 d-flex justify-content-between text-uppercase">Invoice Amoun</p>
                    </div>

                    <div class="info d-flex align-items-center mt-2">
                        <p class="span-lable m-0 d-flex justify-content-between">
                          <span>Invoice Amoun</span>
                          <span class="me-2 mr-2 dash-line"> - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</span>
                        </p>
                        <p class="d-flex align-items-center m-0">
                            <span>${{firstFormGroup.value.invoiceAmount}}</span>
                        </p>
                    </div>

                    <div class="info d-flex align-items-center mt-2">
                        <p class="span-lable m-0 d-flex justify-content-between">
                          <span>Payment Period |</span><span class="payment-period">{{paymentPeriodInfor(firstFormGroup.value.paymentPeriod)}}</span>
                          <span class="me-2 mr-2 dash-line"> - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</span>
                        </p>
                        <p class="d-flex align-items-center m-0">
                            <span>${{firstFormGroup.value.invoiceAmount * 5/100}}</span>
                        </p>
                    </div>

                    <div class="info d-flex align-items-center mt-2">
                        <p class="p-label m-0 d-flex justify-content-between">
                            <span>Total Payable Aomunt</span>
                          <span class="me-2 mr-2 dash-line"> - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</span>
                        </p>
                        <p class="p-label d-flex align-items-center m-0">
                            <span>${{firstFormGroup.value.invoiceAmount - (firstFormGroup.value.invoiceAmount * 5/100)}}</span>
                        </p>
                    </div>

                    <div class="info d-flex align-items-center mt-2">
                        <span class="d-block note-text">*Please note that the payment will be paid after the approval of the supervisor.</span>
                    </div>

                    <div class="info d-flex align-items-center mt-2">
                        <mat-checkbox class="check-box" appearance="standard" (change)="onChangeTermsAndConditions($event)"><span style="font-size: 0.75rem;">By proceeding, I confirm my acceptance of the terms and conditions</span></mat-checkbox>
                        <!-- <p class="m-0 d-flex justify-content-between">By proceeding, I confirm my acceptance of the terms and conditions</p> -->
                    </div>
            
                </section>
              
            </div>


      
            <div class="action-btns text-right w-100 mt-1 mr-2 mb-3 d-flex align-items-center justify-content-end">
                    <!-- <button mat-button matStepperPrevious>Back</button> -->
                    <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
                    <button class="btn primary shadow-none small-btn ml-auto" mat-button (click)="submitInvoice()" [disabled]="!isAcceptTermsConditions">Submit</button>
                    <!-- <mat-spinner class="ms-3" diameter="25" *ngIf="isLoading"></mat-spinner> -->
            </div>
        </div>
    </mat-step>
  </mat-stepper>