import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StateType } from 'src/app/core/enums/main.enum';
import { StateCoordinates } from 'src/app/modules/onboarding/shared/models/location/location.model';
import { AlertService } from '../../../services/alert/alert.service';
import { LocationService } from '../../../services/location/location.service';
import { SharedUpdateLocationPopupComponent } from '../shared-update-location-popup/shared-update-location-popup.component';

@Component({
  selector: 'app-shared-service-area-item-widget',
  templateUrl: './shared-service-area-item-widget.component.html',
  styleUrls: ['./shared-service-area-item-widget.component.scss']
})
export class SharedServiceAreaItemWidgetComponent implements OnInit {

  isLoading = false

  @Output() stateEnableEvent = new EventEmitter<any>();
  @Input() stateCoordinates: StateCoordinates[] = []
  @Input() state!: any
  @Input() businessInfoId!: string
  @Input() tradeId!: string
  @Input() isEditOnly!: boolean

  currentStateCoordinate!: StateCoordinates;
  currentUserId!: string
  currentStateId!: string
  isActiveState = false
  hasLocations = false
  locationCount: number = 0
  currentLocationsList: any[] = []
  statetype = StateType
  currentStateType!: number

  constructor(
    private locationService: LocationService,
    private alertService: AlertService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.currentStateType = this.state.defaultState.value
    this.currentStateCoordinate = this.stateCoordinates.find( e => e.state == this.currentStateType)!
    this.currentUserId = this.tradeId
    this.currentStateId = this.state?.availableState?.id
    this.setActiveState()
  }

  onToggleActive(event: any) {
    const isChecked = event.checked
    const isArchived = !isChecked
    if (this.state?.availableState != null) {
      this.updateArchiveStatus(isArchived)
    } else {
      this.addState()
    }
  }

  addState() {
    const addData = {
      userId: this.currentUserId,
      businessInfoId: this.businessInfoId,
      state: this.state.defaultState.value,
      serviceAreaList: this.state.availableState ? this.state.availableState.serviceAreaList : []
    }
    this.createArea(addData)
  }

  private createArea(data: any) {
    this.isLoading = true
    this.locationService.createServiceArea(data).subscribe({
      next: res => {
        if (res.success) {
          this.state.availableState = res.data
          this.currentStateId = this.state.availableState.id
          this.isActiveState = true
          this.setActiveState()
          this.stateEnableEvent.emit()
          this.alertService.success('Service area enabled successfully')
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      },
      error: err => {
        this.isActiveState = false
        this.alertService.error('Operation failed')
        this.isLoading = false
      }
    })
  }

  updateArchiveStatus(isArchived: boolean) {
    const data = {
      serviceAreaId: this.currentStateId,
      isArchived: isArchived
    }
    this.toggleArchiveState(data)
  }

  private toggleArchiveState(data: any) {
    this.isLoading = true
    this.locationService.serviceAreaEnableDisable(data).subscribe({
      next: res => {
        if (res.success) {
          this.state.availableState = res.data
          this.setActiveState()
          let successMessage = data.isArchived ? 'disabled' : 'enabled'
          this.alertService.success('Service area '+successMessage+' successfully')
          this.stateEnableEvent.emit()
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      },
      error: err => {
        this.isActiveState = !data.isArchived
        this.alertService.error('Operation failed')
        this.isLoading = false
      }
    })
  }

  setActiveState() {
    const isArchived = this.state?.availableState?.isArchived
    this.isActiveState = (isArchived != undefined) ? !isArchived : false
    this.calcLocationCount()
  }

  calcLocationCount() {
    this.locationCount = this.state?.availableState?.serviceAreaDetail.length
    this.hasLocations = ( this.locationCount > 0 )
  }

  // Add Locations (Pins)
  addLocation() {
    const dialogRef = this.dialog.open(SharedUpdateLocationPopupComponent, {
      width: '90%',
      maxWidth: '1100px',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data:{
        isEditOnly: this.isEditOnly,
        stateName: this.state.defaultState.name,
        serviAreaId: this.state?.availableState.id,
        currentLocationList: this.state?.availableState.serviceAreaDetail,
        currentStateCoordinate: this.currentStateCoordinate
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.state.availableState.serviceAreaDetail = result.serviceAreaDetail
        this.calcLocationCount()
      }
    });
  }
}
