import { Component, Input, OnInit } from '@angular/core';
import { DocumentStatus } from 'src/app/core/enums/document.enum';
@Component({
  selector: 'app-ndis-onboarding-file-list-widget',
  templateUrl: './ndis-onboarding-file-list-widget.component.html',
  styleUrls: ['./ndis-onboarding-file-list-widget.component.scss']
})
export class NdisOnboardingFileListWidgetComponent implements OnInit {

  @Input() ndisTradeId!: string
  @Input() businessInfoId!: string
  @Input() documentList!: any[]

  documentStatus = DocumentStatus
  isLoading = false
  isUpdating = false
  isEdit = false

  constructor() { }

  ngOnInit(): void { }

  createDocumentEvent(document: any) {
    this.documentList.push(document)
  }

  updateDocumentEvent(document: any) {
    let updatedIndex = this.documentList.findIndex(e => e.id == document.id)
    this.documentList[updatedIndex] = document
  }

  removeDocumentEvent(deletedDocId: any) {
    this.documentList = this.documentList.filter( e => e.id != deletedDocId)
  }

  isUpdatingEvent(isUpdating: any) {
    this.isUpdating = isUpdating
  }
}
