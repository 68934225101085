<section class="module-container p-3">
  <div *ngIf="(activeModuleList?.length); else NoModulesPlaceHolder" class="row">
    <aside class="tabs col-4 border-end">
      <h4 class="title font-bold text-uppercase mb-3 lh-1">My Modules</h4>

      <section 
        *ngFor="let moduleItem of activeModuleList" 
        class="tab-item px-2 py-2 mb-1 d-flex align-items-center shadow-sm mb-2 pointer" 
        [class.active]="(moduleItem?.tradeModuleId == activeModule?.tradeModuleId)" 
        (click)="viewModule(moduleItem)">
        
        <span class="icon me-2">
          <img src="/assets/icons/common/module-default.svg" alt="">
        </span>

        <div class="d-flex flex-column align-items-start">
          <p class="tab-title m-0 lh-1 mb-2">{{moduleItem.moduleName}}</p>
          <span class="tab-intro lh-1">Johns Lyng Group</span>
        </div>

        <div class="d-flex align-items-center ms-auto">
          <span class="me-2 status font-semi-bold" [class.completed]="moduleItem?.isCompleted">
            {{moduleItem?.isCompleted ? 'Completed' : 'Incomplete'}} 
          </span>
          <svg class="next-icon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="15px"
            viewBox="0 0 24 24" width="24px" fill="#888888">
            <g>
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
            <g>
              <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
            </g>
          </svg>
        </div>
      </section>

    </aside>

    <main class="tab-content col-8">
      <section class="module-wr">
        <div class="placeholder py-3 d-flex flex-column justify-content-center align-items-center">
          <img class="mb-2 icon" src="/assets/icons/common/common-module-ico.svg" [alt]="activeModule?.moduleName">

          <h4 class="module-title font-bold mb-2">{{activeModule?.moduleName}}</h4>

          <span class="status font-semi-bold text-uppercase me-2 mb-3" [class.completed]="activeModule?.isCompleted">
            {{activeModule?.isCompleted ? 'Completed' : 'Incomplete'}} 
          </span>
                  
          <div class="d-flex justify-content-center">
            <button 
              class="action-btn btn primary text-uppercase me-2" 
              mat-flat-button 
              [disabled]="!activeModule?.isCompleted" 
              (click)="viewModulePreview()">
              Preview Module
            </button>
              
            <button 
              class="action-btn btn primary text-uppercase" 
              mat-flat-button 
              [disabled]="activeModule?.isCompleted" 
              (click)="completeModule()">
              {{activeModule?.isCompleted? 'Completed On '+ (activeModule?.completedDate | date :'dd-MM-yyyy') : 'Complete now'}}
            </button>
          </div>
        </div>
      </section>
    </main>
  </div>

  <ng-template #NoModulesPlaceHolder>
    <div class="placeholder d-flex flex-column p-4 justify-content-center align-items-center">
      <img src="/assets/icons/common/module-empty-state.svg" alt="No Modules Available">
      <span class="mt-3 text-secondary font-semi-bold">No Modules Available</span>
    </div>
  </ng-template>
</section>



