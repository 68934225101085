<div class="document-inner-wr">
  <div *ngIf="liabilityDocuments.length>0" class="row mb-1 pt-1">
        <section  *ngFor="let liabilityDocument of liabilityDocuments" class="document-inner-wr mb-1 pt-0">
            <div *ngIf="liabilityDocument" class="document d-flex align-items-start py-2 px-3 border col-12">
                <img class="icon" src="/assets/icons/common/document.svg">
                <div class="ms-2">
                    <div class="title mb-1 d-flex align-items-center">
                        <span class="font-bold lh-1 me-2">{{liabilityDocument.name}}</span>
                        <span *ngIf="!hideStatus">
                            <app-shared-document-status-widget [currentStatus]="liabilityDocument.status"></app-shared-document-status-widget>
                        </span>
                    </div>

                    <div class="d-flex align-items-center">
                        <span class="lbl-txt lh-1 me-2">Updated on {{liabilityDocument.lastUpdatedDate | date:"dd-MM-yyyy"}}</span>
                        <span *ngIf="liabilityDocument?.expireDate" class="lbl-txt lh-1 me-2 border-start ps-2">Expires on {{liabilityDocument.expireDate | date:"dd-MM-yyyy"}}</span>
                    </div>

                    <div class="d-flex align-items-center mt-1">
                        <span class="lbl-txt text-secondary lh-1 me-2">
                            <span>Public Liability: </span>
                            <span *ngIf="liabilityDocument?.publicLiabilityAmount">$ {{ (liabilityDocument.publicLiabilityAmount  / 100) | number: '1.2-2'}}</span>
                            <span *ngIf="!liabilityDocument?.publicLiabilityAmount">--</span>
                        </span>
                        <span class="lbl-txt text-secondary lh-1 me-2  border-start ps-2">
                            <span>Product Liability: </span>
                            <span *ngIf="liabilityDocument?.productsLiabilityAmount">$ {{ (liabilityDocument.productsLiabilityAmount / 100) | number: '1.2-2'}}</span>
                            <span *ngIf="!liabilityDocument?.productsLiabilityAmount">--</span>
                        </span>
                    </div>

                    <div *ngIf="liabilityDocument.status == documentStatus.Rejected" class="d-flex align-items-center mt-1">
                        <span class="lbl-txt text-black lh-1 me-2">Reject Reason: {{liabilityDocument.comment}}</span>
                    </div>
                </div>

                <div class="actions d-flex align-items-center ms-auto">
                    <span class="action-btn me-2">
                        <button mat-icon-button (click)="downloadDocument(liabilityDocument.link, liabilityDocument.name)" class="ketab-btn download d-flex justify-content-center align-items-center">
                            <mat-icon>file_download</mat-icon>
                        </button>
                    </span>
                    
                    <!-- If onboarding user -->
                    <span *ngIf="!(liabilityDocument.status == documentStatus.Exempt || liabilityDocument.status == documentStatus.Verified)">
                        <span class="action-btn">
                            <button mat-icon-button [matMenuTriggerFor]="menu" class="ketab-btn d-flex justify-content-center align-items-center">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="updateLiabilityDocument(liabilityDocument)"><span>Update</span></button>
                                <button *ngIf="isEditable" mat-menu-item (click)="removeDocumentConfirm(liabilityDocument)"><span>Remove</span></button>
                            </mat-menu>
                        </span>
                    </span>
                </div>
            </div>
        </section>
    </div>

    <section 
        class="file-upload-wr d-flex align-items-center justify-content-center py-3 custom-border dashed" 
        (click)="uploadLiabilityDocument()"
        *ngIf="isEditable && liabilityDocuments.length == 0">
        <img class="upload-icon" src="/assets/icons/common/cloud-upload.svg">
        <span class="text-color primary font-semi-bold ml-2">Please upload</span>
    </section>

    <div *ngIf="isLoading" class="inner-preloader d-flex align-items-center justify-content-center">
        <mat-spinner diameter="35"></mat-spinner>
    </div>
</div>