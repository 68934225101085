import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../../services/alert/alert.service';
import { LocationService } from 'src/app/modules/onboarding/shared/services/location/location.service';
import { StateTypeLabelMapping } from './StateTypeLabelMapping';
import { StateType } from 'src/app/core/enums/main.enum';
import { CurrentMapSearchData, FormCreationArray, ServiceAreaList, StateCoordinates, TempLocation, UpdateServiceAreaLocation } from 'src/app/modules/onboarding/shared/models/location/location.model';
import { SharedLocationService } from '../shared-location.service';

@Component({
  selector: 'app-shared-update-location-popup',
  templateUrl: './shared-update-location-popup.component.html',
  styleUrls: ['./shared-update-location-popup.component.scss']
})
export class SharedUpdateLocationPopupComponent implements OnInit {

  @ViewChild('search') public searchElementRef!: ElementRef;
  
  submitted = false
  isLoading = false
  isMapClicked = false
  isLocationSearching = false
  isLocationEditing = false
  canSave = false

  defaultLatitude = environment.map.geoLocations.australia.latitude;
  defaultLongitude = environment.map.geoLocations.australia.longitude;
  currentLatitude!: number;
  currentLongitude!: number;
  zoom = environment.map.zoom;

  //view port restrictions
  countryRestriction = {
    latLngBounds: {
      south: -37.50528,
      west: 140.999279,
      north: -28.15702,
      east: 159.105444
    },
    strictBounds: false
  };

  iconURL = './assets/icons/common/pin.svg'
  stateType = StateType;
  stateTypeLabels = StateTypeLabelMapping;
  keys: string[];

  locationsFormArray: FormCreationArray[] = [];
  tempLocationList: TempLocation[] = [];
  locationsLength = 0;

  currentSearchRadius = 1
  currentSearchIndex!: number
  currentMapSearchData = new CurrentMapSearchData()
  updateServiceAreaData = new UpdateServiceAreaLocation()

  constructor(
    private sharedLocationService: SharedLocationService,
    private alertService: AlertService,
    private locationService: LocationService,
    public dialogRef: MatDialogRef<SharedUpdateLocationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      isEditOnly: boolean,
      stateName: string, 
      serviAreaId: string, 
      currentLocationList: ServiceAreaList[]
      currentStateCoordinate: StateCoordinates,
    },
  ) {
    this.keys = Object.keys(this.stateTypeLabels);
  }

  ngOnInit(): void {
    this.setToDefaultSettings()
    this.setUpdateData()
    this.setCurrentCoordinates()

    if (this.data.currentLocationList) {
      this.createAvailableLocationForms()
      this.createTempLocationList()
    } else {
      this.addNewLocationForm()
    } 
  }

  addNewLocationForm() {
    this.locationsLength = (this.locationsLength + 1)
    const locationItem = {
      index: this.locationsLength,
      locationData: null
    }
    this.locationsFormArray.push(locationItem)
    this.currentSearchIndex = this.locationsLength
  }

  // Create  Arrays
  createAvailableLocationForms() {
    this.data.currentLocationList.forEach( (item, index) => {
      const locationItem = {
        index: index + 1,
        locationData: {
          name: item.name,
          raduis: item.raduis,
          geoLocation: {latitude: item.location.latitude, longitude: item.location.longitude},
        }
      }
      this.locationsFormArray.push(locationItem)
    })
    this.locationsLength = this.locationsFormArray.length
    if (!this.data.isEditOnly) {
      this.addNewLocationForm()
    }
  }

  createTempLocationList() {
    this.tempLocationList = this.data.currentLocationList.map((item, index) => ({ 
      index: index + 1, 
      name: item.name,
      raduis: item.raduis,
      geoLocation: {latitude: item.location.latitude, longitude: item.location.longitude},
    }));
  }

  setToDefaultSettings() {
    this.zoom = 4
    this.currentSearchRadius = 1
    this.isMapClicked = this.isLocationSearching = this.isLocationEditing = false
    this.currentLatitude = this.defaultLatitude
    this.currentLongitude = this.defaultLongitude
    this.currentSearchIndex = this.locationsFormArray.length
    this.canSaveFunc(true)
  }

  setUpdateData() {
    this.updateServiceAreaData.serviAreaId = this.data.serviAreaId
  }

  // * -- Update Locations -- * //
  save() { 
    this.updateServiceAreaData.locationList =  this.tempLocationList.map(({index, ...location}) => location)
    this.updateLocation(this.updateServiceAreaData)
  }

  onLocationSearchEvent(data: any) { // ! EVENT
    if (data) {
      this.setSearchData(data.lat, data.lgt, data.radius)
    } else {
      this.setToDefaultSettings()
    }
  }

  onRadiusChangeEvent(radius: number) { // ! EVENT
    this.currentSearchRadius = this.toMeters(radius)
  }

  // * -- Location Add / Remove / Edit Functionality -- * //
  addLocationEvent(location: TempLocation) { // ! EVENT
    this.currentSearchIndex = location.index
    location.raduis = this.toMeters(location.raduis)
    this.tempLocationList.push(location)
    this.addNewLocationForm()
    this.setToDefaultSettings()
  }

  onLocationUpdateEvent(location: TempLocation) { // ! EVENT
    location.raduis = this.toMeters(location.raduis)
    const editIndex = this.tempLocationList.findIndex(e => e.index === location.index)
    this.tempLocationList[editIndex].raduis = location.raduis
    this.setToDefaultSettings()
  }

  editLocationEvent(location: TempLocation) { // ! EVENT
    this.currentSearchIndex = location.index
    this.isLocationEditing = true
    this.setSearchData(location.geoLocation.latitude, location.geoLocation.longitude, location.raduis)
  }

  removeLocationEvent(index: any) {  // ! EVENT 
    this.locationsFormArray = this.locationsFormArray.filter(e => e.index != index)
    this.tempLocationList = this.tempLocationList.filter(e => e.index != index)
    this.canSaveFunc(true)
  }

  onLocationStatusChangeEvent(isDone: boolean) { // ! EVENT
    this.canSaveFunc(isDone)
  }

  canSaveFunc(isTrue: boolean) {
    const bothArraysEmpty = (this.data.currentLocationList.length == 0) && (this.tempLocationList.length == 0)
    this.canSave = isTrue && !bothArraysEmpty
  }

  private updateLocation(data: UpdateServiceAreaLocation) {
    this.isLoading = true
    this.locationService.updateServiceAreaLocation(data).subscribe({
      next: res => {
        if (res.success) {
          this.dialogRef.close(res.data)
          this.alertService.success('Service area updated successfully')
        } else {
          this.alertService.error(res.error.message)
        }
        this.isLoading = false
      },
      error: err => {
        this.alertService.error('Operation failed')
        this.isLoading = false
      }
    })
  }

  // ** Map Events for Current Search ** //
  mapClicked($event: any) {
    this.isMapClicked = true
    let lat = $event.coords.lat;
    let lgt = $event.coords.lng;
    this.setSearchData(lat, lgt, 1)
    this.updateCurrentActiveLocation()
  }

  markerDragEnd($event: any) {
    let lat = $event.coords.lat;
    let lgt = $event.coords.lng;
    this.setSearchData(lat, lgt)
    this.updateCurrentActiveLocation()
  }

  setSearchData(lat: number, lgt: number, radius?: number) {
    this.isLocationSearching = true
    this.zoom = 10
    this.currentLatitude = lat;
    this.currentLongitude = lgt;
    let rad = radius ? radius : this.currentSearchRadius
    this.currentSearchRadius = this.toMeters(rad)
  }

  // Update search value of the current search location 
  updateCurrentActiveLocation() {
    this.currentMapSearchData.index = this.currentSearchIndex
    this.currentMapSearchData.latitude = this.currentLatitude
    this.currentMapSearchData.longitude = this.currentLongitude
    this.sharedLocationService.setLocationData(this.currentMapSearchData)
  }

  // ** Support functions ** //
  toMeters(value: number) : number {
    return value * 1000
  }

  setCurrentCoordinates() {
    this.countryRestriction.latLngBounds.south = this.data?.currentStateCoordinate?.bounds?.south
    this.countryRestriction.latLngBounds.west = this.data?.currentStateCoordinate?.bounds?.west
    this.countryRestriction.latLngBounds.north = this.data?.currentStateCoordinate?.bounds?.north
    this.countryRestriction.latLngBounds.east = this.data?.currentStateCoordinate?.bounds?.east
  }

}
