<section [class.d-none]="isUpdate" class="document d-flex align-items-center py-2 px-3 border col-12">
  <img class="icon" src="assets/icons/common/document.svg">
  <div class="ms-2">
      <div class="title mb-1 d-flex align-items-center">
          <span class="font-bold lh-1 me-2">{{document.name}} </span>
          <span>
              <app-shared-document-status-widget 
                [currentStatus]="document.documentStatus">
              </app-shared-document-status-widget>
          </span>
      </div>
      <div class="d-flex align-items-center">
        <span class="lbl-txt lh-1 me-2">{{document.state | getStateNameByType}}</span>
          <span class="lbl-txt lh-1 me-2 border-start ps-2">Updated on {{document.lastUpdatedDate | date:"dd-MM-yyyy"}}</span>
          <span *ngIf="document?.expireDate" class="lbl-txt lh-1 me-2 border-start ps-2">Expires on {{document.expireDate | date:"dd-MM-yyyy"}}</span>
      </div>
      <div *ngIf="document.status == documentStatus.Rejected"
          class="d-flex align-items-center mt-1">
          <span class="lbl-txt text-black lh-1 me-2">Reject Reason: {{document.comment}}</span>
      </div>
  </div>
  <div class="actions d-flex align-items-center ms-auto">
      <span class="action-btn me-2">
          <button mat-icon-button (click)="downloadDocument(document.link, document.name)"
              class="ketab-btn download d-flex justify-content-center align-items-center">
              <mat-icon>file_download</mat-icon>
          </button>
      </span>
      
      <span class="action-btn">
        <button mat-icon-button [matMenuTriggerFor]="menu"
            class="ketab-btn d-flex justify-content-center align-items-center">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="updateDocument(document)"><span>Update</span></button>
            <button mat-menu-item (click)="removeDocumentConfirm(document)"><span>Remove</span></button>
        </mat-menu>
    </span>
  </div>
</section>

<section [class.d-none]="!isUpdate">
  <app-ndis-file-upload-widget 
    [isUpdate]="true"
    [ndisDocument]="document"
    [ndisTradeId]="ndisTradeId" 
    (onCancelEvent)="onCancelEvent($event)"
    (updateOnSuccessEvent)="updateOnSuccessEvent($event)">
  </app-ndis-file-upload-widget>
</section>

