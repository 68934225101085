<div class="document-wr">
    <div *ngIf="documentList?.length" class="document-list row mb-1 pt-1">
        <section *ngFor="let document of documentList" class="mb-1">
            <app-ndis-document-widget 
                (isUpdatingEvent)="isUpdatingEvent($event)"
                (updateOnSuccessEvent)="updateDocumentEvent($event)"
                (removeDocumentEvent)="removeDocumentEvent($event)"
                [document]="document">
            </app-ndis-document-widget>
        </section>
    </div>

    <section *ngIf="!isUpdating" class="document-create">
        <app-ndis-file-upload-widget 
            [ndisTradeId]="ndisTradeId" 
            (createOnSuccessEvent)="createDocumentEvent($event)">
        </app-ndis-file-upload-widget>
    </section>
</div>