<article class="state p-3 bg-light mb-2 border">
  <div class="d-flex align-items-center justify-content-end">
    <span class="me-auto text-uppercase" [class.text-disabled]="!isActiveState">
      <span class="font-semi-bold">{{state.defaultState.name}}</span>
      <span *ngIf="locationCount" class="text-capitalize ms-2">( {{locationCount}} Location{{locationCount > 1 ? 's' : ''}} )</span>
    </span>
    <button *ngIf="!hasLocations" class="btn primary small text-capitalize py-1 me-5" mat-flat-button color="primary" [disabled]="!isActiveState || isEditOnly" (click)="addLocation()">Add Locations</button>
    <button *ngIf="hasLocations" class="btn primary small text-capitalize py-1 me-5" mat-flat-button color="primary" [disabled]="!isActiveState" (click)="addLocation()">Edit Locations</button>
    <mat-slide-toggle [checked]="isActiveState" color="primary" (change)="onToggleActive($event)" [disabled]="isEditOnly"></mat-slide-toggle>
  </div>

  <section *ngIf="(state.defaultState.isActive && state?.availableState?.serviceAreaDetail?.length) && isActiveState" class="locations mt-3">
    <article *ngFor="let location of state?.availableState?.serviceAreaDetail" class="location d-flex align-items-center py-1">
      <img class="icon me-2" src="/assets/icons/common/location-pin.svg" alt="">
      <span class="address text-sm">{{location.name}}</span>
      <span class="mx-3">|</span>
      <span class="radious text-sm">{{location.raduis / 1000 }}km Radius</span>
    </article>
  </section>
</article>

