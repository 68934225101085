<section class="state-wr">

  <div *ngIf="isLoading" class="step-loader w-100 d-flex align-items-center justify-content-center">
      <mat-spinner diameter="35"></mat-spinner>
  </div>

  <div *ngFor="let state of mergedServiceAreaList">
    <app-shared-service-area-item-widget 
      (stateEnableEvent)="stateEnableEvent($event)"
      [businessInfoId]="businessInfoId"
      [isEditOnly]="isEditOnly"
      [tradeId]="tradeId"
      [stateCoordinates]="stateCoordinates"
      [state]="state">
    </app-shared-service-area-item-widget>
  </div>

</section>
