<form [formGroup]="formStateLocation" class="form-wr border">
  <div class="d-flex">
    <section class="w-100 input-wr me-1">
      <input 
        class="form-input search-input w-100"
        formControlName="name"
        type="text" 
        matInput 
        placeholder="Ex: 33 Cookes Road, Brisbane, 4403, Australia" 
        (keydown.enter)="$event.preventDefault()" 
        autocorrect="off" 
        autocapitalize="off" 
        spellcheck="off" 
        required
        #search>

      <!-- <mat-error *ngIf="isAdded && f['name'].errors?.['required']">
        Please enter location.
      </mat-error> -->
    </section>

    <section class="input-wr radius-input-wr me-1">
      <input class="form-input radius-input w-100" placeholder="km" formControlName="raduis" type="number">
      <!-- <mat-error *ngIf="isAdded && f['raduis'].errors?.['required']">
        Please enter radius
      </mat-error> -->
    </section>

    <span>
      <button 
        *ngIf="!isAdded && !isEmptyValues" 
        class="btn decline shadow-none px-0 text-sm text-capitalize" 
        mat-flat-button
        (click)="resetForm()"
        type="button">
        Discard
      </button>
    </span>

    <span>
      <button 
        *ngIf="!isAdded && !isEmptyValues && isSearchSuccess" 
        class="btn primary shadow-none ms-1 px-0 text-capitalize" 
        mat-flat-button 
        [disabled]="!isValid && !isAdded"
        (click)="addLocation()"
        type="button">
        Done
      </button>
    </span>

    <span>
      <span 
        *ngIf="isAdded && !isEditing" 
        class="icon-btn edit d-flex align-items-center justify-content-center me-1"  
        (click)="editLocation()"
        type="button">
        <mat-icon>edit</mat-icon>
      </span>
    </span>

    <span *ngIf="!isEditOnly" >
      <span 
        *ngIf="isAdded && !isEditing" 
        class="icon-btn remove d-flex align-items-center justify-content-center"  
        (click)="removeLocation()"
        type="button">
        <mat-icon>delete</mat-icon>
      </span>
    </span>

    <!-- Editing -->
    <span>
      <button 
        *ngIf="isEditing" 
        class="btn decline shadow-none px-0 text-sm text-capitalize" 
        mat-flat-button
        (click)="resetEditForm()"
        type="button">
        Discard
      </button>
    </span>

    <span>
      <button 
        *ngIf="isEditing" 
        class="btn primary shadow-none ms-1 px-0 text-capitalize" 
        mat-flat-button 
        [disabled]="!isValid && !isAdded"
        (click)="updateLocation()"
        type="button">
        Done
      </button>
    </span>
  </div>

  <div class="error-wr">
    <mat-error *ngIf="isAdded && f['name'].errors?.['required']">
      Please enter location.
    </mat-error>
  
    <mat-error *ngIf="isAdded && f['raduis'].errors?.['required']">
      Please enter radius
    </mat-error>
  </div>
</form>
