import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UploadWidgetStyleType } from 'src/app/core/enums/custom.enum';
import { DocumentStatus } from 'src/app/core/enums/document.enum';
import { MainDocumentTypes, SwmsDocument } from '../../../models/document.model';
import { AlertService } from '../../../services/alert/alert.service';
import { SWMSQuestionService } from 'src/app/modules/shared/services/SWMSQuestion/swmsquestion.service';
import { SwmsFilesService } from '../../file-upload/swms-files/swms-files.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedConfirmationPopupComponent } from '../../popups/shared-confirmation-popup/shared-confirmation-popup.component';

@Component({
  selector: 'app-shared-swms-docs-widget',
  templateUrl: './shared-swms-docs-widget.component.html',
  styleUrls: ['./shared-swms-docs-widget.component.scss']
})
export class SharedSwmsDocsWidgetComponent implements OnInit {

  mainQuestionChecked = false
  hasChackedQuestions = false

  @Input() businessInfoId!: string;
  @Input() tradeId!: string;
  @Input() isEditable: boolean = true
  @Output() swmsDocsChangeEvent = new EventEmitter<any>()

  isLoading = false
  questionList: any[] = []

  mainDocumentType = MainDocumentTypes;
  SWMSURL = ''
  currentUserId = ''
  SWMSData = new SwmsDocument()

  documentStatus = DocumentStatus
  uploadWidgetStyleType = UploadWidgetStyleType
  documentfileLocation = 'User/Documents/'
  documentNamePrefix = 'swms_document'
  swmsURL = ''

  createdSWMSList: any[] = []
  mergedAnsQuestionList: any[] = []

  constructor (
    private authService: AuthService,
    public dialog: MatDialog,
    private SWMSQuestionService: SWMSQuestionService,
    private alertService: AlertService,
    private sWMSDocumentServiceNew: SwmsFilesService,
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId
    this.documentfileLocation = `User/${this.currentUserId}/Documents/`
    this.getSWMSQuestions()
  }

  private getSWMSQuestions() {
    const isArchived = false
    this.SWMSQuestionService.getSWMSQuestionsList(isArchived).subscribe({
      next: (res) => {
        if (res.success) {
          this.questionList = res.data
          this.getSWMSDataByTrade(this.businessInfoId)
        }
      }, error: (errr) => {this.alertService.error('Operation failed')},
    });
  }

  private getSWMSDataByTrade(businessInfoId: string) {
    this.sWMSDocumentServiceNew.getSWMSDocument(businessInfoId).subscribe({
      next: (res) => {
        if (res.success) {
          this.createdSWMSList = res.data.data
          this.mergeArray(this.questionList, this.createdSWMSList)
        }
      }, error: (errr) => {this.alertService.error('Operation failed')},
    });
  }

  mergeArray(questionArr: any[], answerArr: any[]) {
    this.mergedAnsQuestionList = [];
    questionArr.forEach(questionItem => {
      let answer = answerArr.find((answerItem) => answerItem.questionId === questionItem.id)
      let isChecked = false
      if(answer !== undefined) {
        isChecked = true
      }
      let mergedItem = {
        order: questionItem.order,
        question: questionItem,
        answer: answer,
        isChecked: isChecked
      }
      this.mergedAnsQuestionList.push(mergedItem)
    });
    this.mainQuestionChecked = this.hasCheckedQuestions(this.mergedAnsQuestionList)
    this.orderSWMSList()
    this.checkSWMSCompleted()
  }

  orderSWMSList() {
    this.mergedAnsQuestionList = this.mergedAnsQuestionList.sort((a,b) => a.order-b.order);
  }

  removeDocumentEvent(documentId: string, questionId: string) {
    this.getSWMSQuestions()
  }

  updateDocumentEvent(documentId: string, questionId: string) {
    this.getSWMSQuestions()
  }

  removeDocumentConfirm(questionData: any, index: number) {
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to unselect this statement? If you confirm, documents you have uploaded will be deleted.',
        confirmTxt: 'Confirm',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        this.deleteAllSWMSDocs(questionData, index)
      } else {
        this.mergedAnsQuestionList[index].isChecked = true
      }
    });
  }

  private deleteAllSWMSDocs(sWMSDocument: any, index: any) {
    this.isLoading = true;
    if(sWMSDocument.answer !== undefined) {
      this.sWMSDocumentServiceNew.deleteSWMSDocumentAll(sWMSDocument.answer.id).subscribe({
        next: (result: any) => {
          if (result.success) {
            this.alertService.success('Documents deleted successfully!');
            this.checkSWMSCompleted()
            this.removeDocsFromQuestion(index)
          } else {
            this.alertService.error(result.error.message);
          }
          this.isLoading = false;
        }, error: err => {
          this.alertService.error('Operation failed!');
          this.isLoading = false;
        }
      });
    }
  }

  removeDocsFromQuestion(index: any) {
    this.mergedAnsQuestionList[index].answer = undefined
  }

  onMainQuestionChangeEvent(isChecked: boolean) {
    this.mainQuestionChecked = isChecked
    this.checkSWMSCompleted()
  }

  onSubQuestionChanageEvent(questionData: any, isChecked: boolean) {
    const index = this.mergedAnsQuestionList.findIndex((obj => obj.question.id == questionData.question.id));
    if(!isChecked && this.mergedAnsQuestionList[index].answer) {
      this.removeDocumentConfirm(questionData, index)
    }
    this.mergedAnsQuestionList[index].isChecked = isChecked
    this.checkSWMSCompleted()
  }

  checkSWMSCompleted() { 
    this.hasChackedQuestions = this.hasCheckedQuestions(this.mergedAnsQuestionList)
    let subQuestionsCompleted = this.hasDocumentsForEachCkeckedQuestions(this.mergedAnsQuestionList)
    let hasDocuments = this.hasDocuments(this.mergedAnsQuestionList)

    const swmsData = {
      hasDocuments: hasDocuments,
      hasCheckedQuestions: this.hasChackedQuestions,
      subQuestionsCompleted: subQuestionsCompleted,
      mainQuestionChecked: this.mainQuestionChecked
    }
    this.swmsDocsChangeEvent.emit(swmsData)
  }

  hasDocumentsForEachCkeckedQuestions(questionsArray: any[]): boolean {
    let checkedQuestionsCount = questionsArray.filter(q => q.isChecked).length
    let checkedQuestionsWithDpcumentsCount = questionsArray.filter(q => q?.answer?.swmsDetailModel?.length > 0).length
    return (checkedQuestionsCount === checkedQuestionsWithDpcumentsCount)
  }

  hasDocuments(questionsArray: any[]): boolean {
    let checkedQuestionsWithDpcumentsCount = questionsArray.filter(q => q?.answer?.swmsDetailModel?.length > 0).length
    return (checkedQuestionsWithDpcumentsCount > 0)
  }

  hasCheckedQuestions(questionsArray: any[]): boolean {
    return questionsArray.some(q => q.isChecked === true)
  }
}
