<section class="form-wr p-3">
  <p class="mb-2 font-semi-bold">NDIS Worker Screening check</p>
  <form [formGroup]="formNdisDocument" (ngSubmit)="onSubmit()">
    <div class="d-flex align-items-start text-sm mb-4">
      <div class="input-field w-30">
          <div class="state-select w-100 h-100 d-flex align-items-center justify-content-between pointer" [matMenuTriggerFor]="menu">
            <span class="place-holder ms-1">{{ selectedState ? (selectedState | getStateNameByType) : 'Please select the state'}}</span>
            <mat-icon class="icon">arrow_drop_down</mat-icon>
          </div>

          <mat-error *ngIf="submitted && f['state'].errors?.['required']">
            Please select the state.
          </mat-error>

          <mat-menu #menu="matMenu" [xPosition]="'before'">
            <button (click)="onStateSelect(item.value)" *ngFor="let item of stateList" mat-menu-item>{{item.viewValue}}</button>
          </mat-menu>
      </div>

      <div class="browse-field w-40 mx-1">
          <input 
            (click)="fileInput.click()" 
            class="browse-input pointer" 
            [class.disabled]="enableDelete" 
            placeholder="Upload file"
            formControlName="link" 
            readonly>
          
          <span class="browse-btn font-semi-bold bg-white">[BROWSE]</span>

          <input
            hidden
            id="file"
            type="file"
            accept="image/png, image/jpeg, .pdf, .doc, .docx"
            name="image"
            #fileInput
            (change)="fileProgress($event)"/>

          <mat-error *ngIf="submitted && f['link'].errors?.['required']">
            Please upload document.
          </mat-error>
      </div>

      <div class="input-field w-30">
        <div class="custom-date-wr">
          <div class="d-flex align-items-center h-100" (click)="picker.open()">
            <input class="date-input" formControlName="expireDate" placeholder="Expiry Date" [matDatepicker]="picker" [min]="minExpireData" readonly>
            <mat-icon class="me-1 icon">calendar_month</mat-icon>
          </div>

          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="submitted && f['expireDate'].errors?.['required']">
            Please select document expire date.
          </mat-error>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end">
      <button type="button" mat-raised-button class="btn decline shadow-none text-sm me-2" (click)="cancel()" >{{ isUpdate ? 'Discard' :'Cancel'}}</button>
      <button mat-raised-button class="btn primary shadow-none">Save Document</button>
      <mat-spinner class="spinner ms-1" diameter="25" *ngIf="isLoading"></mat-spinner>
    </div>
  </form>
</section>