<h3 class="text-md text-uppercase font-bold text-secondary mb-0">Linked Documents</h3>

<section class="document-wr" *ngFor="let document of linkedDocumentsList; last as isLast" [ngClass]="{'border-bottom': !isLast}">
  <div class="document d-flex align-items-center py-3">
    <img class="icon" src="/assets/icons/common/document.svg">
    <div class="ms-2">
        <div class="title mb-1 d-flex align-items-center">
            <span class="font-bold lh-1 me-2">{{document.documentName}}</span>
            <span>
                <app-shared-document-status-widget [currentStatus]="document.status"></app-shared-document-status-widget>
            </span>
        </div>
        <div class="d-flex align-items-center">
            <span class="lbl-txt lh-1 me-2">Updated on {{document.updatedDate | date:"dd-MM-yyyy"}}</span>
            <span *ngIf="document?.expireDate" class="lbl-txt lh-1 ps-2 me-2 border-start">Expires on {{document.expireDate | date:"dd-MM-yyyy"}}</span>
            <span class="lbl-txt lh-1 ps-2 border-start">Linked from {{document.serviceCategoryName}}</span>
        </div>
    </div>
  </div>
</section>

<section class="mt-2" *ngIf="linkedDocumentsList.length == 0">
  <div class="border bg-light text-center p-3">
    <span class="text-secondary"><i>No linked documents</i></span>
  </div>
</section>