import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Trade } from 'src/app/modules/shared/models/tradie/tradie.model';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { TradieService } from 'src/app/modules/shared/services/tradie/tradie.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { TradeAdminPost } from '../../enums/main.enum';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

  isTestEnv =  environment.isTestEnvironment
  isLoading = false;
  isOpen = true;
  tradeId!: string
  profileImageUrl = ''
  currentPost: string = 'loading..'
  tradeData!: Trade;
  tradeAdminPost = TradeAdminPost
  
  constructor(
    private router: Router, 
    private tradieService: TradieService, 
    private authService: AuthService,
    private alertService: AlertService,
  ) {}
  
  ngOnInit(): void {
    this.tradeId = this.authService.currentUserValue.userId
    this.getTradeDetails(this.tradeId)
  }

  viewProfile() {
    this.router.navigate(['/profile'])
  }

  logout() {
    this.authService.logout()
  }

  private getTradeDetails(tradeId: string) {
    this.isLoading = true;
    this.tradieService.getTradieById(tradeId).subscribe( res => {
      if (res.success) {
        this.tradeData = res.data;
        // this.setPost(this.tradeData.adminPost)
        const post = this.tradeData?.team ? this.tradeData?.team?.post : this.tradeData.adminPost
        this.setPost(post)
      } else {
        this.alertService.error(res.error.message)
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  setPost(post: number) {
    switch (post) {
      case this.tradeAdminPost.Admin:
        this.currentPost = 'Admin'
        break;
      case this.tradeAdminPost.Owner:
        this.currentPost = 'Owner'
        break;
      case this.tradeAdminPost.ProjectManager:
        this.currentPost = 'Manager'
        break;
      default:
        this.currentPost = 'Team Member'
        break;
    }
  }
}
