import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessInfo } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';

@Component({
  selector: 'app-update-business-contact-mobile-popup',
  templateUrl: './update-business-contact-mobile-popup.component.html',
  styleUrls: ['./update-business-contact-mobile-popup.component.scss']
})
export class UpdateBusinessContactMobilePopupComponent implements OnInit {

  formUpdateContactMobile!: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpdateBusinessContactMobilePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public  businessInfo: BusinessInfo,
    private alertService: AlertService,
    private tradieService: TradieService,
  ) { }

  ngOnInit(): void {
    this.formUpdateContactMobile = this.formBuilder.group({
      businessInfoId: this.businessInfo.id,
      contactMobile: [this.businessInfo.contactMobile, [Validators.pattern('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.formUpdateContactMobile.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.formUpdateContactMobile.invalid) {
      return;
    }
    this.updateBusinessContactMobile()
  }

  // Update Contact mobile
  updateBusinessContactMobile() {
    this.isLoading = true;
    this.tradieService.updateBusinessContactMobile(this.formUpdateContactMobile.value).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.alertService.success('Business contact mobile successfully updated');
          const address = response.data;
          this.dialogRef.close(address);
        } else {
          this.alertService.error('Operation failed');
        }
      }, error : err => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }

}
