import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { CustomDocumentModel, DocumentModel } from '../../../models/document.model';
import { UploadService } from '../../../services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { SharedServiceDocumentUploadPopupComponent } from '../../popups/shared-service-document-upload-popup/shared-service-document-upload-popup.component';

@Component({
  selector: 'app-shared-service-document-widget',
  templateUrl: './shared-service-document-widget.component.html',
  styleUrls: ['./shared-service-document-widget.component.scss']
})
export class SharedServiceDocumentWidgetComponent implements OnInit {

  @Input() serviceDocument!: DocumentModel
  @Input() businessId!: string
  @Input() isEditable!: boolean
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentStatusEvent = new EventEmitter<any>();
  
  serviceDocumentData = new CustomDocumentModel()
  documentCategory = DocumentCategory
  documentGroup = DocumentGroup
  documentStatus = DocumentStatus
  isLoading = false
  currentUserId!: string
  businessDocumentData: any
  businessInfoId!: string

  constructor(
    private authService: AuthService,
    private uploadService: UploadService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.businessDocumentData = this.serviceDocument;
    this.currentUserId = this.authService.currentUserValue.userId
    this.businessInfoId = this.businessId
    
    
  }

  updateDocument() {
    this.setCreateDocumentData() 
    const dialogRef = this.dialog.open( SharedServiceDocumentUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {  businessDocumentData: this.businessDocumentData , businessInfo: this.businessInfoId}
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.serviceDocument = data
        this.updateDocumentEvent.emit(this.serviceDocument)
      }
    });
  }

  removeDocumentConfirm() {
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isArchived = true
        this.removeDocumentEvent.emit(this.serviceDocument.id)
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setCreateDocumentData() {
    this.serviceDocumentData.document = this.serviceDocument
    this.serviceDocumentData.documentName = null!
    this.serviceDocumentData.documentCategory = this.documentCategory.Other
    this.serviceDocumentData.documentGroup = this.documentGroup.Additional
    this.serviceDocumentData.hasExpiry = true
    this.serviceDocumentData.isBusinessDocument = true
    this.serviceDocumentData.businessServiceId = this.serviceDocument.id
    this.serviceDocumentData.businessInfoId = this.serviceDocument.businessInfoId
    this.serviceDocumentData.isBusinessServiceDocument = true
    this.serviceDocumentData.currentUserId = this.currentUserId
    this.serviceDocumentData.fileLocation = `User/${this.currentUserId}/Documents/`
  }
}
