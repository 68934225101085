export class Tradie {}

export class TradieRegisterModel {
  firstName!: string;
  lastName!: string;
  email!: string;
  mobileNumber!: string;
  password!: string;
  adminPost!: number;
}
export interface TradeProfile {
  rating: number;
  isFavourite: boolean;
  team: Team;
  userAddresses: any[];
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  userType: number;
  profileImageUrl: string;
  profession: any;
  createdDate: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  isArchived: boolean;
  isActive: boolean;
  isPolicyAgree: boolean;
  isEmailVerified: boolean;
  isMobileVerified: boolean;
  roleId: string;
  isSuspended: boolean;
  suspendedReason: any;
  isActivated: boolean;
  adminPost: any;
  DateOfBirth: string;
}
export interface Trade {
  adminPost: number;
  rating: number;
  isFavourite: boolean;
  team: Team;
  userAddresses: any[];
  strataSites: null;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  userType: number;
  profileImageUrl: string;
  profession: null;
  createdDate: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  isArchived: boolean;
  isActive: boolean;
}

export interface Team {
  id: string;
  tradieId: string;
  businessInfoId: string;
  serviceCategoryId: null;
  role: number;
  isJobAssignee: boolean;
  post: number;
  isActive: boolean;
  jobsCompleted: number;
  jobsCancelled: number;
  isArchived: boolean;
  createdDateTime: string;
  tradie: TradieInfo;
  businessInfo: BusinessInfo;
}

export interface BusinessInfo {
  id: string;
  abn: string;
  entity: string;
  entityType: string;
  entityTypeCode: string;
  businessName: string;
  phoneBusinessHours: string;
  phoneAfterHours: string;
  email: string;
  contactName: string;
  contactMobile: string;
  logo: string;
  jobsCompleted: number;
  jobsCancelled: number;
  status: string;
  gst: string;
  location: string;
  isActive: boolean;
  createdDateTime: string;
  rating: number;
  noOfRating: number;
  number: string;
  streetName: string;
  city: string;
  state: string;
  zipCode: string;
  timeZone: null;
  huskiVerified: boolean;
  bAndDPreferred: boolean;
  hasNdis: boolean;
  stripeAccountId: null;
  geoLocation: null;
  businessStatus: number;
  businessServices: BusinessService[];
  businessInfoVerification: BusinessInfoVerification;
}

export interface BusinessInfoVerification {
  id: string;
  businessInfoId: string;
  isDocumentVerified: boolean;
  isBasicInfoVerified: boolean;
  isOrientationCompleted: boolean;
  isDocumentUploaded: boolean;
  isBusinessInfoVerified: boolean;
  isVerified: boolean;
  verifiedBy: null;
  verifiedDate: null;
  lastUpdatedDate: string;
}

export interface BusinessService {
  id: string;
  businessInfoId: string;
  serviceCategoryId: string;
  businessHourCallOutFee: number;
  businessHourHourlyRate: number;
  afterHourCallOutFee: number;
  afterHourHourlyRate: number;
  quotationOnly: boolean;
  status: number;
  comment: string;
  isArchived: boolean;
  documents: any[];
  businessServiceSpecialty:any[];
  businessServiceClassificationType: any[];
}

export interface TradieInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  userType: number;
  profileImageUrl: string;
  profession: null;
  businessName: string;
  businessLogo: string;
  abn: string;
  businessInfoId: string;
  serviceCategoryId: null;
  rating: number;
}

// ======================= //

export class RegisterBusiness {
  abnInfo!: AbnInfo;
  businessName!: string;
  phoneBusinessHours!: string;
  phoneAfterHours!: string;
  email!: string;
  number!: string;
  streetName!: string;
  city!: string;
  state!: string;
  zipCode!: string;
  logoUrl!: string;

  public setAbnData(
    abn: string,
    entity: string,
    entityType: string,
    entityTypeCode: string,
    statusType: string,
    gst: string,
    location: string
  ) {
    this.abnInfo = {
      abn: abn,
      entity: entity,
      entityType: entityType,
      entityTypeCode: entityTypeCode,
      status: statusType,
      gst: gst,
      location: location,
    };
  }
}

export interface AbnInfo {
  abn: string;
  entity: string;
  entityType: string;
  entityTypeCode: string;
  status: string;
  gst: string;
  location: string;
}

export interface BusinessInfoModelRes {
  data: BusinessInfoModel;
  success: boolean;
  error: null;
}

export interface BusinessInfoModel {
  abn: string;
  entity: string;
  entityType: string;
  entityTypeCode: string;
  status: string;
  gst: string;
  location: string;
}

export interface BusinessDocumentAddModel {
  businessServiceId: string;
  name: string;
  link: string;
  documentCategory: number;
  documentType: number;
  group: number;
  expireDate: string;
}

export interface BusinessDocumentUpdateModel {
  id: string;
  name: string;
  link: string;
  documentCategory: number;
  documentType: number;
  group: number;
  expireDate: string;
}

export class TradieUpdateLogo {
  businessInfoId!: string;
  logoUrl!: string;
}

export class BusinessServiceAddModel {
  businessInfoId!: string;
  serviceCategoryId!: string;
  businessHourCallOutFee!: number;
  businessHourHourlyRate!: number;
  afterHourCallOutFee!: number;
  afterHourHourlyRate!: number;
  quotationOnly!: boolean;
  documents!: Document[];
  speciality!:string[];
  categoryClassification!: number[];
  categoryType !: number[];
}

export class BusinessServiceUpdateModel {
  id!: string;
  businessHourCallOutFee!: number;
  businessHourHourlyRate!: number;
  afterHourCallOutFee!: number;
  afterHourHourlyRate!: number;
  quotationOnly!: boolean;
  speciality!:string[];
  categoryClassification!: number[];
  categoryType !: number[];
}

export interface Document {
  name: string;
  link: string;
  documentType: number;
  expireDate: string;
}

export interface BusinessServiceModel {
  id: string;
  businessInfoId: string;
  isArchived: boolean;
  serviceCategoryId: string;
  afterHourCallOutFee: number;
  afterHourHourlyRate: number;
  businessHourCallOutFee: number;
  businessHourHourlyRate: number;
  documents: BusinessDocumentModel[];
  isVerified: boolean;
  quotationOnly: boolean;
  status: number;
  businessServiceSpecialty:any[];
  businessServiceClassificationType: any[];
}

export interface BusinessDocumentModel {
  businessServiceId: string;
  documentType: number;
  expireDate: string;
  id: string;
  isArchived: false;
  link: string;
  name: string;
  status: number;
}

export class ProfessionalIdemnityCompliance {
  id!: string;
  businessInfoId!: string;
  userId!: string;

  professionalIdemnityInsurance!: boolean;
  professionalIdemnityInsuranceDocument!: any;

  professionalIdemnityInsuranceCurrency!: boolean;
  professionalIdemnityInsuranceCurrencyDocument!: any;

  renewProfessionalIdemnityInsurance!: boolean;

  certificateExpireDate!: string;

  professionalIndemnityDocumentStatus!: number;
}

export class HealthSafetyCompliance {
  userId!: string;
  businessInfoId!: string;
  manageHealthSafety!: boolean;
  documentDetails: HealthSafetyDocuments[] = [];
}
export class HealthSafetyDocuments {
  id!: string;
  status!: number;
  expiryDate!: string;
  document!: string;
  healthSafetyDocumentType!: number;
}

export interface ComplianceStatusList {
  id: string;
  businessInfoId: string;
  userId: string;
  userType: number;
  generalComplianceType: number;
  status: number;
  comment: string;
  isArchived: boolean;
  lastUpdatedDate: string;
}

export interface CreateTradieEntitieModel {
  userId: string;
  entityId: string;
}

// Generated by https://quicktype.io

export interface CategoryClassificationAndTypesModel {
  businessServiceId:        string;
  businessServiceType:      number;
  businessServiceTypeValue: number;
  createdDate:              string;
  updatedDate:              string;
  isArchived:               boolean;
}
