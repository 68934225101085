import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as AWS from 'aws-sdk';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentCategoryType, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { StateType } from 'src/app/core/enums/main.enum';
import { CustomDocumentModel } from 'src/app/modules/shared/models/document.model';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { NDISDocumentUpdate, NdisDocumentCreate } from '../ndis-files.model';
import { NdisFilesService } from '../ndis-files.service';
import { GetStateNameByTypePipe } from 'src/app/modules/shared/pipes/get-state-name-by-type.pipe';

@Component({
  selector: 'app-ndis-file-upload-widget',
  templateUrl: './ndis-file-upload-widget.component.html',
  styleUrls: ['./ndis-file-upload-widget.component.scss'],
  providers: [DatePipe, GetStateNameByTypePipe]
})
export class NdisFileUploadWidgetComponent implements OnInit {

  @Input() isUpdate!: boolean
  @Input() ndisDocument!: any
  @Input() ndisTradeId!: string
  @Input() businessInfoId!: string
  @Input() documentIndex!: number;
  @Input() serviceCategoryId!: string;

  @Output() createOnSuccessEvent = new EventEmitter<any>();
  @Output() updateOnSuccessEvent = new EventEmitter<any>();
  @Output() onCancelEvent = new EventEmitter<any>();

  @ViewChild('fileInput') fileInput!: ElementRef
  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();
  currentDate: any = new Date();

  currentUserId!: string;
  curuntDocumentId: any;
  docType: any;

  formNdisDocument!: FormGroup;
  isChangeDetected = false;
  submitted = false;
  isLoading = false;

  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  documentGroup = DocumentGroup;
  documentData = new CustomDocumentModel()
  isValid = false
  enableDelete = false;
  isDocUploaded = false;
  isEmptyValues = true;
  
  selectedState: any
  stateType = StateType
  stateList = [
    {viewValue: 'Victoria State', value: this.stateType.Victoria},
    {viewValue: 'New South Wales State', value: this.stateType.NewSouthWales},
    {viewValue: 'South Australia State', value: this.stateType.SouthAustralia},
    {viewValue: 'Queensland State', value: this.stateType.Queensland},
    {viewValue: 'Western Australia State', value: this.stateType.WesternAustralia},
  ]

  createNdisDocumentData = new NdisDocumentCreate()
  updateNdisDocumentData = new NDISDocumentUpdate()

  documentStatus = DocumentStatus

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private getStateNameByTypePipe: GetStateNameByTypePipe,
    private authService: AuthService,
    private ndisFilesService: NdisFilesService,
  ) {}

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId
    this.formNdisDocument = this.formBuilder.group({
      id: [],
      ndisTradeId: [ this.ndisTradeId],
      state: [ '', Validators.required],
      name: [ '', Validators.required],
      link: ['', Validators.required],
      expireDate: [''],
      documentStatus: [this.documentStatus.Processing],
      documentType: ['', Validators.required],
    });

    if (this.ndisDocument) {
      this.setUpdateFormData()
    }

    this.onFormStatusChnages()
    this.onformValueChnages()
    this.onFormValueChange()
  }

  onFormStatusChnages() {
    this.formNdisDocument.statusChanges.subscribe( status => {
      this.isValid = (status === 'VALID')
      const isComplete = this.isEmptyValues ? true :  (this.isValid && this.isDocUploaded)
      // this.onDocStatusChangeEvent.emit(isComplete)
    })
  }

  onformValueChnages() {
    this.formNdisDocument.valueChanges.subscribe( value => {
      const hasValue = (value.name || value.link || value.group)
      this.isEmptyValues = (hasValue === undefined)
    })
  }

    // Check if initial values are changed
    onFormValueChange() { 
      const initialValue = this.formNdisDocument.value
      this.formNdisDocument.valueChanges.subscribe(value => {
        this.isChangeDetected = Object.keys(initialValue).some(key => this.formNdisDocument.value[key] !=  initialValue[key])
      });
    }

  // convenience getter
  get f() { return this.formNdisDocument.controls; }

  onStateSelect(state: any) {
    this.selectedState = state
    this.formNdisDocument.controls['state'].setValue(this.selectedState);
    this.setFileNameByState();
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();
    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.formNdisDocument.controls['link'].setValue(this.fileName);
    // set file extention / doc type
    const currrentDocType  = this.getDocumentType(this.fileData.name)
    this.formNdisDocument.controls['documentType'].setValue(currrentDocType);
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
    this.setFileNameByState();
  }

  // Upload selected image
  onSubmit() {
    this.submitted = true;
    if (this.formNdisDocument.invalid) { return; } 
    let isDocumentChanged = (this.ndisDocument?.link != this.formNdisDocument.value.link)
    // this.formNdisDocument.reset(); // comment by Thilina 2023-09-11
    this.submitted = false;
    if(this.isUpdate && !isDocumentChanged) {
      this.updateDocument(this.formNdisDocument.value);
    } else {
      this.uploadDocToS3()
    }
  }

  uploadDocToS3() {
    this.isLoading = true
    this.formData = new FormData();
    this.formData.append('file', this.fileData);
    const fileName = this.setFileNameByState();
    const contentType = this.fileData.type;
    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });
    const params = {
      Bucket: environment.config.location,
      Key: `Trade/${this.businessInfoId}/Documents/Ndis/` + fileName,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };
    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }
      this.onAWSUploadComplete(response.Key)
      return true;
    });
  }

  onAWSUploadComplete(key: string) {
    this.isLoading = false
    this.enableDelete = true
    this.isDocUploaded = true
    this.formNdisDocument.patchValue({
      link: key,
    });

    if (this.isUpdate) {
      this.updateDocument(this.formNdisDocument.value);
    } else {
      this.formNdisDocument.controls['documentStatus'].setValue(this.documentStatus.Processing);
      this.formNdisDocument.controls['ndisTradeId'].setValue(this.ndisTradeId);

      this.createDocument(this.formNdisDocument.value);
    }

    this.disableForm()
  }

  // Create NDIS document
  private createDocument(data: any) {
    this.isLoading = true;
    this.createNdisDocumentData = data;
    this.createNdisDocumentData.expireDate = this.convertDate(data.expireDate)!
    this.ndisFilesService.createDocument(this.createNdisDocumentData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.onDocumentCreateSuccess(response.data)
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },  error: (error: any) => {
          this.alertService.error('Operation failed!');
          this.isLoading = false;
        },
      });
  }

  onDocumentCreateSuccess(response: any) {
    this.createOnSuccessEvent.emit(response)
    this.enableAllFormFields()
    this.formNdisDocument.reset();
    this.submitted = false
    this.alertService.success('Document created successfully');
  }

  // update NDIS document
  private updateDocument(data: any) {
    this.isLoading = true;
    this.updateNdisDocumentData = data;
    this.updateNdisDocumentData.expireDate = this.convertDate(data.expireDate)!
    this.updateNdisDocumentData.link = data.link != this.ndisDocument.link ? data.link : this.ndisDocument.link
    this.ndisFilesService.updateDocument(this.updateNdisDocumentData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.onDocumentUpdateSuccess(response.data)
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },  error: (error: any) => {
          this.alertService.error('Operation failed!');
          this.isLoading = false;
        },
      });
  }
  
  onDocumentUpdateSuccess(document: any) {
    this.ndisDocument = document
    this.updateOnSuccessEvent.emit(document)
    this.enableAllFormFields()
    this.alertService.success('Document updated successfully');
    this.setUpdateFormData()
  }

  setUpdateFormData() {
    this.formNdisDocument.controls['id'].setValue(this.ndisDocument.id)
    this.formNdisDocument.controls['name'].setValue(this.ndisDocument.name)
    this.formNdisDocument.controls['state'].setValue(this.ndisDocument.state)
    this.selectedState = this.ndisDocument.state
    const expireDate = this.ndisDocument?.expireDate ? new Date(this.ndisDocument?.expireDate) : null
    this.formNdisDocument.controls['expireDate'].setValue(this.ndisDocument.expireDate)
    this.formNdisDocument.controls['link'].setValue(this.ndisDocument.name) // seting name just to display
    this.formNdisDocument.controls['documentStatus'].setValue(this.documentStatus.Processing)
    const currrentDocType  = this.getDocumentType(this.ndisDocument.link)
    this.formNdisDocument.controls['documentType'].setValue(currrentDocType);
    this.formNdisDocument.controls['documentStatus'].setValue(this.documentStatus.Processing)
  }

  cancel() {
    if (this.isUpdate) {
      this.onCancelEvent.emit(true)
      this.setUpdateFormData()
    } else {
      this.resetForm()
    }
  }

  resetForm() {
    this.formNdisDocument.controls['state'].setValue('')
    this.formNdisDocument.controls['expireDate'].setValue('')
    this.formNdisDocument.controls['link'].setValue('')
    this.fileInput.nativeElement.value = ""
    this.selectedState = null
  }

  disableForm() {
    this.formNdisDocument.controls['state'].disable()
    this.formNdisDocument.controls['expireDate'].disable()
    this.formNdisDocument.controls['link'].disable()
  }

  enableAllFormFields() {
    Object.keys(this.formNdisDocument.controls).forEach(controlName => {
      this.formNdisDocument.controls[controlName].enable();
    });
  }

  // Generate File Name
  setFileNameByState() {
    let fileName;
    let state = this.getStateNameByTypePipe.transform(this.selectedState)
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    const fileExtension = this.fileData?.name.split('.').pop();
    fileName = state ? state.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : 'NDIS_Document';
    this.formNdisDocument.controls['name'].setValue(fileName);
    return fileName+'.'+fileExtension;
  }

  convertDate(date: string) {
    return date ? this.datePipe.transform( date,'yyyy-MM-dd' ) : null;
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }
}
