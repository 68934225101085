<section class="d-flex">

  <div>
    <section>
      <div class="d-flex mb-3">
        <span class="font-bold me-2"> Classification : </span> 
        <span *ngFor="let item of classificationTypeList; last as isLast">
          <span *ngIf="item.businessServiceTypeValue == categoryClassification.Consultant">Consultant</span>
          <span *ngIf="item.businessServiceTypeValue == categoryClassification.Contractor">Contractor</span>
          <span class="me-1" *ngIf="!isLast">,</span>
        </span>
        <span *ngIf="classificationTypeList?.length == 0">--</span>
      </div>

      <div class="d-flex">
        <span class="font-bold me-2">Type  :</span> 
        <span *ngFor="let item of categoryTypeList; last as isLast">
          <span *ngIf="item.businessServiceTypeValue == categoryType.Commercial">Commercial</span>
          <span *ngIf="item.businessServiceTypeValue == categoryType.Residential">Residential</span>
          <span class="me-1" *ngIf="!isLast">,</span>
        </span>
        <span *ngIf="categoryTypeList?.length == 0">--</span>
      </div>
    </section>
    
    <section class="mt-3">
      <mat-chip-list *ngIf="(businessService.businessServiceSpecialty.length > 0); else noSpecialtyBlock">
        <mat-chip *ngFor="let specialty of businessService.businessServiceSpecialty; index as i">{{ specialty.specialtyName}}</mat-chip>
      </mat-chip-list>
      <ng-template #noSpecialtyBlock> <span class="font-bold">Specialities:</span> <span class="ms-2">--</span> </ng-template>
    </section>
  </div>

  <button  
    mat-icon-button 
    (click)="updateServiceCategory()"  
    class="ketab-btn d-flex justify-content-center align-items-center ms-3 ml-auto">
    <mat-icon>edit</mat-icon>
  </button>
</section>


