import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentGroup } from 'src/app/core/enums/document.enum';
import { TradieService } from 'src/app/modules/onboarding/shared/services/tradie.service';
import { SharedServiceDocumentUploadPopupComponent } from 'src/app/modules/shared/components/popups/shared-service-document-upload-popup/shared-service-document-upload-popup.component';
import { CustomDocumentModel } from 'src/app/modules/shared/models/document.model';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { SharedBusinessDocumentInfoPopupComponent } from '../../popups/shared-business-document-info-popup/shared-business-document-info-popup.component';

@Component({
  selector: 'app-shared-service-document-list-widget',
  templateUrl: './shared-service-document-list-widget.component.html',
  styleUrls: ['./shared-service-document-list-widget.component.scss']
})
export class SharedServiceDocumentListWidgetComponent implements OnInit, OnChanges{

  isLoading = false

  @Input() businessService!: any
  @Input() isEditable: boolean = true
  serviceDocumentList: any[] = []

  businessDocumentData = new CustomDocumentModel()
  documentCategory = DocumentCategory
  documentGroup = DocumentGroup
  currentUserId!: string

  businessServiceData: any
  businessInforId!: string
  constructor(
    public dialog: MatDialog, 
    private authService: AuthService,
    private tradieService: TradieService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.businessServiceData = this.businessService;
    this.businessInforId = this.businessService.businessInfoId
  }

  ngOnChanges(): void {
    this.currentUserId = this.authService.currentUserValue.userId
    this.serviceDocumentList = this.businessService.documents
  }

  // Upload Business service document
  uploadDocument() {
    this.setCreateDocumentData();
    const dialogRef = this.dialog.open(SharedServiceDocumentUploadPopupComponent, {
      maxWidth: '520px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {  businessDocumentData: this.businessDocumentData , businessInfo: this.businessInforId}
   
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.serviceDocumentList.push(result)
      }
    });
  }

  updateDocumentEvent(data: any) {
    this.updateListItem(data)
  }

  updateListItem(data: any) {
    const index = this.serviceDocumentList.findIndex(x => x.id === data.id);
    this.serviceDocumentList[index] = data
  }

  removeDocumentEvent(docId: string) {
    this.deleteBusinessDocument(docId)
  }

  viewDocumentInfo() {
    const dialogRef = this.dialog.open( SharedBusinessDocumentInfoPopupComponent, {
      maxWidth: '500px',
      position: {
        top: '60px'
      },
      width: '80%',
      panelClass: 'custom-dialog-container',
      data: this.businessService.serviceCategoryId
    });
  }

  removeDocumentFromList(documentId: string) {
    this.serviceDocumentList = this.serviceDocumentList.filter(item => item.id !== documentId);
  }

  private deleteBusinessDocument(docId: string) {
    this.isLoading = true;
    this.tradieService.deleteBusinessServiceDocument(docId).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Business document deleted successfully!');
          this.removeDocumentFromList(docId)
        } else {
          this.alertService.error('Operation failed!');
        }
        this.isLoading = false;
      }, error : error => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  setCreateDocumentData() {
    this.businessDocumentData.documentName = null!
    this.businessDocumentData.documentCategory = this.documentCategory.Other
    this.businessDocumentData.documentGroup = this.documentGroup.Additional
    this.businessDocumentData.hasExpiry = true
    this.businessDocumentData.isBusinessDocument = false
    this.businessDocumentData.businessServiceId = this.businessServiceData.id
    this.businessDocumentData.businessInfoId = this.businessServiceData.businessInfoId
    this.businessDocumentData.isBusinessServiceDocument = true
    this.businessDocumentData.currentUserId = this.currentUserId
    this.businessDocumentData.fileLocation = `User/${this.currentUserId}/Documents/`
  }
}
