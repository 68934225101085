import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Post, TradieRole } from 'src/app/core/enums/main.enum';
import { AlertService } from '../../../services/alert/alert.service';
import { TeamService } from '../../../services/team/team.service';

@Component({
  selector: 'app-shared-update-team-member-popup',
  templateUrl: './shared-update-team-member-popup.component.html',
  styleUrls: ['./shared-update-team-member-popup.component.scss']
})
export class SharedUpdateTeamMemberPopupComponent implements OnInit {

  formUpdateTeamMember!: FormGroup;
  submitted = false;
  isLoading = false;
  isShowPost = false;

  countryCode = '+61 (Australia)';

  // enums
  tradieRole = TradieRole;
  post = Post;
  tradieRoleList : any[] = [ 
    { value: this.tradieRole.Admin, viewValue: 'Manager'},
    { value: this.tradieRole.TeamMember, viewValue: 'Team Member'}
  ];

  postList : any[] = [ 
    { value: this.post.Owner, viewValue: 'Owner'},
    { value: this.post.Admin, viewValue: 'Admin'},
    { value: this.post.ProjectManager, viewValue: 'Manager'}
  ]

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateTeamMemberPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public teamData: { isAdd: boolean, businessInfoId: string, tradeId: string},
    private teamService: TeamService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.formUpdateTeamMember = this.formBuilder.group({
      businessInfoId: [this.teamData.businessInfoId, Validators.required],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      email: [ '', [Validators.required, Validators.pattern('[A-Z0-9a-z_.%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]],
      mobileNumber: ['', [Validators.required, Validators.pattern('[+][0-9]{2}[0-9]{9}')]],
      dateOfBirth: ['', Validators.required],
      serviceCategoryId: [null],
      profession: [null],
      tradieRole: ['', Validators.required],
      post: ['']
    });
  }

  get f() { return this.formUpdateTeamMember.controls; }

  onSubmit() {
    this.submitted = true;
    
    // If invalid
    if (this.formUpdateTeamMember.invalid) {
      return;
    }
    
    this.isLoading = true;
    this.teamService.addTeamMember(this.formUpdateTeamMember.value).subscribe( response => {
      if (response.success) {
        this.alertService.success('Team member successfully added');
        this.dialogRef.close(response.data);
      } else {
        this.alertService.error(response.error.message);
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.error('Operation failed');
    });
  }

  tradieRoleChange(event: any) {
    this.formUpdateTeamMember.value.post = null;
    const selectedCategory = event.value;
    switch (selectedCategory) {
      case this.tradieRole.Admin:
        this.isShowPost = true;
        break;
      case this.tradieRole.TeamMember:
        this.isShowPost = false;
        break;
    }
  }
}
