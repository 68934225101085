import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
// import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class PaidNowService {

  private addInvoiceData = new Subject<any>();

  constructor(private http: HttpClient, private authService: AuthService) { }

  // // add Team Member
  // public addTeamMember(data: addTeamMemberModel): Observable<any> {
  //   const url = `${this.teamMember}/AddMember`;
  //   return this.http.post<any>(url, data);
  // }

  postInvoiceData(data: any) {
    const url = 'https://ctet7ig4qi33gp6tfg5utgliw40byour.lambda-url.ap-southeast-2.on.aws'
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST',
        'Access-Control-Allow-Headers': 'application/json'
      }),
      withCredentials: true,
    };
    return this.http.post<any>(url, data, httpOptions);
  }

  addInvoice() {
    return this.addInvoiceData.asObservable();
  }
}
