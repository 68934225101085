<section class="header d-flex justify-content-between align-items-center py-2">
    <span class="ps-3">Module Completion Wizard</span>

    <div class="d-flex justify-content-end align-items-center pe-2" (click)="close()">
      <span>Exit Wizard</span>
      <button class="close-icon" mat-icon-button>
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
</section>
<section>
  <div *ngIf="activeModule?.moduleMatrixModelList[0]?.moduleLink != null; else emptyPreview"  class="video-wr">
    <iframe width="100%" height="650" [src]="activeModule?.moduleMatrixModelList[0]?.moduleLink | linkSafe:'resourceUrl'"></iframe>
  </div>

  <ng-template #emptyPreview>
    <div class="empty-placeholder d-flex flex-column justify-content-center align-items-center" *ngIf="activeModule?.moduleMatrixModelList[0]?.moduleLink == null">
      <img class="mb-2 icon" src="/assets/icons/common/module-empty-state.svg" alt="Preview Not Available">
      <span class="m-0 text-secondary font-semi-bold">Preview Not Available</span>
    </div>
  </ng-template>
</section>