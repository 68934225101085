import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BusinessServiceType, ServiceCategoryClassification, ServiceCategoryType } from 'src/app/core/enums/main.enum';
import { CategoryClassificationAndTypesModel } from '../../../models/tradie/tradie.model';
import { SharedUpdateClassificationTypePopupComponent } from '../../popups/shared-update-classification-type-popup/shared-update-classification-type-popup.component';

@Component({
  selector: 'app-shared-category-classifications-widget',
  templateUrl: './shared-category-classifications-widget.component.html',
  styleUrls: ['./shared-category-classifications-widget.component.scss']
})
export class SharedCategoryClassificationsWidgetComponent implements OnInit {

  @Input() businessService!: any;
  classificationList: CategoryClassificationAndTypesModel[] = [];

  businessServiceType = BusinessServiceType
  categoryClassification = ServiceCategoryClassification
  categoryType = ServiceCategoryType
  
  classificationTypeList: CategoryClassificationAndTypesModel[] = []
  categoryTypeList: CategoryClassificationAndTypesModel[] = []

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.classificationList = this.businessService.businessServiceClassificationType
    this.setInitialList(this.classificationList)
  }

  setInitialList(list: CategoryClassificationAndTypesModel[]) {
    this.getClassificationTypeList(list)
    this.getCategoryTypeList(list)
  }

  getClassificationTypeList(list: CategoryClassificationAndTypesModel[]) {
    this.classificationTypeList = list?.filter( e => e.businessServiceType == this.businessServiceType.ServiceCategoryClassification)
  }

  getCategoryTypeList(list: CategoryClassificationAndTypesModel[]) {
    this.categoryTypeList = list?.filter( e => e.businessServiceType == this.businessServiceType.ServiceCategoryType)
  }

  // Edit Classification Type / Spacialities
  updateServiceCategory() {
    const dialogRef = this.dialog.open( SharedUpdateClassificationTypePopupComponent, {
      maxWidth: '900px',
      width: '90%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: { businessData: this.businessService, classificationOnly: false, spacialityOnly: false}
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.businessService = res
        this.setInitialList(res.businessServiceClassificationType)
      }
    });
  }
}
