import { state } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { State } from '@ngrx/store';
import { int } from 'aws-sdk/clients/datapipeline';
import { statusType } from 'aws-sdk/clients/iam';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DocumentCategory, DocumentGroup, DocumentStatus } from 'src/app/core/enums/document.enum';
import { StateShortTypes, StateType } from 'src/app/core/enums/main.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { LocationService } from 'src/app/modules/shared/services/location/location.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { BusinessFileUploadPopupComponent } from '../business-file-upload-popup/business-file-upload-popup.component';
import { BusinessFilesCustomDocumentModel, BusinessFilesModel } from '../business-files.model';
import { BusinessFilesService } from '../business-files.service';

@Component({
  selector: 'app-business-file-list-widget',
  templateUrl: './business-file-list-widget.component.html',
  styleUrls: ['./business-file-list-widget.component.scss']
})
export class BusinessFileListWidgetComponent implements OnInit {

  @Input() businessInfoId!: string
  @Input() hideStatus: boolean = false
  @Input() isOptional: boolean = false
  @Input() hasExpiry: boolean = true
  @Input() businessDocuments!: BusinessFilesModel[]
  @Input() currentDocumentCategory!: number
  @Input() isEditable: boolean = true
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentStatusEvent = new EventEmitter<any>();

  businessDocument!: BusinessFilesModel
  businessDocumentData = new BusinessFilesCustomDocumentModel()
  documentCategory = DocumentCategory
  documentGroup = DocumentGroup
  documentStatus = DocumentStatus
  isLoading = false

  statesListService = new Array<int>();
  statesListDoc = new Array<int>();

  setStateVal!: int;
  
  state = StateShortTypes;

  constructor(
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private businessDocumentServiceNew: BusinessFilesService,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.getServiceAreaList(this.businessInfoId, false);
    this.getBusinessDocumentsNew(this.businessInfoId);
  }

  getServiceAreaList(businessInfoId: string, isArchived: boolean) {
    this.locationService.getServiceAreaList(businessInfoId, isArchived).subscribe({
      next: res => {
        this.setServiceArea(res.data);
        
      }, error: err => { }
    })
  }

  private getBusinessDocumentsNew(businessInfoId: string) {
    this.isLoading = true;
    this.businessDocumentServiceNew.getBusinessDocumentListById(businessInfoId).subscribe((result: any) => {
      if (result.success) {
        this.setBusinessDoc(result.data.businessDocumentDetailsModel)
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }


  setServiceArea(res: any) {
    res.forEach((element: any) => {
      this.statesListService.push(element.state)
    });
    var unique = this.statesListService.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
  })

  this.statesListService = unique;
    // this.getBusinessDocumentsNew(this.businessInfoId);
  }

  setBusinessDoc(res: any) {
    res.forEach((element: any) => {
      this.statesListDoc.push(element.state)
    });

    var unique = this.statesListDoc.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
  })

  this.statesListDoc = unique;
    // this.removeDuplicates(this.statesListDoc)
    // this.statesListService.forEach(element => {
    //   let index = this.statesListDoc.findIndex(e => e == element);
    //   if (index !== -1) {
    //     this.statesListService.splice(index, 1);
    //   }
    // });

    //  this.removeDuplicates(this.statesListDoc)
    this.statesListDoc.forEach(element => {
      let index = this.statesListService.findIndex(e => e == element);
      if (index !== -1) {
        this.statesListService.splice(index, 1);
      }
    });
  }
  // removeDuplicates(arr: any) {
  //   var unique = this.statesListDoc.filter(function(elem, index, self) {
  //     return index === self.indexOf(elem);
  // })
  // this.statesListDoc = unique;
  // }


  uploadBusinessDocument(item: any) {
    this.setCreateDocumentData(null)
    this.setStateVal = item;
    const dialogRef = this.dialog.open(BusinessFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position: { top: '60px' },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: { isUpdate: false, documentData: this.businessDocumentData, item: item },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.businessDocuments.unshift(data);
        let index = this.statesListService.findIndex(e => e === data.state);
        if (index !== -1) {
          this.statesListService.splice(index, 1);
        }
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  updateBusinessDocument(businessDocument: BusinessFilesModel) {
    this.setCreateDocumentData(businessDocument)
    const dialogRef = this.dialog.open(BusinessFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position: { top: '60px' },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: { isUpdate: true, documentData: this.businessDocumentData },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.updateListItem(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  removeDocumentConfirm(businessDocument: BusinessFilesModel) {
    this.setCreateDocumentData(businessDocument)
    const dialogRef = this.dialog.open(SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        const isArchived = true
        this.deleteBusinessDocument(businessDocument, isArchived)
      }
    });
  }

  private deleteBusinessDocument(businessDocument: BusinessFilesModel, isArchived: boolean) {
    this.isLoading = true;
    this.businessDocumentServiceNew.deleteBusinessDocument(businessDocument.id, isArchived).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeDocumentEvent.emit(businessDocument.id)
          // this.businessDocument = null!
          // this.removeFromList(businessDocument.id);
          this.getServiceAreaList(this.businessInfoId, false);
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setCreateDocumentData(businessDocument: any) {
    this.businessDocumentData = new BusinessFilesCustomDocumentModel()
    if (businessDocument) {
      this.businessDocumentData.document = businessDocument
      this.businessDocumentData.documentName = businessDocument.name // this.businessDocumentData.documentName = null!
    }
    this.businessDocumentData.documentCategory = this.currentDocumentCategory
    if (this.currentDocumentCategory == 7)
      this.businessDocumentData.state = this.setStateVal;

    this.businessDocumentData.documentGroup = this.isOptional ? this.documentGroup.Additional : this.documentGroup.Mandatory
    this.businessDocumentData.hasExpiry = this.hasExpiry
    this.businessDocumentData.isBusinessDocument = true
    this.businessDocumentData.businessInfoId = this.businessInfoId
    this.businessDocumentData.isBusinessServiceDocument = false

  }

  updateListItem(data: BusinessFilesModel) {
    const index = this.businessDocuments.findIndex(x => x.id === data.id);
    this.businessDocuments[index] = data
  }

  removeFromList(id: string) {
    const index = this.businessDocuments.findIndex(x => x.id === id);
    if (index > -1) {
      this.businessDocuments.splice(index, 1);
    }
  }
}
