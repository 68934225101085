import { DatePipe } from '@angular/common';
import { Component, Inject, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentGroup } from 'src/app/core/enums/document.enum';
import { ServiceCategory } from '../../../models/service-category/service-category.model';
import { BusinessServiceAddModel, BusinessServiceModel, BusinessServiceUpdateModel } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ServiceCategoryClassification, ServiceCategoryType } from 'src/app/core/enums/main.enum';

@Component({
  selector: 'app-shared-update-service-category-popup',
  templateUrl: './shared-update-service-category-popup.component.html',
  styleUrls: ['./shared-update-service-category-popup.component.scss'],
  providers: [DatePipe],
})
export class SharedUpdateServiceCategoryPopupComponent implements OnInit {

  submitted = false;
  isLoading = false;
  hasCategory = false;
  hasClassification = false;
  hasType =false;
  formServiceCategory!: FormGroup;

  serviceCategoriesList: ServiceCategory[] = []
  selectedCategory!: string

  businessServiceAddData = new BusinessServiceAddModel();
  businessServiceUpdateData = new BusinessServiceUpdateModel();
  documentGroup = DocumentGroup

  isValidDocs = true
  isDocumentsAvailable = false
  isFinished = false
  addedDocumentList: any[] = []
  documentsFormArray: number[] = [];
  serviceCategoryId!: string;
  docLength = 0;

  updatedRates: any

  // Specialty
  selectable = true;
  removable = true;
  addOnBlur = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  specialtyList: string[] = [];
  @ViewChild('chipInput', { static: false }) chipInput!: ElementRef;


  ServiceCategoryClassification = ServiceCategoryClassification
  classificationList = [
    { name: 'Contractor', value: this.ServiceCategoryClassification.Contractor },
    { name: 'Consultant', value: this.ServiceCategoryClassification.Consultant },
  ]

  serviceCategoryType = ServiceCategoryType
  serviceCategoryTypeList = [
    { name: 'Residential', value: this.serviceCategoryType.Residential },
    { name: 'Commercial', value: this.serviceCategoryType.Commercial },
  ]

  classificationReqestList = []
  typeReqestList = []

  constructor(
    private alertService: AlertService,
    private tradieService: TradieService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SharedUpdateServiceCategoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public businessData: {
      isAdd: boolean;
      id: string;
      data: BusinessServiceModel;
      serviceCategories: ServiceCategory[];
    }) { }

  ngOnInit(): void {
    this.businessServiceAddData.businessInfoId = this.businessData.id
    // Get active service categories
    this.serviceCategoriesList = this.businessData.serviceCategories.filter(cat => cat.isArchived == false)

    this.formServiceCategory = this.formBuilder.group({
      businessInfoId: this.businessData.id,
      serviceCategoryId: [''],
      businessHourCallOutFee: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      businessHourHourlyRate: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      afterHourCallOutFee: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      afterHourHourlyRate: ['', [Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$'), Validators.max(21474836.00)]],
      quotationOnly: [''],
      documents: [],
      speciality: null,
    });

    if (this.businessData.isAdd) {
      this.addDocument()
    } else {
      this.setFormData(this.businessData.data)
    }
  }

  // convenience getter
  get f() { return this.formServiceCategory.controls; }

  get addDocumentForm() {
    return this.formServiceCategory.get('documents') as FormArray
  }

  onSubmit() {
    this.submitted = true;
    if (this.formServiceCategory.invalid) {
      return;
    }
    if (this.businessData.isAdd) {
      this.businessServiceAddData.speciality = this.specialtyList
      this.addServiceCategory(this.businessServiceAddData)
    } else {
      this.businessServiceUpdateData = this.setRequestUpdateData(this.formServiceCategory.value)
      this.updateBusinessService(this.businessServiceUpdateData)
    }
  }

  public serviceCategoryOnSelect(selectedId: string) {
    const serviceCategoryId = selectedId;
    if (serviceCategoryId) {
      this.hasCategory = true
      this.formServiceCategory.controls['serviceCategoryId'].setValue(serviceCategoryId);
      this.businessServiceAddData.serviceCategoryId = serviceCategoryId
    }
    this.checkIsFinished()
  }

  private addServiceCategory(data: BusinessServiceAddModel) {
    this.isLoading = true;
    data.categoryClassification = this.classificationReqestList
    data.categoryType = this.typeReqestList
    this.tradieService.addBusinessService(data).subscribe({
      next: response => {
        if (response.success) {
          this.alertService.success('Service category successfully added');
          this.dialogRef.close(response.data)
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }

  private updateBusinessService(data: BusinessServiceUpdateModel) {
    this.isLoading = true;
    this.tradieService.updateBusinessService(data).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.alertService.success('Service category details successfully updated');
          this.updatedRates = response.data
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      },
      error: (error: any) => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }

  setRequestUpdateData(data: BusinessServiceAddModel): BusinessServiceUpdateModel { // 1$ = 100 cents
    this.businessServiceUpdateData.id = this.businessData.data.id
    this.businessServiceUpdateData.businessHourCallOutFee = (data.businessHourCallOutFee * 100)
    this.businessServiceUpdateData.businessHourHourlyRate = (data.businessHourHourlyRate * 100)
    this.businessServiceUpdateData.afterHourCallOutFee = (data.afterHourCallOutFee * 100)
    this.businessServiceUpdateData.afterHourHourlyRate = (data.afterHourHourlyRate * 100)
    this.businessServiceUpdateData.quotationOnly = data.quotationOnly
    this.businessServiceUpdateData.speciality = this.specialtyList
    return this.businessServiceUpdateData
  }

  setFormData(data: BusinessServiceModel) {
    this.selectedCategory = this.businessData.data.serviceCategoryId
    this.formServiceCategory.controls['serviceCategoryId'].setValue(data.serviceCategoryId)
    this.formServiceCategory.controls['businessHourCallOutFee'].setValue((data.businessHourCallOutFee != 0) ? (data.businessHourCallOutFee / 100) : '')
    this.formServiceCategory.controls['businessHourHourlyRate'].setValue((data.businessHourHourlyRate != 0) ? (data.businessHourHourlyRate / 100) : '')
    this.formServiceCategory.controls['afterHourCallOutFee'].setValue((data.afterHourCallOutFee != 0) ? (data.afterHourCallOutFee / 100) : '')
    this.formServiceCategory.controls['afterHourHourlyRate'].setValue((data.afterHourHourlyRate != 0) ? (data.afterHourHourlyRate / 100) : '')
    this.formServiceCategory.controls['quotationOnly'].setValue(data.quotationOnly)

    // if(data.speciality) this.specialtyList = data.speciality
    if (data.businessServiceSpecialty) this.specialtyList = this.createArray(data.businessServiceSpecialty)
  }

  // --- Documents --- //
  addDocumentEvent(document: any) {
    this.addedDocumentList.push(document)
    this.businessServiceAddData.documents = this.addedDocumentList
    this.checkDocumentsAvailable()
    this.addDocument();
  }

  addDocument() {
    // Increase doc arry length
    this.docLength = this.docLength + 1;
    this.documentsFormArray.push(this.docLength);
  }

  deleteDocEvent(data: any) {
    this.documentsFormArray = this.documentsFormArray.filter(e => e != data.index)
    this.addedDocumentList = this.addedDocumentList.filter(e => e.index !== data.index)
    this.businessServiceAddData.documents = this.addedDocumentList
    this.checkDocumentsAvailable()
  }

  onDocStatusChangeEvent(isValid: boolean) {
    this.isValidDocs = isValid
    this.checkIsFinished()
  }

  checkDocumentsAvailable() {
    this.isDocumentsAvailable = (this.addedDocumentList.length > 0)
    this.checkIsFinished()
  }

  checkIsFinished() {
    this.isFinished = this.isValidDocs && this.hasCategory && this.hasClassification && this.hasType
    // this.isFinished = this.isValidDocs && this.hasCategory 
  }

  close() {
    let data = this.businessData.isAdd ? null : this.updatedRates
    this.dialogRef.close(data)
  }

  removeSpecialty(specialty: any): void {
    const index = this.specialtyList.indexOf(specialty);

    if (index >= 0) {
      this.specialtyList.splice(index, 1);
    }
  }

  addSpecialtyEvent(event: MatChipInputEvent): void {
    const value = event.value.replace(/\s/g, ' ');
    this.addToSpecialtyList(value)
  }

  addSpecialty(value: string) {
    this.addToSpecialtyList(value)
  }

  addToSpecialtyList(specialtyAddr: string) {
    const specialty = specialtyAddr.replace(/\s/g, ' ');

    if ((specialty || '').trim()) {

      this.specialtyList.push(specialty);
      this.formServiceCategory.controls['speciality'].setValue(this.specialtyList)

      this.chipInput.nativeElement.value = '';
    }
    // this.specialtyList.push({ specialty: value, invalid: false });
  }

  createArray(array: any) {
    let specialtyArray = []

    for (let i = 0; i < array.length; i++) {
      specialtyArray.push(array[i].specialtyName)

    }

    return specialtyArray
  }

  changeClassification(event: any) {
    this.classificationReqestList = event
    this.hasClassification = (this.classificationReqestList.length > 0)
    this.checkIsFinished()
  }

  changeType(event: any) {
    this.typeReqestList = event
    this.hasType = (this.typeReqestList.length > 0)
    this.checkIsFinished()
  }
}


