<section class="header-wr m-0 ps-4 pe-2 py-4">
  <h3 class="popup-header mb-0">{{ businessData.isAdd ? 'Add' : 'Update'}} Service Category & Documents</h3>
  <p class="mb-0">Please add a service category and upload all relevant documents.</p>
  <p class="mb-0 form-section-info text-secondary mb-2">Note: If you do not see your category listed please contact
    customer support.</p>
  <button class="close" mat-icon-button>
    <mat-icon (click)="close()">cancel</mat-icon>
  </button>
</section>


<main class="px-4 mt-2">
  <section class="category-info-wr">
    <p class="mb-1 font-semi-bold">Service category</p>
    <app-shared-select-category-widget [serviceCategoriesList]="serviceCategoriesList"
      [currentCategory]="selectedCategory" [isViewOnly]="!businessData?.isAdd"
      (categoryEvent)="serviceCategoryOnSelect($event)">
    </app-shared-select-category-widget>
  </section>

  <form class="row mt-0" [class.d-none]="businessData?.isAdd" [formGroup]="formServiceCategory">
    <div class="col-12">
      <p class="form-sub-title mb-2 text-uppercase">Business Hours <span class="optional-span">(optional)</span></p>
    </div>

    <div class="col-6 mb-2">
      <p class="hourly-rate ">Call out rate (first hour of work)</p>
      <mat-form-field class="w-100" appearance="legacy">
        <input type="number" formControlName="businessHourCallOutFee" matInput placeholder="Please type">
        <mat-error *ngIf="f['businessHourCallOutFee'].errors?.['pattern']">
          Please enter a valid amount.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 mb-2">
      <p class="hourly-rate ">Hourly rate</p>
      <mat-form-field class="w-100" appearance="legacy">
        <input type="number" formControlName="businessHourHourlyRate" matInput placeholder="Please type">
        <mat-error *ngIf="f['businessHourHourlyRate'].errors?.['pattern']">
          Please enter a valid amount.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <p class="form-sub-title mb-0 text-uppercase">After Hours <span class="optional-span">(optional)</span></p>
    </div>

    <div class="col-6 mb-2">
      <p class="hourly-rate ">Call out rate (first hour of work)</p>
      <mat-form-field class="w-100" appearance="legacy">
        <input type="number" formControlName="afterHourCallOutFee" matInput placeholder="Please type">
        <mat-error *ngIf="f['afterHourCallOutFee'].errors?.['pattern']">
          Please enter a valid amount.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6 mb-2">
      <p class="hourly-rate ">Hourly rate</p>
      <mat-form-field class="w-100" appearance="legacy">
        <input type="number" formControlName="afterHourHourlyRate" matInput placeholder="Please type">
        <mat-error *ngIf="f['afterHourHourlyRate'].errors?.['pattern']">
          Please enter a valid amount.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-12 mb-2">
      <mat-checkbox class="checkbox" formControlName="quotationOnly" appearance="standard">Receive only quote requests
      </mat-checkbox>
    </div>
  </form>

  <div class="row">
    <div class="col-6">
      <p class="text-sm font-semi-bold mb-0">Classification*</p>
      <mat-form-field class="w-100" appearance="legacy">
        <mat-label>Please select your classification</mat-label>
        <mat-select #ClassificationValue (selectionChange)="changeClassification($event.value)" multiple>
          <mat-option *ngFor="let classification of classificationList" [value]="classification.value">
            {{classification.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <p class="text-sm font-semi-bold mb-0">Type*</p>
      <mat-form-field class="w-100" appearance="legacy">
        <mat-label>Please select your type of service</mat-label>
        <mat-select #TypeValue (selectionChange)="changeType($event.value)" multiple>
          <mat-option *ngFor="let type  of serviceCategoryTypeList" [value]="type.value">
            {{type .name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</div>
  <div class="col-12">
    <p class="text-sm font-semi-bold mb-0">List your specialties relevant to the category selected above</p>
    <mat-form-field class="w-100" appearance="standard">
      <mat-chip-list #chipList aria-label="specialty selection">
        <mat-chip *ngFor="let specialty of specialtyList" [removable]="removable" (removed)="removeSpecialty(specialty)"
          class="chip-text">
          {{specialty}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input #chipInput [matChipInputFor]="chipList" placeholder="New Specialty..."
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addSpecialtyEvent($event)">
        <button *ngIf="chipInput.value" type="button" class="chip-add" matSuffix mat-mini-fab aria-label="add"
          (click)="addSpecialty(chipInput.value)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-chip-list>
    </mat-form-field>
  </div>

  <section class="documents-wr" [class.d-none]="!businessData?.isAdd">
    <p class="text-sm font-semi-bold text-uppercase">Documents</p>

    <div class="title-wr d-flex align-items-end">
      <span class="column-litle doc-name w-40 mb-1 "><small>Document</small></span>
      <span class="column-litle doc-exdate w-30 pr-2 mb-1 mr-3">Document Name<small></small></span>
      <span class="column-litle doc-link w-30 pr-2 mb-1 mr-3"> Expiry Date<small></small></span>
    </div>

    <div *ngFor="let index of documentsFormArray; index as i">
      <app-shared-category-document-creation-widget [documentIndex]="index" [serviceCategoryId]="serviceCategoryId"
        (onDocStatusChangeEvent)="onDocStatusChangeEvent($event)" (deleteDocEvent)="deleteDocEvent($event)"
        (createDocumentEvent)="addDocumentEvent($event)">
      </app-shared-category-document-creation-widget>
    </div>
  </section>

  <section class="action-btns d-flex align-items-center justify-content-end mt-4 mb-3">
    <button *ngIf="businessData?.isAdd" class="btn primary shadow-none px-5 ms-2" mat-raised-button
      [disabled]="!isFinished" (click)="onSubmit()">Save
    </button>

    <button *ngIf="!businessData?.isAdd" class="btn primary shadow-none px-5 ms-2" mat-raised-button
      (click)="onSubmit()">Save
    </button>
  </section>
</main>