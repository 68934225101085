import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NDISDocumentUpdate, NdisDocumentCreate } from './ndis-files.model';


@Injectable({
  providedIn: 'root'
})
export class NdisFilesService {

  private endpoint = environment.endpointURL + '/api/NdisDocument';

  constructor(private http: HttpClient) { }

  public createDocument(data: NdisDocumentCreate): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.post<any>(url, data);
  }

  public updateDocument(data: NDISDocumentUpdate): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.put<any>(url, data);
  }

  public updateDocumentStatus(data: any): Observable<any> {
    const url = `${this.endpoint}/UpdateNdisDocumentStatus`;
    return this.http.patch(url, data);
  }

  public deleteDocument(documentId: string, isArchived: boolean): Observable<any> {
    const url = `${this.endpoint}?id=${documentId}&isArchived=${isArchived}`;
    return this.http.delete<any>(url);
  }

  public getDocumentListById(businessInfoId: string): Observable<Document> {
    const url = `${this.endpoint}/GetByBusinessInfoId?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }
}