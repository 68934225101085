<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
    <p class="popup-header mb-0 mb-0">Update Business Contact Mobile </p>
    <button mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
</div>
  
<mat-divider></mat-divider>
  
<div class="popup-wr p-4">
<form class="row mt-0" [formGroup]="formUpdateContactMobile" (ngSubmit)="onSubmit()">

    <div class="col-12 mb-3">
        <mat-form-field class="w-100" appearance="legacy">
            <mat-label>Contact Mobile</mat-label>
            <input matInput placeholder="Mobile number" formControlName="contactMobile">
            <!-- <mat-error *ngIf="f['contactMobile'].errors?.['required']">
                Please enter contact mobile number.
            </mat-error> -->
            <mat-error *ngIf="f['contactMobile'].errors?.['pattern']">
                Please enter valid contact mobile number.
            </mat-error>
        </mat-form-field>
    </div>


    <div class="col-12 action-btns text-right w-100 mt-3">
        <div class="d-flex align-items-center justify-content-end">
            <button class="btn primary shadow-none" mat-raised-button [disabled]="isLoading">Update</button>
            <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
        </div>
    </div>
</form>
</div>