<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
    <p *ngIf="!data.spacialityOnly && !data.classificationOnly" class="popup-header font-bold mb-0">Update Service Category</p>
    <p *ngIf="data.classificationOnly" class="popup-header font-bold mb-0">Update Classification Types</p>
    <p *ngIf="data.spacialityOnly" class="popup-header font-bold mb-0">Update Spacialities</p>
    <button mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
</div>

<mat-divider></mat-divider>

<main class="px-4 mt-4">
    <form class="row mt-0" [formGroup]="formBusinessService" (ngSubmit)="onSubmit()">
        
        <div class="col-6" [class.d-none]="data.spacialityOnly">
            <p class="text-sm font-semi-bold mb-0">Classification*</p>
            <mat-form-field class="w-100" appearance="legacy">
                <mat-label>Please select your classification</mat-label>
                <mat-select formControlName="categoryClassification" #ClassificationValue  (selectionChange)="onChangeClassification($event.value)" multiple>
                    <mat-option *ngFor="let classification of classificationList" [value]="classification.value">
                        {{classification.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> 

        <div class="col-6" [class.d-none]="data.spacialityOnly">
            <p class="text-sm font-semi-bold mb-0">Type*</p>
            <mat-form-field class="w-100"  appearance="legacy">
                <mat-label>Please select your type of service</mat-label>
                <mat-select formControlName="categoryType" #TypeValue (selectionChange)="onChangeType($event.value)" multiple>
                    <mat-option *ngFor="let type  of serviceCategoryTypeList" [value]="type.value">
                        {{type .name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12" [class.d-none]="data.classificationOnly">
            <mat-form-field class="w-100" appearance="standard">
                <mat-chip-list #chipList aria-label="specialty selection">
                    <mat-chip *ngFor="let specialty of specialtyList" [removable]="removable" (removed)="removeSpecialty(specialty)" class="chip-text">
                        {{specialty}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input 
                        #chipInput 
                        [matChipInputFor]="chipList" 
                        placeholder="New Specialty..."
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addSpecialtyEvent($event)">
                        <button *ngIf="chipInput.value" type="button" class="chip-add" matSuffix mat-mini-fab aria-label="add" (click)="addSpecialty(chipInput.value)">
                            <mat-icon>add</mat-icon>
                        </button>
                </mat-chip-list>
            </mat-form-field>
        </div>

        <section class="action-btns d-flex align-items-center justify-content-end mt-4 mb-3">
            <button [disabled]="!isFinished" class="btn primary shadow-none px-5 ms-2" mat-raised-button>Save</button>
        </section>
    </form>
</main>