
import { DocumentStatus } from "src/app/core/enums/document.enum";


export class IndemnityCreateUploadModel{
    id!: string;
    businessInfoId!: string;
    // userId!: string;
    renewProfessionalIdemnityInsurance!: boolean;
    isArchived!: boolean;
    lastUpdatedDate!: any;
    professionalIdemnityCompliancesDetails : IndemnityDocumentDetailModel[] = [];

    public addIndemnityDocumentModel(document : IndemnityDocumentDetailModel){
        this.professionalIdemnityCompliancesDetails.push(document);
    }
}

export class IndemnityUpdateUploadModel{
    id!: string;
    businessInfoId!: string;
    userId!: string;
    renewProfessionalIdemnityInsurance!: boolean;
    isArchived!: boolean;
    lastUpdatedDate!: any;
    professionalIdemnityCompliancesDetailsModel : IndemnityDocumentDetailModel[] = [];

    public addIndemnityDocumentModel(document : IndemnityDocumentDetailModel){
        this.professionalIdemnityCompliancesDetailsModel.push(document);
    }
}





export class IndemnityDocumentDetailModel{
    id!: string;
    documentType!: DocumentType;
    professionalIdemnityDocument !: boolean;
    professionalIdemnityCompliancesHeaderId !: string;
    link!: string;
    professionalIndemnity!: number;
    documentStatus!: DocumentStatus;
    comment!: string;
    documentName!: string;
    policyNumber!: string;
    insuranceProvider!: string;
    fromDate!: any;
    expireDate!: any;
    isArchived!: boolean;
    lastUpdatedDate!: any;
    
}

export class IndemnityFilesCustomDocumentModel {
    documentName!: string;
    documentCategory!: number;
    documentGroup!: number;
    hasExpiry!: boolean;
    currentUserId!: string;
  
    document!: IndemnityDocumentDetailModel;
    businessInfoId!: any;
    businessServiceId!: any;
    fileLocation!: any;
  
    isOtherDoc!: boolean;
    otherDocExpiry!: string;
    otherDocURL!: string;
}