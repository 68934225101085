import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StateType } from 'src/app/core/enums/main.enum';
import { StateCoordinates } from 'src/app/modules/onboarding/shared/models/location/location.model';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-shared-service-area-widget',
  templateUrl: './shared-service-area-widget.component.html',
  styleUrls: ['./shared-service-area-widget.component.scss']
})
export class SharedServiceAreaWidgetComponent implements OnInit {

  isLoading = false

  @Output() stateListEvent = new EventEmitter<any>();
  @Input() businessInfoId!: string
  @Input() tradeId!: string
  @Input() isEditOnly!: boolean

  serviceAreaList: any[] = []
  stateCoordinates: StateCoordinates[] = []
  mergedServiceAreaList: any[] = []
  statetype = StateType

  stateList = [
    { isActive: false, name:'New South Wales', value: this.statetype.NewSouthWales},
    { isActive: false, name:'Victoria', value: this.statetype.Victoria},
    { isActive: false, name:'Queensland', value: this.statetype.Queensland},
    { isActive: false, name:'Western Australia', value: this.statetype.WesternAustralia},
    { isActive: false, name:'South Australia', value: this.statetype.SouthAustralia},
    { isActive: false, name:'Tasmania', value: this.statetype.Tasmania},
    { isActive: false, name:'Northern Territory', value: this.statetype.NorthernTerritory},
    { isActive: false, name:'Australian Capital Territory', value: this.statetype.AustralianCapitalTerritory}
  ]

  constructor(
    public dialog: MatDialog,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.getStateCoordinates()
    this.getServiceAreaList(this.businessInfoId)
  }

  private getServiceAreaList(businessInfoId: string) {
    this.isLoading = true
    this.locationService.getServiceAreaList(businessInfoId).subscribe({
      next: res => {
        if (res.success) {
          this.serviceAreaList = res.data
          this.mergeStateList()
        }
        this.isLoading = false
      }, error: err => { this.isLoading = false }
    })
  }

  private getStateCoordinates() {
    this.locationService.getStateCoordinate().subscribe( (data: StateCoordinates[]) => {
      this.stateCoordinates = data
    })
  }

  mergeStateList() {
    this.stateList.forEach(state => {

      const index = this.serviceAreaList.findIndex(x => x.state === state.value);

      let currentState = null
      if (index > -1) {
        currentState = this.serviceAreaList[index]
        state.isActive = true
      } 

      const item = {
        defaultState: state,
        availableState: currentState
      }
      this.mergedServiceAreaList.push(item)
    })
  }

  stateEnableEvent(event: any) {
    this.stateListEvent.emit()
  }
}
