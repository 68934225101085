export class Location {}

export class ServiceArea {
  id!: string;
  userId!: string;
  name!: string;
  businessInfoId!: string;
  raduis!: number;
  state!: number;
  geoLocation!: GeoLocation;

  public setGeoLocation(latitude: number, longitude: number) {
    this.geoLocation = {
      latitude: latitude,
      longitude: longitude,
    };
  }
}
export interface GeoLocation {
  latitude: number;
  longitude: number;
}
export interface ServiceAreaList {
  id: string;
  name: string;
  raduis: number;
  state: string;
  userId: string;
  businessInfoId: string;
  lastUpdatedTime: string;
  location: GeoLocation;
  isArchived: boolean;
}
export class UpdateServiceAreaLocation {
  serviAreaId!: string;
  locationList: LocationList[] = [];

  public addLocation(name: string, raduis: number, geoLocation: GeoLocation) {
    let location = {
      name: name,
      raduis: raduis * 1000,
      geoLocation: geoLocation,
    };
    this.locationList.push(location);
  }
}
export interface LocationList {
  name: string;
  raduis: number;
  geoLocation: GeoLocation;
}
export interface TempLocation {
  index: number;
  name: string;
  raduis: number;
  geoLocation: GeoLocation;
}

export interface GeoLocation {
  latitude: number;
  longitude: number;
}

export class CurrentMapSearchData {
  index!: number;
  latitude!: number;
  longitude!: number;
}

export interface FormCreationArray{
  index: number;
  locationData: LocationList | null;
}
export interface StateCoordinates {
  state:       number;
  coordinates: Coordinates;
  bounds:      Bounds;
}

export interface Bounds {
  south: number;
  west:  number;
  north: number;
  east:  number;
}

export interface Coordinates {
  latitude:  number;
  longitude: number;
}
