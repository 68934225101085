import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessServiceType, ServiceCategoryClassification, ServiceCategoryType } from 'src/app/core/enums/main.enum';
import { BusinessServiceModel, BusinessServiceUpdateModel } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-shared-update-classification-type-popup',
  templateUrl: './shared-update-classification-type-popup.component.html',
  styleUrls: ['./shared-update-classification-type-popup.component.scss']
})
export class SharedUpdateClassificationTypePopupComponent implements OnInit {

  isLoading = false;
  formBusinessService!: FormGroup

  // -- Classifications -- //
  ServiceCategoryClassification = ServiceCategoryClassification
  classificationList = [
    { name: 'Contractor', value: this.ServiceCategoryClassification.Contractor },
    { name: 'Consultant', value: this.ServiceCategoryClassification.Consultant },
  ]

  serviceCategoryType = ServiceCategoryType
  serviceCategoryTypeList = [
    { name: 'Residential', value: this.serviceCategoryType.Residential },
    { name: 'Commercial', value: this.serviceCategoryType.Commercial },
  ]

  businessServiceUpdateData = new BusinessServiceUpdateModel();
  classificationReqestList : any[] = []
  typeReqestList : any[] = []

  // -- Spacialities -- //
  isFinished = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  specialtyList: string[] = [];
  @ViewChild('chipInput', {static: false}) chipInput!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private tradieService: TradieService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<SharedUpdateClassificationTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {businessData: BusinessServiceModel, classificationOnly: boolean, spacialityOnly: boolean},
  ) { }

  ngOnInit(): void {
    this.formBusinessService = this.formBuilder.group({
      id: this.data.businessData.id,
      businessInfoId: this.data.businessData.businessInfoId,
      serviceCategoryId: [this.data.businessData.serviceCategoryId],
      speciality: [''],
      categoryClassification: [''],
      categoryType: [''],
    });

    this.setUpdatedClassificationTypedata()
  }

  onSubmit() {
    this.businessServiceUpdateData = this.setRequestUpdateData(this.formBusinessService.value)
    this.updateBusinessService(this.businessServiceUpdateData)
  }

  setRequestUpdateData(data: BusinessServiceUpdateModel) {
    this.businessServiceUpdateData = data
    this.businessServiceUpdateData.speciality = this.specialtyList
    this.businessServiceUpdateData.categoryClassification = data.categoryClassification
    this.businessServiceUpdateData.categoryType = data.categoryType
    return this.businessServiceUpdateData
  }

  // -- Classifications -- //
  setUpdatedClassificationTypedata() {
    let classificationAndTypsList = this.data.businessData?.businessServiceClassificationType
    classificationAndTypsList.forEach(element => {
      if (element.businessServiceType == BusinessServiceType.ServiceCategoryClassification) {
        this.classificationReqestList.push(element.businessServiceTypeValue)
      }  
      else if (element.businessServiceType == BusinessServiceType.ServiceCategoryType) {
        this.typeReqestList.push(element.businessServiceTypeValue)
      }
    });
    this.setFormData()
  }

  setFormData() { 
    this.specialtyList = this.data.businessData.businessServiceSpecialty.map(e => e.specialtyName)
    this.formBusinessService.controls['categoryClassification'].setValue(this.classificationReqestList)
    this.formBusinessService.controls['categoryType'].setValue(this.typeReqestList)
    this.checkIsFinished()
  }

  onChangeClassification(event: any) {
    this.classificationReqestList = event
    this.checkIsFinished()
  }

  onChangeType(event: any) {
    this.typeReqestList = event
    this.checkIsFinished()
  }

  checkIsFinished( ){
    const isClassificationsDone = (this.classificationReqestList.length > 0 && this.typeReqestList.length > 0 )
    this.isFinished = this.data.spacialityOnly ? true : isClassificationsDone
  }

  // -- Spacialities -- //
  addSpecialtyEvent(event: MatChipInputEvent): void {
    const value = event.value.replace(/\s/g, ' ');
    this.addToSpecialtyList(value)
  }

  addSpecialty(value:string) {
    this.addToSpecialtyList(value)
  }

  removeSpecialty(specialty: any): void {
    const index = this.specialtyList.indexOf(specialty);
    if (index >= 0) {
      this.specialtyList.splice(index, 1);
    }
  }

  addToSpecialtyList(specialtyAddr: string) {
    const specialty = specialtyAddr.replace(/\s/g, ' ');
    if ((specialty || '').trim()) {
        this.specialtyList.push(specialty);
        this.chipInput.nativeElement.value = '';
    }
  }

  createArray(array:any) {
    let specialtyArray = []
    for (let i = 0 ; i < array.length ; i++){
      specialtyArray.push(array[i].specialtyName)
    }
    return specialtyArray
  }

  // Update service category
  private updateBusinessService(data: BusinessServiceUpdateModel) {
    this.isLoading = true;
    this.tradieService.updateBusinessService(data).subscribe({
      next: response => {
        if (response.success) {
          this.alertService.success('Service category details successfully updated');
          this.dialogRef.close(response.data);
        } else {
          this.alertService.error(response.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }
}
