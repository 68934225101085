import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserFilesCreateModel, UserFilesEditModel } from './user-files.model';

@Injectable({
  providedIn: 'root'
})
export class UserFilesService {

  private endpoint = environment.endpointURL + '/api/Document';

  constructor(private http: HttpClient) { }

  public createDocumentById(data: UserFilesCreateModel): Observable<any> {
    const url = `${this.endpoint}/DocumentNew`;
    return this.http.post<any>(url, data);
  }

  public deleteDocument(documentId: string): Observable<any> {
    const url = `${this.endpoint}/DocumentNew?id=${documentId}`;
    return this.http.delete<any>(url);
  }

  public updateDocument(data: UserFilesEditModel): Observable<any> {
    const url = `${this.endpoint}/DocumentNew`;
    return this.http.put<any>(url, data);
  }

  public getDocumentsByUserId(userId: string): Observable<any> {
    const url = `${this.endpoint}/GetByUserIdNew?UserId=${userId}`;
    return this.http.get<any>(url);
  }

  public updateDocumentAnswer(data: any): Observable<any> {
    const url = `${this.endpoint}/DocumentAnswerUpdate`;
    return this.http.patch(url, data);
  }
}
