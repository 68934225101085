<section class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header mb-0">Add Locations in <span class="text-capitalize">{{data.stateName}}</span></p>
  <button mat-icon-button mat-dialog-close><mat-icon>cancel</mat-icon></button>
</section>

<mat-divider></mat-divider>

<section class="popup-wr p-4" [class.d-none]="!data?.currentStateCoordinate">
  <div class="row">
    <div class="col-12">
      <p class="font-bold mb-3">You will only receive work for each state you are operating in. Your licenses and registrations will be checked against what you have selected</p>
    </div>

    <!-- Locations List -->
    <div class="col-5">
        <p class="font-semi-bold mb-3">If you have any specific locations operating in, please add below.</p>
        <div *ngFor="let item of locationsFormArray;" class="mb-1">
          <app-shared-add-location-widget
            [isEditOnly]="data.isEditOnly"
            (onLocationSearchEvent)="onLocationSearchEvent($event)"
            (onRadiusChangeEvent)="onRadiusChangeEvent($event)"
            (onLocationUpdateEvent)="onLocationUpdateEvent($event)"
            (addLocationEvent)="addLocationEvent($event)"
            (editLocationEvent)="editLocationEvent($event)"
            (removeLocationEvent)="removeLocationEvent($event)"
            (onLocationStatusChangeEvent)="onLocationStatusChangeEvent($event)"
            [addedLocationData]="item?.locationData"
            [locationIndex]="item.index">
          </app-shared-add-location-widget>
        </div>
    </div>

    <!-- Map -->
    <div class="col-7">
      <!-- Settings => [fitBounds]="true" [restriction]="countryRestriction" -->
      <section class="maps-wr bg-secondary">
        <agm-map 
          [restriction]="countryRestriction"
          [latitude]="currentLatitude" 
          [longitude]="currentLongitude" 
          [zoom]="zoom"
          [disableDefaultUI]="false"
          (mapClick)="mapClicked($event)">

          <!-- Current Search : Location -->
          <agm-marker 
            *ngIf="isLocationSearching || isMapClicked"
            [latitude]="currentLatitude" 
            [longitude]="currentLongitude" 
            [markerDraggable]="false"
            [iconUrl]="iconURL"
            [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)">
          </agm-marker>
          
          <agm-circle 
            *ngIf="isLocationSearching && (currentSearchRadius > 0)"
            [latitude]="currentLatitude" 
            [longitude]="currentLongitude" 
            [radius]="currentSearchRadius"
            [fillColor]="'#39CCF2'"
            [circleDraggable]="true"
            [editable]="false">
          </agm-circle>

          <!-- Added Locations -->
          <agm-marker 
            [visible]="!isLocationEditing"
            *ngFor="let location of tempLocationList"
            [latitude]="location.geoLocation.latitude" 
            [longitude]="location.geoLocation.longitude" 
            [markerDraggable]="false"
            [iconUrl]="iconURL"
            [markerDraggable]="false">
          </agm-marker>

          <agm-circle 
            [visible]="!isLocationEditing"
            *ngFor="let location of tempLocationList"
            [latitude]="location.geoLocation.latitude" 
            [longitude]="location.geoLocation.longitude" 
            [radius]="location.raduis"
            [fillColor]="'#39CCF2'"
            [circleDraggable]="false"
            [editable]="false">
          </agm-circle>

        </agm-map>
      </section>
      <div class="action-btns d-flex align-items-center justify-content-end w-100 mt-3 mr-4">
        <button class="btn primary w-mid" mat-flat-button [disabled]="isLoading || !canSave" (click)="save()">Save</button>
        <mat-spinner class="ms-2" diameter="25" *ngIf="isLoading"></mat-spinner>
      </div>
    </div>
  </div>

  <div 
    *ngIf="isLoading" 
    class="map-loader w-100 h-100">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</section>


<section class="rounded p-5" *ngIf="!data?.currentStateCoordinate">
  <p class="m-0 text-center font-bold">
    Loading...
  </p>
</section>
