import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessInfo } from '../../../models/tradie/tradie.model';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from '../../../services/tradie/tradie.service';

@Component({
  selector: 'app-update-business-contact-name-popup',
  templateUrl: './update-business-contact-name-popup.component.html',
  styleUrls: ['./update-business-contact-name-popup.component.scss']
})
export class UpdateBusinessContactNamePopupComponent implements OnInit {

  formUpdateContactName!: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpdateBusinessContactNamePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public  businessInfo: BusinessInfo,
    private alertService: AlertService,
    private tradieService: TradieService,
  ) { }

  ngOnInit(): void {
    this.formUpdateContactName = this.formBuilder.group({
      businessInfoId: this.businessInfo.id,
      contactName: [this.businessInfo.contactName]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.formUpdateContactName.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.formUpdateContactName.invalid) {
      return;
    }
    this.updateBusinessContactName()
  }

  // Update Contact Name
  updateBusinessContactName() {
    this.isLoading = true;
    this.tradieService.updateBusinessContactName(this.formUpdateContactName.value).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.alertService.success('Business contact name successfully updated');
          const address = response.data;
          this.dialogRef.close(address);
        } else {
          this.alertService.error('Operation failed');
        }
      }, error : err => {
        this.isLoading = false;
        this.alertService.error('Operation failed');
      }
    });
  }

}
