import * as AWS from 'aws-sdk';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentCategoryType, DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { NdisFilesService } from '../ndis-files.service';
import { NDISDocumentUpdate, NdisDocumentCreate } from '../ndis-files.model';
import { GetStateNameByTypePipe } from 'src/app/modules/shared/pipes/get-state-name-by-type.pipe';
import { StateType } from 'src/app/core/enums/main.enum';
import { log } from 'console';

@Component({
  selector: 'app-ndis-file-upload-popup',
  templateUrl: './ndis-file-upload-popup.component.html',
  styleUrls: ['./ndis-file-upload-popup.component.scss'],
  providers: [DatePipe, GetStateNameByTypePipe]
})
export class NdisFileUploadPopupComponent implements OnInit {
  
  formNdisDocument!: FormGroup;
  submitted = false;
  isLoading = false;
  isOtherDoc = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();

  stateType = StateType
  stateList = [
    {viewValue: 'Victoria State', value: this.stateType.Victoria},
    {viewValue: 'New South Wales State', value: this.stateType.NewSouthWales},
    {viewValue: 'South Australia State', value: this.stateType.SouthAustralia},
    {viewValue: 'Queensland State', value: this.stateType.Queensland},
    {viewValue: 'Western Australia State', value: this.stateType.WesternAustralia},
  ]

  documentStatus = DocumentStatus
  documentType = DocumentCategoryType;

  createNdisDocumentData = new NdisDocumentCreate()
  updateNdisDocumentData = new NDISDocumentUpdate()

  constructor(
    private datePipe: DatePipe,
    private getStateNameByTypePipe: GetStateNameByTypePipe,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private ndisFilesService: NdisFilesService,
    public dialogRef: MatDialogRef<NdisFileUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {businessInfoId: string, ndisTradeId: string, hasState: boolean, documentData: any},
  ) { }

  ngOnInit(): void {
    this.formNdisDocument = this.formBuilder.group({
      id: [],
      ndisTradeId: [ this.data.ndisTradeId],
      state: [''],
      name: [ '', Validators.required], 
      link: ['', Validators.required],
      expireDate: [''],
      documentStatus: [this.documentStatus.Processing],
      documentType: ['', Validators.required],
    });
    if (this.data.documentData) {
      this.setUpdateFormData()
    }
    // this.setDisabledType()
  }

  get f() {
    return this.formNdisDocument.controls;
  }

  onSubmit() {
    this.submitted = true
    if (this.formNdisDocument.invalid) {return;}
    if (this.data.documentData?.link === this.formNdisDocument.value.link) {
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();
    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.formNdisDocument.controls['link'].setValue(this.fileName);
    // set file extention / doc type
    const currrentDocType  = this.getDocumentType(this.fileData.name)
    this.formNdisDocument.controls['documentType'].setValue(currrentDocType);
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
    this.setFileName();
  }

  uploadDocToS3() {
    this.isLoading = true
    this.formData = new FormData();
    this.formData.append('file', this.fileData);
    const fileName = this.setFileName();
    const contentType = this.fileData.type;
    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });
    const params = {
      Bucket: environment.config.location,
      Key: `Trade/${this.data.businessInfoId}/Documents/Ndis/` + fileName,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };
    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }
      this.onAWSUploadComplete(response.Key)
      return true;
    });
  }

  onAWSUploadComplete(key: string) {
    this.isLoading = false
    this.formNdisDocument.patchValue({link: key});
    this.saveForm()
  }

  saveForm() {
    if (this.data.documentData?.id) {
      this.updateDocument(this.formNdisDocument.getRawValue());
    } else {
      this.createDocument(this.formNdisDocument.getRawValue());
    }
  }

  private createDocument(data: any) {
    this.createNdisDocumentData = data;
    this.createNdisDocumentData.expireDate = this.convertDate(data.expireDate)!
    this.ndisFilesService.createDocument(this.createNdisDocumentData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.onDocumentCreateSuccess(response.data)
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },  error: (error: any) => {
          this.alertService.error('Operation failed!');
          this.isLoading = false;
        },
      });
  }

  onDocumentCreateSuccess(data: any) {
    this.submitted = false
    this.alertService.success('Document created successfully');
    this.dialogRef.close(data)
  }

  private updateDocument(data: any) {
    this.isLoading = true;
    this.updateNdisDocumentData = data;
    this.updateNdisDocumentData.expireDate = this.convertDate(data.expireDate)!
    this.ndisFilesService.updateDocument(this.updateNdisDocumentData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.onDocumentUpdateSuccess(response.data)
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },  error: (error: any) => {
          this.alertService.error('Operation failed!');
          this.isLoading = false;
        },
      });
  }

  onDocumentUpdateSuccess(data: any) {
    this.alertService.success('Document updated successfully');
    this.dialogRef.close(data)
  }

  onDocumentStateChange(event: any) {
    let state = event.value
    this.formNdisDocument.controls['state'].setValue(state);
  }

  setUpdateFormData() {
    this.formNdisDocument.controls['id'].setValue(this.data.documentData?.id)
    this.formNdisDocument.controls['name'].setValue(this.data.documentData?.name)
    this.formNdisDocument.controls['state'].setValue(this.data.documentData?.state)
    this.formNdisDocument.controls['expireDate'].setValue(this.data.documentData?.expireDate)
    this.formNdisDocument.controls['link'].setValue(this.data.documentData?.link)
    this.formNdisDocument.controls['documentStatus'].setValue(this.documentStatus.Processing)
    const currrentDocType  = this.getDocumentType(this.data.documentData?.link)
    this.formNdisDocument.controls['documentType'].setValue(currrentDocType);
  }

  setFileName() {
    var name = 'NDIS_Document'
    if (this.data.hasState) {
      name = this.createFileNameByState(this.formNdisDocument.value.state)
    } else {
      name = this.createFileNameByString(this.formNdisDocument.value.name)
    }
    return name
  }

  createFileNameByString(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  createFileNameByState(stateValue: number) {
    let fileName;
    let state = this.getStateNameByTypePipe.transform(stateValue)
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    const fileExtension = this.fileData?.name.split('.').pop();
    fileName = state ? state.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : 'NDIS_Document';
    this.formNdisDocument.controls['name'].setValue(fileName);
    return fileName+fileExtension;
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  // setDisabledType() {
  //   this.documentCategoryList.forEach( category => {
  //     category.isDisabled = ( this.data?.types?.includes(category.value) === true )
  //   })
  // }

  convertDate(date: string) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null;
  }
  
  clearDate() {
    this.formNdisDocument.controls['expireDate'].setValue(null);
  }
}
