import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../../services/alert/alert.service';
import { ModuleMatrixService } from '../module-matrix.service';

@Component({
  selector: 'app-shared-module-matrix-preview-popup',
  templateUrl: './shared-module-matrix-preview-popup.component.html',
  styleUrls: ['./shared-module-matrix-preview-popup.component.scss']
})
export class SharedModuleMatrixPreviewPopupComponent implements OnInit {

  activeModule: any
  isLoading = false

  constructor(
    private moduleMatrixService: ModuleMatrixService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: { module: any },
    public dialogRef: MatDialogRef<SharedModuleMatrixPreviewPopupComponent>
  ) { }
  
  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    this.receiveMessage(event);
  }

  receiveMessage(event: any) {
    if (event.data.isCompleted) this.compleateModule(this.data.module.tradeModuleId)
    else this.alertService.error('Something went wrong!')
  }

  ngOnInit(): void {
    this.activeModule = this.data.module
  }

  private compleateModule(tradeModuleId: string, pdfLink?: string) {
    this.isLoading = true
    this.moduleMatrixService.completedModuleMatrix(tradeModuleId, pdfLink).subscribe({
      next: res => {
        if(res.success) {
          this.dialogRef.close(true)
          this.isLoading = false 
          this.alertService.success('Module completed successfully')
        }
      }, error: err => { this.isLoading = false }
    })
  }

  close(){
    this.dialogRef.close(false)
  }

}
