export enum Document {}

export enum DocumentCategoryType {
  DOC = 1,
  PDF = 2,
  PNG = 3,
  JPEG = 4,
}

export enum DocumentCategory {
  DrivingLicense = 1,
  Passport = 2,
  Other = 5,
  Insurance = 3,
  PoliceClearence = 4,
  PublicLiabilityCertificate = 6,
  WorkCoverCertificate = 7,
  Signature = 8,
  CovidVaccinationCertificate = 9,
  GovernmentPhotoId = 10,
  OtherProofOfIdentity = 11,
  WhiteCard = 12,
}

export enum DocumentGroup {
  Mandatory = 1,
  Additional = 2,
  NotSet = 3,
}

export enum DocumentStatus {
  Processing = 1,
  Verified = 2,
  Rejected = 3,
  Expired = 4,
  Alert = 5,
  Exempt = 6,
}

export enum HealthSafetyDocumentType {
  OHSManagementSystemDocument = 1,
  RelevantAccreditationDocument = 2,
  JSAOrSWMSDocument = 3,
  StandardOperatingProcedureDocument = 4,
  RiskAssessmentDocument = 5,
  InductionProcessDocument = 6,
}

export enum LeadStatusType {
  InviteSent = 1,
  Onboarding = 2,
  NewApplicant = 3,
  Approved = 4,
}

export enum EmployeeRangeType {
    Zero = 1,
    From01To05 = 2,
    From06To10 = 3,
    From11To20 = 4,
    From21To100 = 5,
    From101To200 = 6,
    From201 = 7,
}