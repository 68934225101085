import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { environment } from 'src/environments/environment'; 
import {
  DocumentCategory,
  DocumentCategoryType,
  DocumentGroup,
} from 'src/app/core/enums/document.enum';
import {
  BusinessDocumentUpdateModel,
  BusinessServiceDocumentModel,
  CustomDocumentModel,
} from 'src/app/modules/shared/models/document.model';
import * as AWS from 'aws-sdk';
import { AlertService } from '../../../services/alert/alert.service';
import { TradieService } from 'src/app/modules/onboarding/shared/services/tradie.service';


@Component({
  selector: 'app-shared-service-document-upload-popup',
  templateUrl: './shared-service-document-upload-popup.component.html',
  styleUrls: ['./shared-service-document-upload-popup.component.scss'],
  providers: [DatePipe]
})
export class SharedServiceDocumentUploadPopupComponent implements OnInit {

  formServiceDocument!: FormGroup;
  submitted = false;
  isLoading = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();
  linkCategoryObject : any[] =[]

  serviceDocumentData = new BusinessServiceDocumentModel();

  documentType = DocumentCategoryType;
  documentCategory = DocumentCategory;
  
  documentGroup = DocumentGroup;
  documentGroupList : any[] = [
    { value: this.documentGroup.Mandatory, viewValue: 'Mandatory'},
    { value: this.documentGroup.Additional, viewValue: 'Additional'}
  ]

  linkcategoryList: any[] = []
  businessData!: any
  linkCategoryObj: any[] =[]
  constructor(
    private tradieService: TradieService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      businessDocumentData: CustomDocumentModel;
      businessInfo: any;
    },
    public dialogRef: MatDialogRef<SharedServiceDocumentUploadPopupComponent>
  ) {}

  ngOnInit(): void {
    this.businessData =this.data.businessDocumentData
    this.getBusinessServiceWithLinkCategory(this.businessData.businessServiceId , this.data.businessInfo);
    this.formServiceDocument = this.formBuilder.group({
      name: [ '', Validators.required],
      link: ['', Validators.required],
      documentType: ['', Validators.required],
      expireDate: [''],
      group: ['', Validators.required],
      documentCategory: ['', Validators.required],
      businessServiceId: ['', Validators.required],
      linkToServiceCategortIdList: ['']
    });

    if (this.businessData) {
      this.GetBusinessServiceListByDocumentId(this.data.businessInfo ,this.businessData.businessServiceId, this.businessData.id);
    } else {
      this.setDocumentCreateData(this.businessData);
    }
  }

  // getter - access to form fields
  get f() {
    return this.formServiceDocument.controls;
  }

  // Upload selected image
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;

    if (this.businessData.link === this.formServiceDocument.value.link) {
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }

  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formServiceDocument.value.name
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const folderName = `Business_Services/${this.businessData.businessServiceId}`
    const fileLocation = `Trade/${this.businessData.businessInfoId}/Documents/${folderName}/`+ fileName +'.'+ fileExtension;

    const params = {
      Bucket: environment.config.location,
      Key: fileLocation,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formServiceDocument.patchValue({
        link: response.Key,
      });
      this.saveForm();
      return true;
    });
  }

  saveForm() {
    if (this.businessData) {
      this.updateBusinessServiceDocument(this.formServiceDocument.value);
    } else {
      this.createBusinessServiceDocument(this.formServiceDocument.value);
    }
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();
    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.formServiceDocument.patchValue({
      link: this.fileName,
    });
    const fileExtension = this.fileData.name.split('.').pop();
    const docType = this.getFileExtention(fileExtension); // set file extention
    this.formServiceDocument.controls['documentType'].setValue(docType);
    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  // get business service document by service
  private getBusinessServiceWithLinkCategory(businessServiceId: any , businessId: any) {
   this.isLoading = true;
   this.tradieService
     .GetBusinessServiceWithLinkCategory(businessServiceId , businessId).subscribe({
       next: (response: any) => {
         if (response) {
           this.linkCategoryObj = response.data;
         } else {
         }
         this.isLoading = false;
       },
       error: (error: any) => {
         this.alertService.error('Operation failed!');
       },
     });
 }

   // get business service document by category
   private GetBusinessServiceListByDocumentId(serviceCategoryId: string , businessId: string,documentId: string) {
    this.isLoading = true;
    this.tradieService
      .GetBusinessServiceListByDocumentId(serviceCategoryId , businessId, documentId).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.linkCategoryObject = response.data;
          this.setDocumentUpdateData(this.businessData);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Create business service document
  private createBusinessServiceDocument(data: BusinessServiceDocumentModel) {
    this.isLoading = true;
    this.serviceDocumentData = data;
    this.serviceDocumentData.expireDate = this.convertDate(data.expireDate)
    this.tradieService
      .addBusinessServiceDocument(this.serviceDocumentData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit business service document
  private updateBusinessServiceDocument(data: BusinessDocumentUpdateModel) {
    this.isLoading = true;
    data.businessInfoId = this.data.businessInfo
    data.linkToServiceCategortIdList = this.linkCategoryObject
    this.serviceDocumentData = data;
    this.serviceDocumentData.expireDate = this.convertDate(data.expireDate)
    this.tradieService
      .updateBusinessServiceDocument(this.serviceDocumentData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Is Update
  setDocumentUpdateData(serviceDocument: any) {
    let docType = this.getFileExtention(serviceDocument.link.split('.').pop())
    this.formServiceDocument.addControl('id', new FormControl(serviceDocument.id) );
    this.formServiceDocument.controls['name'].setValue(serviceDocument.name);
    this.formServiceDocument.controls['link'].setValue(serviceDocument.link);
    this.formServiceDocument.controls['documentType'].setValue(docType);
    const expireDate = serviceDocument.expireDate ? serviceDocument.expireDate : null
    this.formServiceDocument.controls['expireDate'].setValue(expireDate);
    this.formServiceDocument.controls['group'].setValue(this.businessData.documentGroup);
    this.formServiceDocument.controls['documentCategory'].setValue(serviceDocument.documentCategory);
    this.formServiceDocument.controls['businessServiceId'].setValue(serviceDocument.businessServiceId);
    this.formServiceDocument.controls['linkToServiceCategortIdList'].setValue(this.linkCategoryObject);
  }

  setDocumentCreateData(serviceDocument: CustomDocumentModel) {
    this.formServiceDocument.controls['group'].setValue(this.businessData.documentGroup);
    this.formServiceDocument.controls['documentCategory'].setValue(serviceDocument.documentCategory);
    this.formServiceDocument.controls['businessServiceId'].setValue(serviceDocument.businessServiceId);
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  // --- SET FILE / DOCUMENT TYPE --- //
  getFileExtention(fileExtension: any) {
    let extention;
    switch ( fileExtension.toLowerCase() ) {
      case 'pdf': 
        extention = this.documentType.PDF 
        break;
      case 'doc': 
        extention = this.documentType.DOC 
        break;
      case 'docx': 
        extention = this.documentType.DOC 
        break;
      case 'png': 
        extention = this.documentType.PNG 
        break;
      case 'jpeg': 
        extention = this.documentType.JPEG 
        break;
      case 'jpg': 
        extention = this.documentType.JPEG 
        break;
    }
    return extention
  }

  convertDate(date: string) {
    return this.datePipe.transform( date,'yyyy-MM-dd' ); 
  }

  clearDate() {
    this.formServiceDocument.controls['expireDate'].setValue(null);
  }
}
