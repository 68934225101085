import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentGroup, DocumentStatus, HealthSafetyDocumentType } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { HealthAndSafetyFileUploadPopupComponent } from '../health-and-safety-file-upload-popup/health-and-safety-file-upload-popup.component';
import { HealthAndSafetyFilesCustomDocumentModel, HealthAndSafetyFilesModel } from '../health-and-safety-files.model';
import { HealthAndSafetyFilesService } from '../health-and-safety-files.service';

@Component({
  selector: 'app-health-and-safety-file-list-widget',
  templateUrl: './health-and-safety-file-list-widget.component.html',
  styleUrls: ['./health-and-safety-file-list-widget.component.scss']
})
export class HealthAndSafetyFileListWidgetComponent implements OnInit {

  @Input() businessInfoId!: string
  @Input() hideStatus: boolean = false
  @Input() isOptional: boolean = false
  @Input() hasExpiry: boolean = true
  @Input() healthAndSafetyDocuments!: HealthAndSafetyFilesModel[]
  @Input() currentDocumentCategory!: number
  @Input() tradeId!: string
  @Input() isEditable: boolean = true
  @Output() removeDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentEvent = new EventEmitter<any>();
  @Output() updateDocumentStatusEvent = new EventEmitter<any>();

  healthAndSafetyDocument!: HealthAndSafetyFilesModel
  healthAndSafetyDocumentData = new HealthAndSafetyFilesCustomDocumentModel()
  documentGroup = DocumentGroup
  documentStatus = DocumentStatus
  healthSafetyDocumentType = HealthSafetyDocumentType
  isLoading = false

  constructor(
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private healthAndSafetyDocumentServiceNew: HealthAndSafetyFilesService
  ) { }

  ngOnInit(): void {
  }

  uploadBusinessDocument() {
    this.setCreateDocumentData(null) 
    const dialogRef = this.dialog.open( HealthAndSafetyFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: false, documentData: this.healthAndSafetyDocumentData, userId: this.tradeId },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        // this.businessDocument = data
        this.healthAndSafetyDocuments.unshift(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  updateBusinessDocument(healthAndSafetyDocument: HealthAndSafetyFilesModel) {
    this.setCreateDocumentData(healthAndSafetyDocument)
    const dialogRef = this.dialog.open( HealthAndSafetyFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {isUpdate: true, documentData: this.healthAndSafetyDocumentData, userId: this.tradeId},
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        // this.businessDocument = data
        this.updateListItem(data)
        this.updateDocumentEvent.emit(data)
      }
    });
  }

  removeDocumentConfirm(healthAndSafetyDocument: HealthAndSafetyFilesModel) {
    this.setCreateDocumentData(healthAndSafetyDocument)
    const dialogRef = this.dialog.open( SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe( success => {
      if (success) {
        const isArchived = true
        this.deleteBusinessDocument(healthAndSafetyDocument, isArchived)
      }
    });
  }

  private deleteBusinessDocument(healthAndSafetyDocument: HealthAndSafetyFilesModel, isArchived: boolean) {
    this.isLoading = true;
    this.healthAndSafetyDocumentServiceNew.deleteHealthSafetyCompliance(healthAndSafetyDocument.id, this.businessInfoId, isArchived, this.tradeId).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.alertService.success('Document deleted successfully!');
          this.removeDocumentEvent.emit(healthAndSafetyDocument.id)
          // this.businessDocument = null!
          this.removeFromList(healthAndSafetyDocument.id)
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setCreateDocumentData(healthAndSafetyDocument: any) {
    this.healthAndSafetyDocumentData = new HealthAndSafetyFilesCustomDocumentModel()
    if (healthAndSafetyDocument) {
      this.healthAndSafetyDocumentData.document = healthAndSafetyDocument
      this.healthAndSafetyDocumentData.documentName = healthAndSafetyDocument.name
    }
    this.healthAndSafetyDocumentData.documentCategory = this.currentDocumentCategory
    this.healthAndSafetyDocumentData.documentGroup = this.isOptional ? this.documentGroup.Additional : this.documentGroup.Mandatory
    this.healthAndSafetyDocumentData.hasExpiry = this.hasExpiry
    this.healthAndSafetyDocumentData.businessInfoId = this.businessInfoId
    this.healthAndSafetyDocumentData.currentUserId = this.tradeId
    this.healthAndSafetyDocumentData.fileLocation = `User/${this.tradeId}/Documents/`
  }

  updateListItem(data: HealthAndSafetyFilesModel) {
    const index = this.healthAndSafetyDocuments.findIndex(x => x.id === data.id);
    this.healthAndSafetyDocuments[index] = data
  }

  removeFromList(id: string) {
    const index = this.healthAndSafetyDocuments.findIndex(x => x.id === id);
    if (index > -1) {
      this.healthAndSafetyDocuments.splice(index, 1);
    }
  }

}
