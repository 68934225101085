<main class="welcome-wr container-fluid">
  <div class="row">
    <div class="intro-wr col-4 bg-signup vh-100 mat-elevation-z8 d-flex align-items-center justify-content-center">
      <div class="text-white px-5">
        <img class="mb-5 brand-husky" src="assets/icons/logo/logo.png"  alt="Brand - Huski Trades & Services">
        <h3 class="intro font-regular mb-4">
          <span class="d-block">Join the best Trades &</span>
          <span class="d-block">Services community in</span>
          <span class="d-block">Australia.</span>
      </h3>
      <p class="sub-intro font-regular">
          <span class="d-block">Huski’s network is continually expanding into sectors including Commercial, </span>
          <span class="d-block"> Insurance, Strata, Real Estate and </span>
          <span class="d-block">Government.</span>
      </p>
        <!-- <a href="https://www.google.com/" class="font-light ms-3 text-white" target="_blank">Visit website</a> -->
      </div>
      <p class="copyright-text font-light mt-auto text-center">© Huski 2022</p>
    </div>
    <div class="col-8 d-flex align-items-start scroll-wr">
      <section class="p-5 d-flex flex-column">
        <p class="mb-3 page-title">
          <span class="me-3 pera-1">Welcome,</span>
          <span>{{ profileData ? profileData.firstName : '...'}}</span>
        </p>

        <section class="content">
          <div class="mb-3">
            <p>Huski Compliance is a cutting-edge contractor compliance software. Backed by a skilled team of officers, we
              monitor, manage, and maintain your documentation. Our compliance technology ensures your business remains
              within the rules and regulations of the States and Territories you operate in, so you have more time for the
              things that matter.</p>
            <p class="pera-2">Before you get started, make sure you have the <span class="pera-1">below
                on hand</span> so we can get you approved and ready to work
              as soon as possible:</p>
          </div>
          <div>
            <p class="m-0">CHECKLIST:</p>
          </div>
          <div class="col-12 row mb-3">
            <div>
              <ul class="mb-0">
                <li>Proof of Identity (Driver’s license / Passport / Government Issued Photo ID)</li>
              </ul>
            </div>
            <div class="col-6">
              <ul>
                <li>ABN</li>
                <li>Public Liability</li>
                <li>Workers Compensation</li>
                <li>Professional Indemnity</li>
                <li>Trade Licenses & Qualifications</li>
              </ul>
            </div>
            <div class="col-6">
              <ul>
                <li>Construction Industry White Card</li>
                <li>SWMS</li>
                <li>High Risk SWMS</li>
                <li>Company Details</li>
                <li>Call Out Fees & Hourly Rates</li>
              </ul>
            </div>
          </div>
          <div>
            <p> <span class="pera-2">I will complete the onboarding later, </span><a class="link dark underlined font-semi-bold pointer" (click)="logout()"> Logout</a></p>
          </div>
          <div>
            <p class="mb-2 font-semi-bold">Note:</p>
            <p class="pera-2">You should only be completing this onboarding if you are the owner of your
              business, an admin, or a manager
              of a team. Once you complete this onboarding, our team will assess your documents and get back to you.
              Please make sure you fill-in all details correctly and upload any documents that are relevant to your
              business, so we can approve you the first time.</p>
          </div>
        </section>

        <div class="mt-3">
          <button class="btn primary large" mat-flat-button (click)="startOnboarding()">Start Onboarding Now
            <svg class="svg-viewbox" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path style="fill:#fafafd;"
                d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z"
                data-name="Right" />
            </svg>
          </button>
        </div>
      </section>
    </div>
  </div>
</main>