import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentCategoryType, DocumentGroup } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { SwmsFilesService } from '../swms-files.service';
import * as AWS from 'aws-sdk';
import { SWMSFilesCreateModel, SWMSFilesCustomDocumentModel, SWMSFilesModel, SWMSFilesUpdateModel } from '../swms-files.model';

@Component({
  selector: 'app-swms-file-upload-popup',
  templateUrl: './swms-file-upload-popup.component.html',
  styleUrls: ['./swms-file-upload-popup.component.scss'],
  providers: [DatePipe]
})
export class SwmsFileUploadPopupComponent implements OnInit {

  formNewdoc!: FormGroup;
  submitted = false;
  isLoading = false;
  hasExpiry = false;
  isOtherDoc = false;

  formData!: FormData;
  reader!: FileReader;
  fileData!: File;
  fileName!: string;
  currentURL!: string;
  imgURL: any;
  public imagePath!: string;
  minExpireData = new Date();

  sWMSDocumentUpdateData!: SWMSFilesUpdateModel;
  sWMSDocumentCreateData!: SWMSFilesCreateModel;

  currentDocumentName = ''
  documentGroup = DocumentGroup;
  documentType = DocumentCategoryType;
  isChecked = true
  documentStatus = 1

  constructor(
    private sWMSDocumentServiceNew: SwmsFilesService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: {isUpdate: boolean, documentData: SWMSFilesCustomDocumentModel, userId: string, questionId: string},
    public dialogRef: MatDialogRef<SwmsFileUploadPopupComponent>
  ) { }

  ngOnInit(): void {
    this.formNewdoc = this.formBuilder.group({
      businessInfoId: [ this.data.documentData.businessInfoId],
      userId: [this.data.userId],
      questionId: [this.data.questionId],
      isChecked: [this.isChecked],
      documentStatus: [this.documentStatus],
      documentName: [ '', Validators.required],
      documentType: [''],
      link: ['', Validators.required],
      expireDate: [''],
    });

    this.hasExpiry = this.data.documentData.hasExpiry

    if (this.data.isUpdate) this.setDocumentUpdateFormData(this.data.documentData.document);
    else this.setDocumentCreateFormData(this.data.documentData)
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.formNewdoc.controls;
  }

  // Upload selected image
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    // If invalid
    if (this.formNewdoc.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.data.documentData.document?.link === this.formNewdoc.value.link) { 
      this.saveForm(); // Updating & not updating the file
    } else {
      this.uploadDocToS3(); // updating the file
    }
  }
  
  setDocumentCreateFormData(document: SWMSFilesCustomDocumentModel) {
    this.formNewdoc.controls['businessInfoId'].setValue(document.businessInfoId);
    this.formNewdoc.controls['questionId'].setValue(this.data.questionId);
    this.formNewdoc.controls['isChecked'].setValue(this.isChecked);
    this.formNewdoc.controls['documentStatus'].setValue(this.documentStatus);
  }

  setDocumentUpdateFormData(document: SWMSFilesModel) {
    this.formNewdoc.addControl('id', new FormControl(document.swmsHeaderId) );
    this.formNewdoc.addControl('swmsDetailsId', new FormControl(document.id) );
    this.formNewdoc.controls['userId'].setValue(this.data.userId);
    this.formNewdoc.controls['businessInfoId'].setValue(this.data.documentData.businessInfoId);
    this.formNewdoc.controls['questionId'].setValue(this.data.questionId);
    this.formNewdoc.controls['isChecked'].setValue(this.isChecked);
    this.formNewdoc.controls['link'].setValue(this.data.documentData?.document.link);
    this.formNewdoc.controls['documentStatus'].setValue(this.documentStatus);
    this.formNewdoc.controls['documentName'].setValue(document?.documentName);
    const expDate = document?.expireDate ? new Date(document?.expireDate) : null
    this.formNewdoc.controls['expireDate'].setValue(expDate);
  }
  
  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    const fileName = this.getFileNameByCategory(
      this.formNewdoc.value.documentName
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });

    const fileLocation = `Trade/${this.data.documentData.businessInfoId}/Documents/SWMS/`+ fileName+'.'+ fileExtension

    const params = {
      Bucket: environment.config.location,
      Key: fileLocation,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.formNewdoc.patchValue({
        link: response.Key,
      });

      this.saveForm();
      return true;
    });
  }

  saveForm() {
    if (this.data.documentData?.currentUserId) {
      if (this.data.isUpdate) {
        this.updateBusinessDocument(this.formNewdoc.getRawValue());
      } else {
        this.createBusinessDocument(this.formNewdoc.getRawValue());
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.formNewdoc.patchValue({
      link: this.fileName,
    });

    // set file extention / doc type
    const currrentDocType  = this.getDocumentType(this.fileData.name)
    this.formNewdoc.controls['documentType'].setValue(currrentDocType);

    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };
  }

  // Create business document
  private createBusinessDocument(data: SWMSFilesCreateModel) {
    this.sWMSDocumentCreateData = data;
    this.sWMSDocumentCreateData.expireDate = this.convertDate(data.expireDate)
    this.isLoading = true;
    this.sWMSDocumentServiceNew
      .createSWMSDocument(this.sWMSDocumentCreateData)
      .subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document created successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Edit business document
  private updateBusinessDocument(data: SWMSFilesUpdateModel) {
    this.sWMSDocumentUpdateData = data;
    this.sWMSDocumentUpdateData.expireDate = this.convertDate(data.expireDate)
    this.isLoading = true;
    this.sWMSDocumentServiceNew
      .updateSWMSDocument(this.sWMSDocumentUpdateData).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.alertService.success('Document updated successfully');
            this.dialogRef.close(response.data);
          } else {
            this.alertService.error(response.error.message);
          }
          this.isLoading = false;
        },
        error: (error: any) => {
          this.alertService.error('Operation failed!');
        },
      });
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : 'SWMS-Document_'+timePrefix;
  }

  // Convert Date
  convertDate(date: string) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null
  }

  // Get documentType form name
  getDocumentType(link: string) {
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  clearDate() {
    this.formNewdoc.controls['expireDate'].setValue(null);
  }
}
