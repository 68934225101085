import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HealthAndSafetyFilesService {

  private endpoint = environment.endpointURL + '/api/HelthSaftyDocument';

  constructor(private http: HttpClient) { }

  public createHealthSafetyCompliance(data: any): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.post<any>(url, data);
  }

  public updateHealthSafetyCompliance(data: any): Observable<any> {
    const url = `${this.endpoint}`;
    return this.http.put<any>(url, data);
  }

  public getHealthSafetyCompliance(businessInfoId: string): Observable<any> {
    const url = `${this.endpoint}/GetByBusinessInfoId?businessInfoId=${businessInfoId}`;
    return this.http.get<any>(url);
  }

  public deleteHealthSafetyCompliance(id: string, businessInfoId: string, isArchived: boolean, userId : string ): Observable<any> {
    const url = `${this.endpoint}?id=${id}&businessInfoId=${businessInfoId}&isArchived=${isArchived}&UserId=${userId}`;
    return this.http.delete<any>(url);
  }

  public deleteAllHealthSafetyCompliance(businessInfoId: string, documentType: number): Observable<any> {
    const url = `${this.endpoint}/DeleteAll?businessInfoId=${businessInfoId}&documentType=${documentType}`;
    return this.http.delete<any>(url);
  }

  public changeHealthSafetyStatus(data: any): Observable<any> {
    const url = `${this.endpoint}/ManageHealthSafety`;
    return this.http.post<any>(url, data);
  }
}
