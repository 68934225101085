import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DocumentCategoryType } from 'src/app/core/enums/document.enum';
import { PaymentPeriodType } from 'src/app/core/enums/main.enum';
import { PaidNowService } from 'src/app/modules/paid-now/shared/services/paid-now.service';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DatePipe } from '@angular/common';
import * as AWS from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { log } from 'console';

@Component({
  selector: 'app-shared-update-paid-now-invoice',
  templateUrl: './shared-update-paid-now-invoice.component.html',
  styleUrls: ['./shared-update-paid-now-invoice.component.scss'],
  providers: [DatePipe]
})
export class SharedUpdatePaidNowInvoiceComponent implements OnInit {

  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  isLinear = false;
  isAcceptTermsConditions = false;

  paymentPeriod = PaymentPeriodType;
  paymentPeriodList : any[] = [ 
    { value: this.paymentPeriod.Daily, viewValue: 'Today'},
    { value: this.paymentPeriod.Weekly, viewValue: 'Week'},
    { value: this.paymentPeriod.Monthly, viewValue: 'Month'},
  ]

  reader!: FileReader;
  fileName!: string;
  fileData!: File;
  docType: any;
  documentType = DocumentCategoryType;
  imgURL: any;
  public imagePath!: string;
  formData!: FormData;
  fileName_invoice!: string;

  // @ViewChild('stepper') stepper!: MatStepper;
  stepNo = 1
  userId!: string;
  nextDate!: Date;
  totalPayableAmount!: any
  

  constructor(
    private formBuilder: FormBuilder,
    private paidNowService: PaidNowService,
    public dialogRef: MatDialogRef<SharedUpdatePaidNowInvoiceComponent>,
    private alertService: AlertService,
    private authService: AuthService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: {isAdd: boolean, tradeProfileData: any},
  ) { 
    this.calculateNextDate();
  }

  ngOnInit(): void {
    this.userId = this.authService.currentUserValue.userId;

    this.firstFormGroup = this.formBuilder.group({
      jobReferenceNo: ['', Validators.required],
      invoiceAmount: ['', [Validators.required, Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$')]],
      paymentPeriod: ['', Validators.required],
      link: ['', Validators.required],
      description: ['', Validators.required],
    });
  
    this.secondFormGroup = this.formBuilder.group({
      supervisorName: ['', Validators.required],
      supervisorEmail: ['', [Validators.required, Validators.pattern('[A-Z0-9a-z_.%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}')]],
      supervisorMobile: ['', [Validators.required, Validators.pattern('[0-9]{9}')]],
    });
  }

  

  get f() { return this.firstFormGroup.controls; }
  get f2() { return this.secondFormGroup.controls; }

  validateAndMoveToNext(stepper: MatStepper, form: FormGroup): void {
    
    

    if (form.valid) {
      stepper.next();
      this.stepNo = stepper.selectedIndex + 1;
    } else {
      // Handle validation error
      console.log('Form is not valid');
    }
  }

  // Browse file/document and preview.
  fileProgress(event: any) {
    this.reader = new FileReader();

    this.fileName = '';
    this.fileData = event.target.files[0];
    this.fileName = event.target.files[0].name;

    this.firstFormGroup.patchValue({
      link: this.fileName,
    });

    const fileExtension = this.fileData.name.split('.').pop();

    this.docType = this.getDocumentType(fileExtension); // set file extention
    // this.formGroup.controls['documentType'].setValue(this.docType);
    // this.coNoteUpdateModel.DocumentType = this.docType;

    const mimeType = event.target.files[0].type;
    const maxFileSize = 1024 * 1024;


    // For iamge preview
    this.imagePath = event.target.files;
    this.reader.readAsDataURL(event.target.files[0]);
    this.reader.onload = (_event) => {
      this.imgURL = this.reader.result;
    };

  }

  // Get documentType form name
  getDocumentType(link: any) {
    // const extention = link.split('.').pop()
    const currentLink = link.toLowerCase()
    const extention = currentLink.split('.').pop()
    let currentDocumentType
    switch (extention) {
      case 'pdf':
        currentDocumentType = this.documentType.PDF
        break;
      case 'doc':
        currentDocumentType = this.documentType.DOC
        break;
      case 'docx':
        currentDocumentType = this.documentType.DOC
        break;
      case 'png':
        currentDocumentType = this.documentType.PNG
        break;
      case 'jpeg':
        currentDocumentType = this.documentType.JPEG
        break;
      case 'jpg':
        currentDocumentType = this.documentType.JPEG
        break;
    }
    return currentDocumentType
  }

  onChangeTermsAndConditions(event: any){ // Use appropriate model type instead of any
    this.isAcceptTermsConditions = event.checked;
  }

  paymentPeriodInfor(paymentPeriod:any){
    let paymentPeriodLable

    switch (paymentPeriod) {
      case 1:
        paymentPeriodLable = "Today (5% Interest)"
        break;
      case 2:
        paymentPeriodLable = "Week (3% Interest)"
        break;
      case 3:
        paymentPeriodLable = "Month (1% Interest)"
        break;
    }
    return paymentPeriodLable
  }

  submitInvoice(){

    this.uploadDocToS3(); 

    
    
    

  }

  calculateNextDate() {
    const currentDate = new Date();
    // Adding two months to the current date
    currentDate.setMonth(currentDate.getMonth() + 2);
    this.nextDate = currentDate;
  }

  uploadDocToS3() {
    this.formData = new FormData();
    this.formData.append('file', this.fileData);

    this.fileName_invoice = this.getFileNameByCategory(
      this.firstFormGroup.value.jobReferenceNo
    );
    const contentType = this.fileData.type;
    const fileExtension = this.fileData.name.split('.').pop();

    const bucket = new AWS.S3({
      accessKeyId: environment.config.keyId,
      secretAccessKey: environment.config.key,
      region: environment.config.region,
    });
    
    const fileLocation = `Trade/${this.data.tradeProfileData.team.businessInfoId}/Documents/PaidNowInvoice/`+this.fileName_invoice+'.'+ fileExtension;
    
    const params = {
      Bucket: environment.config.location,
      Key: fileLocation,
      Body: this.fileData,
      ACL: 'private',
      ContentType: contentType,
    };

    bucket.upload(params, (error: any, response: any) => {
      if (error) {
        this.alertService.error('Operation failed!');
        return false;
      }

      this.firstFormGroup.patchValue({
        link: response.Location,
      });

      this.saveForm();
      return true;
    });
  }

  saveForm(){
    console.log('fileName 1===', this.fileName);
    console.log('fileName invoice 2===', this.fileName_invoice);

    let data = {
      "description": this.firstFormGroup.value.description,
      "amount": this.firstFormGroup.value.invoiceAmount * 100,
      "payment_period": this.firstFormGroup.value.paymentPeriod,
      "trade_id": this.userId,
      "supervisor_name": this.secondFormGroup.value.supervisorName,
      "supervisor_email": this.secondFormGroup.value.supervisorEmail,
      "supervisor_mobile": this.secondFormGroup.value.supervisorMobile,
      "job_reference_no": this.firstFormGroup.value.jobReferenceNo,
      "supervisor_status_last": "1",
      "status_last": "1",
      "due_date": this.nextDate,
      "created_user": this.data.tradeProfileData.id,
      "updated_user": this.data.tradeProfileData.id,
      "document": {
          "document_name": this.fileName_invoice,
          "document_link": this.firstFormGroup.value.link,
          "document_type": this.docType
      }
    }

    console.log('form data === ', data);

    // this.paidNowService.postInvoiceData(
    //   data
    // );

    this.paidNowService.postInvoiceData(data).subscribe( response => {
      if (response.success) {
        this.alertService.success('Invoice successfully added');
        console.log('success responce == ', response);
        this.dialogRef.close(response);
      } else {
        this.alertService.error(response.error.message);
        console.log('error responce == ', response);
        this.dialogRef.close(response);
      }
      // this.isLoading = false;
    }, error => {
      // this.isLoading = false;
      this.alertService.error('Operation failed');
    });
    
    // this.paidNowService.addInvoice().subscribe((response) => {
    //   if (response.success == true) {

    //     this.alertService.success('Invoice successfully added');

    //     console.log('success responce == ', response);
        
    //     this.dialogRef.close(response);
    //   } else {
    //     this.alertService.error('Operation failed');
        
    //     console.log('error responce == ', response);

    //     this.dialogRef.close(response);
    //   }
    // });
  }

  // Generate File Name
  getFileNameByCategory(name: string) {
    const timePrefix = this.datePipe.transform(new Date(), 'yyyyMMddhhmmss');
    return name ? name.split(' ').join('_').toLocaleLowerCase() + '_' + timePrefix : '';
  }

  calPayable(){
    this.totalPayableAmount = this.firstFormGroup.value.invoiceAmount - (this.firstFormGroup.value.invoiceAmount * 5/100)
    
  }
}
