import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentStatus } from 'src/app/core/enums/document.enum';
import { AlertService } from 'src/app/modules/shared/services/alert/alert.service';
import { UploadService } from 'src/app/modules/shared/services/upload/upload.service';
import { SharedConfirmationPopupComponent } from '../../../popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { NdisFilesService } from '../ndis-files.service';
import { NdisFileUploadPopupComponent } from '../ndis-file-upload-popup/ndis-file-upload-popup.component';

@Component({
  selector: 'app-ndis-file-list-widget',
  templateUrl: './ndis-file-list-widget.component.html',
  styleUrls: ['./ndis-file-list-widget.component.scss']
})
export class NdisFileListWidgetComponent implements OnInit {

  @Input() isEditable!: boolean
  @Input() hideStatus!: boolean
  @Input() hasState!: boolean
  
  @Input() businessInfoId!: string
  @Input() ndisTradeId!: string
  @Input() documentList: any[] = []

  isLoading = false
  documentStatus = DocumentStatus

  constructor(
    private alertService: AlertService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private ndisDocumentService: NdisFilesService
  ) { }

  ngOnInit(): void {
  }

  createDocument() {
    const dialogRef = this.dialog.open( NdisFileUploadPopupComponent, {
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        hasState: this.hasState,
        businessInfoId: this.businessInfoId, 
        ndisTradeId: this.ndisTradeId, 
        documentData: null, 
      },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.documentList.unshift(data)
      }
    });
  }

  updateDocument(document: any) {
    const dialogRef = this.dialog.open( NdisFileUploadPopupComponent, {  
      maxWidth: '640px',
      width: '50%',
      position : {top: '60px'},
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        hasState: this.hasState,
        businessInfoId: this.businessInfoId,
        ndisTradeId: this.ndisTradeId, 
        documentData: document, 
      },
    });

    dialogRef.afterClosed().subscribe( (data) => {
      if (data) {
        this.updateListItem(data)
      }
    });
  }

  updateListItem(data: any) {
    const index = this.documentList.findIndex(x => x.id === data.id);
    this.documentList[index] = data
  }

  removeDocumentConfirm(document: any) {
    const dialogRef = this.dialog.open(SharedConfirmationPopupComponent, {
      maxWidth: '500px',
      width: '90%',
      position: {
        top: '60px',
      },
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: {
        title: 'Please Confirm',
        message: 'Are you sure you want to delete this document?',
        confirmTxt: 'Yes, Delete',
        cancelTxt: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe(success => {
      if (success) {
        const isArchived = true
        this.deleteDocument(document, isArchived)
      }
    });
  }

  private deleteDocument(document: any, isArchived: boolean) {
    this.isLoading = true;
    this.ndisDocumentService.deleteDocument(document.id, isArchived).subscribe({
      next: (result: any) => {
        if (result.success) {
          this.clearDeletedDocument(document.id)
          this.alertService.success('Document deleted successfully!');
        } else {
          this.alertService.error(result.error.message);
        }
        this.isLoading = false;
      }, error: err => {
        this.alertService.error('Operation failed!');
        this.isLoading = false;
      }
    });
  }

  clearDeletedDocument(deletedDocId: any) {
    this.documentList = this.documentList.filter( e => e.id != deletedDocId)
  }

  // Download Document
  downloadDocument(key: string, name: string) {
    const documentUrl = this.uploadService.getUrlByS3KEY(key);
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', documentUrl);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
