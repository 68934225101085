<div class="main-container d-flex">
  <aside class="sidebar d-flex flex-column gradient-bg vert" [class.open]="isOpen">

    <section class="top-sidebar w-100">

      <a class="logo-wr d-flex align-items-center justify-content-start px-3">
        <img class="logo" src="/assets/icons/logo/logo_w.svg" alt="Husky">
        <h4 class="logo-text mb-0 text-white font-bold" mat-line>HUSKI</h4>
      </a>

      <div class="info-wr d-flex flex-column justify-content-center p-3">
        <p class="title m-0">{{tradeData?.team?.businessInfo?.businessName}}</p>
        <p class="sub-title text-md m-0">{{currentPost}}</p>

        <svg class="curved-bg" xmlns="http://www.w3.org/2000/svg" width="29.303" height="120.231" viewBox="0 0 29.303 120.231">
          <g id="Path_26" data-name="Path 26" transform="translate(0.007 28.156)" fill="#f4f6f9">
            <path d="M 26.57426071166992 65.56924438476563 C 23.32943534851074 59.90282821655273 18.86396598815918 55.7744255065918 14.75082778930664 51.97178268432617 C 11.41340827941895 48.88628005981445 8.261048316955566 45.97190093994141 5.917068004608154 42.49990081787109 C 3.348927974700928 38.69586181640625 2.104167938232422 34.72292327880859 1.999768018722534 29.99682235717773 C 1.890327930450439 25.0432014465332 3.081687927246094 20.67060089111328 5.749047756195068 16.2359619140625 C 8.17814826965332 12.19748115539551 11.4896879196167 8.652381896972656 14.99564838409424 4.899141311645508 C 19.25162124633789 0.3429843187332153 23.58959579467773 -4.300694942474365 26.62277412414551 -10.07779502868652 C 26.57254028320313 10.17433738708496 26.51650810241699 43.63807678222656 26.57426071166992 65.56924438476563 Z" stroke="none"/>
            <path d="M 24.60669326782227 -3.195652008056641 C 22.05557823181152 0.2712898254394531 19.18924331665039 3.339622497558594 16.45718765258789 6.264369964599609 C 9.652542114257813 13.54895401000977 3.775854110717773 19.84014129638672 3.999271392822266 29.95266151428223 C 4.094833374023438 34.27780914306641 5.230937957763672 37.9091796875 7.574687957763672 41.38082885742188 C 9.788957595825195 44.66070175170898 12.8586254119873 47.49864196777344 16.1085205078125 50.50320434570313 C 18.92221832275391 53.10448455810547 21.8950366973877 55.85287857055664 24.56094741821289 59.12709426879883 C 24.53096199035645 40.02561950683594 24.56660461425781 15.02978515625 24.60669326782227 -3.195652008056641 M 28.68397331237793 -28.15643692016602 C 28.68593978881836 -28.15626907348633 28.68702125549316 -27.78539657592773 28.68702125549316 -27.00171279907227 C 28.68797492980957 -26.57828140258789 28.68207550048828 -26.1595344543457 28.66957473754883 -25.74649429321289 C 28.67596626281738 -27.30353164672852 28.68099021911621 -28.15668869018555 28.68397331237793 -28.15643692016602 Z M 28.66957473754883 -25.74649429321289 C 28.61498260498047 -12.44791030883789 28.4604320526123 52.29667663574219 28.62293434143066 77.04898071289063 C 24.41510772705078 53.62918472290039 0.4854869842529297 52.00403213500977 0.0002498626708984375 30.041015625 C -0.5259513854980469 6.223857879638672 27.86277770996094 0.9091567993164063 28.66957473754883 -25.74649429321289 Z M 28.62293434143066 77.04898071289063 C 29.04886436462402 79.41960906982422 29.27250099182129 82.01230621337891 29.24952125549316 84.87328338623047 C 29.33905982971191 90.29036712646484 29.28791618347168 92.07499694824219 29.18876647949219 92.07499694824219 C 29.01108169555664 92.07499694824219 28.67930221557617 86.33880615234375 28.72733306884766 85.49599456787109 C 28.6832275390625 83.96109771728516 28.64900016784668 81.01950073242188 28.62293434143066 77.04898071289063 Z" stroke="none" fill="#f4f6f9"/>
          </g>
        </svg>

        <div class="toggle-nav mat-elevation-z5 d-flex align-items-center justify-content-center"
            (click)="isOpen = !isOpen" >
          <svg class="btn" xmlns="http://www.w3.org/2000/svg" width="15.5" height="13.5" viewBox="0 0 15.5 13.5">
            <g id="menu" transform="translate(-285.04 -170.25)">
              <line id="Line_301" data-name="Line 301" x2="14" transform="translate(285.79 171)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
              <line id="Line_302" data-name="Line 302" x2="14" transform="translate(285.79 177)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
              <line id="Line_303" data-name="Line 303" x2="14" transform="translate(285.79 183)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
            </g>
          </svg>
        </div>
      </div>

    </section>

    <section class="middle-sidebar w-100 px-3">
      <mat-divider class="divider"></mat-divider>
      <p class="text-white mt-4 text-md text-uppercase">Menu</p>
      <div class="sidebar-list d-flex flex-column align-items-start justify-content-start p-0">

        <a [routerLink]="['/profile']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="16.492" height="16.492" viewBox="0 0 16.492 16.492">
              <g id="user_2_" data-name="user (2)" transform="translate(0)">
              <g id="Group_568" data-name="Group 568" transform="translate(0 10.602)">
                  <g id="Group_567" data-name="Group 567" transform="translate(0)">
                  <path id="Path_58" data-name="Path 58" d="M13.3,308.065l-2.507-.836a.587.587,0,0,0-.186-.031H5.89a.587.587,0,0,0-.186.031l-2.507.836A4.668,4.668,0,0,0,0,312.5a.589.589,0,0,0,.589.589H15.9a.589.589,0,0,0,.589-.589A4.668,4.668,0,0,0,13.3,308.065Z" transform="translate(0 -307.199)" fill="#fff"/>
                  </g>
              </g>
              <g id="Group_570" data-name="Group 570" transform="translate(4.712)">
                  <g id="Group_569" data-name="Group 569">
                  <path id="Path_59" data-name="Path 59" d="M140.067,0a3.534,3.534,0,0,0-3.534,3.534V5.89a3.534,3.534,0,1,0,7.068,0V3.534A3.534,3.534,0,0,0,140.067,0Z" transform="translate(-136.533 0)" fill="#fff"/>
                  </g>
              </g>
              </g>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">My Profile</span>
        </a>
        
        <!-- <a [routerLink]="['/dashboard']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="15.5" height="15.5" viewBox="0 0 15.5 15.5">
              <g id="Group_618" data-name="Group 618" transform="translate(-53 -319)">
                <g id="dashboard_3_" data-name="dashboard (3)" transform="translate(53 319)">
                    <path id="Path_7" data-name="Path 7" d="M5.974,5.167H1.13A1.131,1.131,0,0,1,0,4.036V1.13A1.131,1.131,0,0,1,1.13,0H5.974A1.131,1.131,0,0,1,7.1,1.13V4.036A1.131,1.131,0,0,1,5.974,5.167ZM1.13.969a.162.162,0,0,0-.161.161V4.036A.162.162,0,0,0,1.13,4.2H5.974a.162.162,0,0,0,.162-.162V1.13A.162.162,0,0,0,5.974.969Zm0,0" fill="#fff"/>
                    <path id="Path_8" data-name="Path 8" d="M5.974,222.374H1.13A1.131,1.131,0,0,1,0,221.244v-6.781a1.131,1.131,0,0,1,1.13-1.13H5.974a1.131,1.131,0,0,1,1.13,1.13v6.781A1.131,1.131,0,0,1,5.974,222.374ZM1.13,214.3a.162.162,0,0,0-.161.162v6.781a.162.162,0,0,0,.161.161H5.974a.162.162,0,0,0,.162-.161v-6.781a.162.162,0,0,0-.162-.162Zm0,0" transform="translate(0 -206.874)" fill="#fff"/>
                    <path id="Path_9" data-name="Path 9" d="M283.306,346.5h-4.844a1.131,1.131,0,0,1-1.13-1.13v-2.906a1.131,1.131,0,0,1,1.13-1.13h4.844a1.131,1.131,0,0,1,1.13,1.13v2.906A1.131,1.131,0,0,1,283.306,346.5Zm-4.844-4.2a.162.162,0,0,0-.162.162v2.906a.162.162,0,0,0,.162.161h4.844a.162.162,0,0,0,.161-.161v-2.906a.162.162,0,0,0-.161-.162Zm0,0" transform="translate(-268.936 -330.999)" fill="#fff"/>
                    <path id="Path_10" data-name="Path 10" d="M283.306,9.042h-4.844a1.131,1.131,0,0,1-1.13-1.13V1.13A1.131,1.131,0,0,1,278.462,0h4.844a1.131,1.131,0,0,1,1.13,1.13V7.911A1.131,1.131,0,0,1,283.306,9.042ZM278.462.969a.162.162,0,0,0-.162.161V7.911a.162.162,0,0,0,.162.162h4.844a.162.162,0,0,0,.161-.162V1.13a.162.162,0,0,0-.161-.161Zm0,0" transform="translate(-268.936)" fill="#fff"/>
                </g>
                <rect id="Rectangle_8" data-name="Rectangle 8" width="6" height="9" rx="1" transform="translate(62 319)" fill="#fff"/>
                <rect id="Rectangle_9" data-name="Rectangle 9" width="6" height="4" rx="1" transform="translate(62 321)" fill="#fff"/>
                <rect id="Rectangle_10" data-name="Rectangle 10" width="7" height="5" rx="1" transform="translate(53 319)" fill="#fff"/>
              </g>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">Dashboard</span> 
        </a> -->

        <a *ngIf="isTestEnv" [routerLink]="['/available-jobs/available-job']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="22.137" height="9.58" viewBox="0 0 22.137 9.58">
            <g id="stream" transform="translate(209 -46.938)">
              <g id="Group_3666" data-name="Group 3666" transform="translate(-198.335 46.938)">
                <g id="Group_3665" data-name="Group 3665" transform="translate(0 0)">
                  <path id="Path_2528" data-name="Path 2528" d="M11.344,51.42,6.99,47.066a.434.434,0,0,0-.308-.128H.436a.435.435,0,0,0-.308.743L3.867,51.42a.436.436,0,0,1,0,.616L.128,55.774a.435.435,0,0,0,.308.743H6.682a.434.434,0,0,0,.308-.128l4.354-4.354A.435.435,0,0,0,11.344,51.42Z" transform="translate(0 -46.938)" fill="#fff"/>
                </g>
              </g>
              <g id="Group_3673" data-name="Group 3673" transform="translate(-209 46.938)">
                <g id="Group_3665-2" data-name="Group 3665" transform="translate(0 0)">
                  <path id="Path_2528-2" data-name="Path 2528" d="M11.344,51.42,6.99,47.066a.434.434,0,0,0-.308-.128H.436a.435.435,0,0,0-.308.743L3.867,51.42a.436.436,0,0,1,0,.616L.128,55.774a.435.435,0,0,0,.308.743H6.682a.434.434,0,0,0,.308-.128l4.354-4.354A.435.435,0,0,0,11.344,51.42Z" transform="translate(0 -46.938)" fill="#fff"/>
                </g>
              </g>
            </g>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">Available Jobs</span>
        </a>

        <a *ngIf="isTestEnv" [routerLink]="['/my-jobs']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="16.762" height="13.41" viewBox="0 0 16.762 13.41">
              <path id="bxs-credit-card" d="M17.086,4H3.676A1.678,1.678,0,0,0,2,5.676V7.352H18.762V5.676A1.678,1.678,0,0,0,17.086,4ZM2,15.733A1.678,1.678,0,0,0,3.676,17.41h13.41a1.678,1.678,0,0,0,1.676-1.676V10.7H2Zm2.514-2.514H9.543V14.9H4.514Z" transform="translate(-2 -4)" fill="#fff"/>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">My Jobs</span>
        </a>
        
        <a [routerLink]="['/paid-now']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="29" viewBox="0 0 13 29">
            <g id="paidnow" transform="translate(-55 -750)">
              <text id="Dashboard_Charts_Ban" data-name="Dashboard Charts Ban" transform="translate(55 770)" fill="#fff" font-size="20" font-family="SourceSansPro-SemiboldIt, Source Sans Pro" font-weight="600" font-style="italic"><tspan x="0" y="0">P</tspan></text>
              <text id="Dashboard_Charts_Ban-2" data-name="Dashboard Charts Ban" transform="translate(56 771)" fill="#fff" font-size="20" font-family="SourceSansPro-SemiboldIt, Source Sans Pro" font-weight="600" font-style="italic" opacity="0.318"><tspan x="0" y="0">P</tspan></text>
            </g>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">Paid Now</span>
        </a>

        <!-- <a [routerLink]="['/team']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="16.492" height="16.492" viewBox="0 0 16.492 16.492">
              <g id="user_2_" data-name="user (2)" transform="translate(0)">
              <g id="Group_568" data-name="Group 568" transform="translate(0 10.602)">
                  <g id="Group_567" data-name="Group 567" transform="translate(0)">
                  <path id="Path_58" data-name="Path 58" d="M13.3,308.065l-2.507-.836a.587.587,0,0,0-.186-.031H5.89a.587.587,0,0,0-.186.031l-2.507.836A4.668,4.668,0,0,0,0,312.5a.589.589,0,0,0,.589.589H15.9a.589.589,0,0,0,.589-.589A4.668,4.668,0,0,0,13.3,308.065Z" transform="translate(0 -307.199)" fill="#fff"/>
                  </g>
              </g>
              <g id="Group_570" data-name="Group 570" transform="translate(4.712)">
                  <g id="Group_569" data-name="Group 569">
                  <path id="Path_59" data-name="Path 59" d="M140.067,0a3.534,3.534,0,0,0-3.534,3.534V5.89a3.534,3.534,0,1,0,7.068,0V3.534A3.534,3.534,0,0,0,140.067,0Z" transform="translate(-136.533 0)" fill="#fff"/>
                  </g>
              </g>
              </g>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">Team</span>
        </a> -->

        <!-- <a [routerLink]="['/inbox']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="17.986" height="18.059" viewBox="0 0 17.986 18.059">
              <g id="bxs-chat" transform="translate(-2 -2)">
              <path id="Path_20" data-name="Path 20" d="M3.8,16.791H5.6v3.67l4.587-3.67H14.59a1.8,1.8,0,0,0,1.8-1.8V7.8A1.8,1.8,0,0,0,14.59,6H3.8A1.8,1.8,0,0,0,2,7.8v7.194A1.8,1.8,0,0,0,3.8,16.791Z" transform="translate(0 -0.403)" fill="#fff"/>
              <path id="Path_21" data-name="Path 21" d="M18.59,2H7.8A1.8,1.8,0,0,0,6,3.8H16.791a1.8,1.8,0,0,1,1.8,1.8v7.194a1.8,1.8,0,0,0,1.8-1.8V3.8A1.8,1.8,0,0,0,18.59,2Z" transform="translate(-0.403)" fill="#fff"/>
              </g>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">Inbox</span>
        </a> -->

        <!-- <a [routerLink]="['/sub-contractors']" routerLinkActive="active" class="sidebar-list-item d-flex align-items-center mb-3">
          <svg class="sidebar-icon" xmlns="http://www.w3.org/2000/svg" width="22.137" height="9.58" viewBox="0 0 22.137 9.58">
            <g id="stream" transform="translate(209 -46.938)">
              <g id="Group_3666" data-name="Group 3666" transform="translate(-198.335 46.938)">
                <g id="Group_3665" data-name="Group 3665" transform="translate(0 0)">
                  <path id="Path_2528" data-name="Path 2528" d="M11.344,51.42,6.99,47.066a.434.434,0,0,0-.308-.128H.436a.435.435,0,0,0-.308.743L3.867,51.42a.436.436,0,0,1,0,.616L.128,55.774a.435.435,0,0,0,.308.743H6.682a.434.434,0,0,0,.308-.128l4.354-4.354A.435.435,0,0,0,11.344,51.42Z" transform="translate(0 -46.938)" fill="#fff"/>
                </g>
              </g>
              <g id="Group_3673" data-name="Group 3673" transform="translate(-209 46.938)">
                <g id="Group_3665-2" data-name="Group 3665" transform="translate(0 0)">
                  <path id="Path_2528-2" data-name="Path 2528" d="M11.344,51.42,6.99,47.066a.434.434,0,0,0-.308-.128H.436a.435.435,0,0,0-.308.743L3.867,51.42a.436.436,0,0,1,0,.616L.128,55.774a.435.435,0,0,0,.308.743H6.682a.434.434,0,0,0,.308-.128l4.354-4.354A.435.435,0,0,0,11.344,51.42Z" transform="translate(0 -46.938)" fill="#fff"/>
                </g>
              </g>
            </g>
          </svg>
          <span class="hidden-sidebar ms-3 text-white font-light">Sub Contractors</span>
        </a> -->
      </div>
    </section>
    
    <section class="bottom-sidebar mt-auto w-100 p-3">

      <div [matMenuTriggerFor]="myAccount" 
            class="user-wr d-flex align-items-center p-2 text-white pointer">
        <img 
          class="user-img object-fit rounded-circle me-2" 
          [src]="tradeData?.profileImageUrl ? tradeData?.profileImageUrl : '/assets/icons/common/default_user.svg'" />
        <div class="d-flex flex-column">
          <span class="text-md">My Account</span>
          <span class="text-md font-light">{{tradeData?.firstName}} {{tradeData?.lastName}}</span>
        </div>
        <mat-icon class="ms-auto">arrow_right</mat-icon>
      </div>

      <mat-menu #myAccount="matMenu" xPosition="before">
        <!-- <button mat-menu-item (click)="viewProfile()">
          <span class="d-flex align-items-center">
            <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="18.984" height="19.86" viewBox="0 0 18.984 19.86">
              <g id="user_3_" data-name="user (3)" transform="translate(-11.328)">
                <g id="Group_576" data-name="Group 576" transform="translate(11.328 9.956)">
                  <g id="Group_575" data-name="Group 575" transform="translate(0)">
                    <path id="Path_68" data-name="Path 68" d="M20.82,257.323a9.5,9.5,0,0,0-9.492,9.492.413.413,0,0,0,.413.413H29.9a.413.413,0,0,0,.413-.413A9.5,9.5,0,0,0,20.82,257.323Z" transform="translate(-11.328 -257.323)" fill="#666"/>
                  </g>
                </g>
                <g id="Group_578" data-name="Group 578" transform="translate(16.28 0)">
                  <g id="Group_577" data-name="Group 577" transform="translate(0 0)">
                    <circle id="Ellipse_74" data-name="Ellipse 74" cx="4.54" cy="4.54" r="4.54" fill="#666"/>
                  </g>
                </g>
              </g>
            </svg>
            <span class="me-3">Profile</span>
          </span>
        </button>
        <mat-divider></mat-divider> -->

        <button mat-menu-item (click)="logout()">
          <span class="d-flex align-items-center">
            <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="17.189" height="17.189" viewBox="0 0 17.189 17.189">
              <g id="logout" transform="translate(0 -0.003)">
                <g id="Group_580" data-name="Group 580" transform="translate(0 0.003)">
                  <g id="Group_579" data-name="Group 579">
                    <path id="Path_69" data-name="Path 69" d="M6.446,8.6A2.151,2.151,0,0,1,8.594,6.449h2.865V.719A.716.716,0,0,0,10.743,0H.716A.716.716,0,0,0,0,.719V16.476a.716.716,0,0,0,.716.716H10.743a.716.716,0,0,0,.716-.716v-5.73H8.594A2.151,2.151,0,0,1,6.446,8.6Z" transform="translate(0 -0.003)" fill="#666"/>
                    <path id="Path_70" data-name="Path 70" d="M243.816,153.363l.006-.008c.012-.016.024-.031.035-.048l.014-.024c.007-.012.015-.024.021-.036s.01-.02.015-.031.01-.021.015-.032.009-.024.013-.036.007-.02.01-.03.008-.028.011-.042,0-.016.006-.025.007-.039.009-.059c0,0,0-.007,0-.01a.722.722,0,0,0,0-.145s0-.007,0-.01c0-.02-.005-.039-.009-.059s0-.017-.006-.025-.007-.028-.011-.042-.007-.02-.01-.03-.008-.024-.013-.036-.01-.021-.015-.032-.009-.021-.015-.031-.014-.024-.021-.036l-.014-.024c-.011-.017-.023-.032-.035-.048l-.006-.008a.713.713,0,0,0-.051-.057l-2.862-2.862a.716.716,0,0,0-1.013,1.013l1.642,1.642h-6.149a.716.716,0,0,0,0,1.432h6.149l-1.642,1.642a.716.716,0,1,0,1.013,1.013l2.862-2.862A.7.7,0,0,0,243.816,153.363Z" transform="translate(-226.789 -144.316)" fill="#666"/>
                  </g>
                </g>
              </g>
            </svg>
            <span class="me-3">Logout</span>
          </span>
        </button>
      </mat-menu>
    </section>
  </aside>

  <main class="main-content">
    <router-outlet></router-outlet>
  </main>
</div>
