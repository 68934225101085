<section class="mb-3">
  <mat-checkbox
    class="check-box mb-2"
    [disabled]="hasChackedQuestions || !isEditable"
    [checked]="mainQuestionChecked"
    (change)="onMainQuestionChangeEvent($event.checked);">
    <span class="form-section-title font-semi-bold">Upload High Risk Safe Work Method Statements(SWMS)</span>
  </mat-checkbox>
</section>

<div class="ps-4" *ngIf="mainQuestionChecked">
  <p class="mt-2 mb-2">Please tick applicable statements for your works and upload relevant documents.</p>
  <p class="mb-0 font-semi-bold">Does your organisation engage in construction work;</p>
  
  <section  *ngFor="let questionItem of mergedAnsQuestionList; index as i" class="my-3 d-flex align-items-start">
    <mat-checkbox
      class="check-box"
      [checked]="questionItem.isChecked"
      (change)="onSubQuestionChanageEvent(questionItem, $event.checked)"
      [disabled]="!isEditable">
    </mat-checkbox>
  
    <div class="ms-2">
      <p class="mb-2 text-small question-text font-semi-bold">{{questionItem.question.question}}</p> 
      <div class="document-wr" [class.d-none]="!questionItem.isChecked">
        <app-swms-file-list-widget 
          [businessInfoId]="businessInfoId"
          [questionId]="questionItem.question.id"
          [tradeId]="tradeId"
          [hideStatus]=false
          [sWMSQuestionItem]="questionItem"
          [sWMSDocuments]="questionItem?.answer ? questionItem?.answer?.swmsDetailModel: []"
          (updateDocumentEvent)="updateDocumentEvent($event, questionItem.question.id)"
          (removeDocumentEvent)="removeDocumentEvent($event, questionItem.question.id)"
          [isEditable]="isEditable">
        </app-swms-file-list-widget>
      </div>
    </div>
  </section>
</div>
