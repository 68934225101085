import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ModuleMatrixService } from '../module-matrix.service';
import { SharedModuleMatrixPreviewPopupComponent } from '../shared-module-matrix-preview-popup/shared-module-matrix-preview-popup.component';

@Component({
  selector: 'app-shared-module-matrix-widget',
  templateUrl: './shared-module-matrix-widget.component.html',
  styleUrls: ['./shared-module-matrix-widget.component.scss']
})
export class SharedModuleMatrixWidgetComponent implements OnInit {

  isLoading = false
  currentUserId!: string
  modulesList: any[] = []
  activeModuleList: any[] = []
  activeModule: any

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private moduleMatrixService: ModuleMatrixService
  ) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.currentUserValue.userId
    this.getModules(this.currentUserId)
  }

  private getModules(userId: string) {
    this.isLoading = true
    this.moduleMatrixService.getModulesByUserId(userId).subscribe({
      next: res => {
        if(res.success) {
          this.modulesList = res.data
          this.activeModuleList = this.modulesList?.filter( e => e.moduleMatrixModelList[0].moduleStatus == true )
          this.activeModule = this.activeModuleList[0]
        }
        this.isLoading = false
      }, error: err => {
        this.isLoading = false
      }
    })
  }

  viewModule(selectedModule: any) {
    this.activeModule = selectedModule
  }

  completeModule() {
    const dialogRef = this.dialog.open(SharedModuleMatrixPreviewPopupComponent, {
      maxWidth: '940px',
      width: '90%',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data: { module: this.activeModule },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.activeModule.isCompleted = true
      }
    });
  }

  viewModulePreview() {}
}
