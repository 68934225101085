<div class="d-flex justify-content-between align-items-center m-0 ps-4 pe-2 py-3">
  <p class="popup-header mb-0">{{ businessData.isAdd ? 'Add' : 'Change'}} Credit Card</p>
  <button mat-icon-button><mat-icon matDialogClose>cancel</mat-icon></button>
</div>

<mat-divider></mat-divider>
  
<div class="popup-wr px-4 pt-3 pb-1 d-flex justify-content-center">
  <form id="payment-form"
        class="row text-start mt-4" 
        [formGroup]="formPayment" 
        (ngSubmit)="onSubmit()" 
        action="/charge"
        method="post">
  
      <div class="col-12 mb-3">
        <div class="rate-selection-wr row">
          <div class="col-12 mb-3">
              <mat-form-field class="w-100" appearance="legacy">
                  <mat-label>Name on the card</mat-label>
                  <input matInput placeholder="Please enter name on the card" formControlName="name">
                  <mat-error *ngIf="f['name'].errors?.['required']">
                      Please enter name on the card.
                  </mat-error>
              </mat-form-field>
          </div>
          
          <div class="col-12 mb-3">
              <div class="col-lg-12 mb-4" [class.d-none]="isHide"> 
                  <mat-label class="text-md text-secondary">Card Number</mat-label>
                  <div id="card-element" class="custom-border"></div>
                  <div class="card-errors" id="card-errors" role="alert"></div>
                  <mat-label class="card-errors">{{cardError}}</mat-label>
                </div>
          </div>
      
          <div class="col-12 action-btns text-right w-100 mt-1">
              <div class="d-flex align-items-center justify-content-end">
                  <button class="btn primary w-mid" mat-flat-button [disabled]="isLoading || !isValid || !isCardComplete">Save Card</button>
                  <mat-spinner *ngIf="isLoading" class="ms-2" diameter="30"></mat-spinner>
              </div>
          </div>
        </div>
      </div>
    </form>
  </div>  