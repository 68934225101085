import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


// 3rd party
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CodeInputModule } from 'angular-code-input';

// Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// Components / Pipes
import { GetCategoryNamePipe } from './pipes/get-category-name.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { ArrayOrderPipe } from './pipes/array-order.pipe';
import { FormatCardNumberPipe } from './pipes/format-card-number.pipe';
import { CentsToDollerPipe } from './pipes/cents-to-doller.pipe';
import { SetCheckedPipe } from './pipes/set-checked.pipe';

import { SharedSelectCategoryWidgetComponent } from './components/widgets/shared-select-category-widget/shared-select-category-widget.component';
import { SharedServiceDocumentUploadPopupComponent } from './components/popups/shared-service-document-upload-popup/shared-service-document-upload-popup.component';
import { SharedUpdateNamePopupComponent } from './components/popups/shared-update-name-popup/shared-update-name-popup.component';
import { SharedUpdateEmailPopupComponent } from './components/popups/shared-update-email-popup/shared-update-email-popup.component';
import { SharedDigitalSignaturePopupComponent } from './components/popups/shared-digital-signature-popup/shared-digital-signature-popup.component';
import { SharedAbnInfoPopupComponent } from './components/popups/shared-abn-info-popup/shared-abn-info-popup.component';
import { SharedUpdateLocationPopupComponent } from './components/service-area/shared-update-location-popup/shared-update-location-popup.component';
import { SharedBusinessDocumentInfoPopupComponent } from './components/popups/shared-business-document-info-popup/shared-business-document-info-popup.component';
import { SharedUpdateServiceCategoryPopupComponent } from './components/popups/shared-update-service-category-popup/shared-update-service-category-popup.component';
import { SharedServiceDocumentListWidgetComponent } from './components/widgets/shared-service-document-list-widget/shared-service-document-list-widget.component';
import { StepperWidgetComponent } from './components/widgets/stepper-widget/stepper-widget.component';
import { SharedFileUploadWidgetComponent } from './components/widgets/shared-file-upload-widget/shared-file-upload-widget.component';
import { SharedConfirmationPopupComponent } from './components/popups/shared-confirmation-popup/shared-confirmation-popup.component';
import { SharedDocumentUploadPopupComponent } from './components/popups/shared-document-upload-popup/shared-document-upload-popup.component';
import { SharedBusinessDocumentUploadPopupComponent } from './components/popups/shared-business-document-upload-popup/shared-business-document-upload-popup.component';
import { SharedServiceDocumentWidgetComponent } from './components/widgets/shared-service-document-widget/shared-service-document-widget.component';
import { SharedBusinessDocumentWidgetComponent } from './components/widgets/shared-business-document-widget/shared-business-document-widget.component';
import { SharedImageUploadPopupComponent } from './components/popups/shared-image-upload-popup/shared-image-upload-popup.component';
import { SharedRatingsWidgetComponent } from './components/widgets/shared-ratings-widget/shared-ratings-widget.component';
import { FormatMobileNumberPipe } from './pipes/format-mobile-number.pipe';
import { GetCategoryIconPipe } from './pipes/get-category-icon.pipe';
import { GetPreferrenceAnswerPipe } from './pipes/get-preferrence-answer.pipe';
import { SharedUpdateAddressPopupComponent } from './components/popups/shared-update-address-popup/shared-update-address-popup.component';
import { SharedUpdatePhonePopupComponent } from './components/popups/shared-update-phone-popup/shared-update-phone-popup.component';
import { SharedUpdateBusinessNamePopupComponent } from './components/popups/shared-update-business-name-popup/shared-update-business-name-popup.component';
import { SharedUpdatePreferencesPopupComponent } from './components/popups/shared-update-preferences-popup/shared-update-preferences-popup.component';
import { SharedUpdateBankPopupComponent } from './components/popups/shared-update-bank-popup/shared-update-bank-popup.component';
import { SharedCategoryRatesWidgetComponent } from './components/widgets/shared-category-rates-widget/shared-category-rates-widget.component';
import { SharedUpdateBusinessAddressPopupComponent } from './components/popups/shared-update-business-address-popup/shared-update-business-address-popup.component';
import { SharedUpdateBusinessPhonePopupComponent } from './components/popups/shared-update-business-phone-popup/shared-update-business-phone-popup.component';
import { SharedUpdateBusinessEmailPopupComponent } from './components/popups/shared-update-business-email-popup/shared-update-business-email-popup.component';
import { SharedUploadUserDucumentPopupComponent } from './components/popups/shared-upload-user-ducument-popup/shared-upload-user-ducument-popup.component';
import { SharedUserDocumentWidgetComponent } from './components/widgets/shared-user-document-widget/shared-user-document-widget.component';
import { SharedVerifyMobileNumberPopupComponent } from './components/popups/shared-verify-mobile-number-popup/shared-verify-mobile-number-popup.component';
import { SharedMediaPreviewWidgetComponent } from './components/widgets/shared-media-preview-widget/shared-media-preview-widget.component';
import { SharedMediaPreviewPopupComponent } from './components/popups/shared-media-preview-popup/shared-media-preview-popup.component';
import { SharedJobProgressWidgetComponent } from './components/widgets/shared-job-progress-widget/shared-job-progress-widget.component';
import { SharedUserInfoWidgetComponent } from './components/widgets/shared-user-info-widget/shared-user-info-widget.component';
import { SharedDocumentStatusWidgetComponent } from './components/widgets/shared-document-status-widget/shared-document-status-widget.component';
import { SharedUpdateQuestionPopupComponent } from './components/popups/shared-update-question-popup/shared-update-question-popup.component';
import { SharedAddCreditCardPopupComponent } from './components/popups/shared-add-credit-card-popup/shared-add-credit-card-popup.component';
import { SharedSwmsDocsWidgetComponent } from './components/widgets/shared-swms-docs-widget/shared-swms-docs-widget.component';
import { SharedUpdateTeamMemberPopupComponent } from './components/popups/shared-update-team-member-popup/shared-update-team-member-popup.component';
import { SharedTeamDocumentUploadPopupComponent } from './components/popups/shared-team-document-upload-popup/shared-team-document-upload-popup.component';
import { SharedTeamUserDocumentWidgetComponent } from './components/widgets/shared-team-user-document-widget/shared-team-user-document-widget.component';
import { SharedCategoryCreationWidgetComponent } from './components/popups/shared-update-service-category-popup/shared-category-creation-widget/shared-category-creation-widget.component';
import { SharedCategoryDocumentCreationWidgetComponent } from './components/popups/shared-update-service-category-popup/shared-category-document-creation-widget/shared-category-document-creation-widget.component';
import { SharedWelcomePageComponent } from './components/shared-welcome-page/shared-welcome-page.component';
import { ComplianceStatusWidgetComponent } from './components/widgets/compliance-status-widget/compliance-status-widget.component';
import { SharedAlertPopupComponent } from './components/popups/shared-alert-popup/shared-alert-popup.component';
import { SharedTermsConditionsComponent } from './components/shared-terms-conditions/shared-terms-conditions.component';
import { SharedMemberTermsConditionsComponent } from './components/shared-member-terms-conditions/shared-member-terms-conditions.component';

import { UserFileListWidgetComponent } from './components/file-upload/user-files/user-file-list-widget/user-file-list-widget.component';
import { UserFileUploadPopupComponent } from './components/file-upload/user-files/user-file-upload-popup/user-file-upload-popup.component';
import { HealthFileListWidgetComponent } from './components/file-upload/health-files/health-file-list-widget/health-file-list-widget.component';
import { HealthFileUploadPopupComponent } from './components/file-upload/health-files/health-file-upload-popup/health-file-upload-popup.component';
import { BusinessFileListWidgetComponent } from './components/file-upload/business-files/business-file-list-widget/business-file-list-widget.component';
import { BusinessFileUploadPopupComponent } from './components/file-upload/business-files/business-file-upload-popup/business-file-upload-popup.component';
import { HealthAndSafetyFileListWidgetComponent } from './components/file-upload/health-and-safety-files/health-and-safety-file-list-widget/health-and-safety-file-list-widget.component';
import { HealthAndSafetyFileUploadPopupComponent } from './components/file-upload/health-and-safety-files/health-and-safety-file-upload-popup/health-and-safety-file-upload-popup.component';
import { IndemnityFileListWidgetComponent } from './components/file-upload/profetional-indemnity-files/indemnity-file-list-widget/indemnity-file-list-widget.component';
import { IndemnityFileUploadPopupComponent } from './components/file-upload/profetional-indemnity-files/indemnity-file-upload-popup/indemnity-file-upload-popup.component';
import { SwmsFileListWidgetComponent } from './components/file-upload/swms-files/swms-file-list-widget/swms-file-list-widget.component';
import { SwmsFileUploadPopupComponent } from './components/file-upload/swms-files/swms-file-upload-popup/swms-file-upload-popup.component';
import { LiabilityFileListWidgetComponent } from './components/file-upload/liability-files/liability-file-list-widget/liability-file-list-widget.component';
import { LiabilityFileUploadPopupComponent } from './components/file-upload/liability-files/liability-file-upload-popup/liability-file-upload-popup.component';

import { SharedServiceAreaWidgetComponent } from './components/service-area/shared-service-area-widget/shared-service-area-widget.component';
import { SharedServiceAreaItemWidgetComponent } from './components/service-area/shared-service-area-item-widget/shared-service-area-item-widget.component';

// ngx-currency
import { NgxCurrencyModule, CurrencyMaskInputMode } from "ngx-currency";
import { MatChipsModule } from '@angular/material/chips';

import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedImageCropperPopupComponent } from './components/popups/shared-image-cropper-popup/shared-image-cropper-popup.component';
import { SharedPreferencesTextInputWidgetComponent } from './components/widgets/shared-preferences-text-input-widget/shared-preferences-text-input-widget.component';
import { MatchedEmailValidatorDirective } from './directives/matched-email-validator.directive';
import { ServiceCategoryStatusNoteWidgetComponent } from './components/widgets/service-category-status-note-widget/service-category-status-note-widget.component';
import { SharedUpdateServiceCategorySpecialitiesPopupComponent } from './components/popups/shared-update-service-category-specialities-popup/shared-update-service-category-specialities-popup.component';
import { SharedAddLocationWidgetComponent } from './components/service-area/shared-add-location-widget/shared-add-location-widget.component';
import { SharedUpdateClassificationTypePopupComponent } from './components/popups/shared-update-classification-type-popup/shared-update-classification-type-popup.component';
import { SharedCategoryClassificationsWidgetComponent } from './components/widgets/shared-category-classifications-widget/shared-category-classifications-widget.component';
import { SharedServiceDocumentLinkedWidgetComponent } from './components/widgets/shared-service-document-linked-widget/shared-service-document-linked-widget.component';
import { UpdateBusinessContactNamePopupComponent } from './components/popups/update-business-contact-name-popup/update-business-contact-name-popup.component';
import { UpdateBusinessContactMobilePopupComponent } from './components/popups/update-business-contact-mobile-popup/update-business-contact-mobile-popup.component';
import { SharedModuleMatrixWidgetComponent } from './components/module-matrix/shared-module-matrix-widget/shared-module-matrix-widget.component';
import { LinkSafePipe } from './pipes/link-safe.pipe';
import { SharedModuleMatrixPreviewPopupComponent } from './components/module-matrix/shared-module-matrix-preview-popup/shared-module-matrix-preview-popup.component';
import { NdisFileListWidgetComponent } from './components/file-upload/ndis-files/ndis-file-list-widget/ndis-file-list-widget.component';
import { NdisFileUploadPopupComponent } from './components/file-upload/ndis-files/ndis-file-upload-popup/ndis-file-upload-popup.component';
import { NdisOnboardingFileListWidgetComponent } from './components/file-upload/ndis-files/ndis-onboarding-file-list-widget/ndis-onboarding-file-list-widget.component';
import { NdisFileUploadWidgetComponent } from './components/file-upload/ndis-files/ndis-file-upload-widget/ndis-file-upload-widget.component';
import { GetStateNameByTypePipe } from './pipes/get-state-name-by-type.pipe';
import { NdisDocumentWidgetComponent } from './components/file-upload/ndis-files/ndis-document-widget/ndis-document-widget.component';
import { SharedUpdatePaidNowInvoiceComponent } from './components/popups/shared-update-paid-now-invoice/shared-update-paid-now-invoice.component';
import { MatStepperModule } from '@angular/material/stepper';


@NgModule({
  declarations: [
    StepperWidgetComponent,
    SharedFileUploadWidgetComponent,
    SharedDocumentUploadPopupComponent,
    SharedConfirmationPopupComponent,
    SharedSelectCategoryWidgetComponent,
    GetCategoryNamePipe,
    SharedBusinessDocumentWidgetComponent,
    ArrayOrderPipe,
    FormatCardNumberPipe,
    SharedServiceDocumentUploadPopupComponent,
    SharedUpdateNamePopupComponent,
    SharedUpdateEmailPopupComponent,
    SharedDigitalSignaturePopupComponent,
    SharedAbnInfoPopupComponent,
    SharedUpdateLocationPopupComponent,
    SharedBusinessDocumentInfoPopupComponent,
    SharedUpdateServiceCategoryPopupComponent,
    SharedUpdateServiceCategorySpecialitiesPopupComponent,
    SharedServiceDocumentListWidgetComponent,
    SharedImageUploadPopupComponent,
    SharedRatingsWidgetComponent,
    FormatMobileNumberPipe,
    GetCategoryIconPipe,
    GetPreferrenceAnswerPipe,
    SharedUpdateAddressPopupComponent,
    SharedUpdatePhonePopupComponent,
    SharedUpdateBusinessNamePopupComponent,
    SharedUpdatePreferencesPopupComponent,
    SharedUpdateBankPopupComponent,
    SharedCategoryRatesWidgetComponent,
    SharedBusinessDocumentUploadPopupComponent,
    SharedServiceDocumentWidgetComponent,
    SharedBusinessDocumentWidgetComponent,
    SharedUpdateBusinessAddressPopupComponent,
    SharedUpdateBusinessPhonePopupComponent,
    SharedUpdateBusinessEmailPopupComponent,
    SharedUploadUserDucumentPopupComponent,
    SharedUserDocumentWidgetComponent,
    SharedVerifyMobileNumberPopupComponent,
    SharedMediaPreviewWidgetComponent,
    SharedMediaPreviewPopupComponent,
    SharedJobProgressWidgetComponent,
    SharedUserInfoWidgetComponent,
    SharedDocumentStatusWidgetComponent,
    SharedUpdateQuestionPopupComponent,
    SharedAddCreditCardPopupComponent,
    SharedSwmsDocsWidgetComponent,
    SharedUpdateTeamMemberPopupComponent,

    SharedTeamDocumentUploadPopupComponent,
    SharedTeamUserDocumentWidgetComponent,
    SharedCategoryCreationWidgetComponent,
    SharedCategoryDocumentCreationWidgetComponent,
    SharedWelcomePageComponent,
    ComplianceStatusWidgetComponent,
    UserFileListWidgetComponent,
    UserFileUploadPopupComponent,
    SharedTermsConditionsComponent,
    HealthFileListWidgetComponent,
    HealthFileUploadPopupComponent,
    SharedAlertPopupComponent,
    BusinessFileListWidgetComponent,
    BusinessFileUploadPopupComponent,
    HealthAndSafetyFileListWidgetComponent,
    HealthAndSafetyFileUploadPopupComponent,
    IndemnityFileListWidgetComponent,
    IndemnityFileUploadPopupComponent,
    SwmsFileListWidgetComponent,
    SwmsFileUploadPopupComponent,
    LiabilityFileListWidgetComponent,
    LiabilityFileUploadPopupComponent,
    CentsToDollerPipe,
    SharedMemberTermsConditionsComponent,
    SharedImageCropperPopupComponent,
    SharedPreferencesTextInputWidgetComponent,
    SetCheckedPipe,
    MatchedEmailValidatorDirective,
    ServiceCategoryStatusNoteWidgetComponent,
    SharedServiceAreaWidgetComponent,
    SharedServiceAreaItemWidgetComponent,
    SharedAddLocationWidgetComponent,
    SharedUpdateClassificationTypePopupComponent,
    SharedCategoryClassificationsWidgetComponent,
    SharedServiceDocumentLinkedWidgetComponent,
    UpdateBusinessContactNamePopupComponent,
    UpdateBusinessContactMobilePopupComponent,
    SharedModuleMatrixWidgetComponent,
    LinkSafePipe,
    SharedModuleMatrixPreviewPopupComponent,
    NdisFileListWidgetComponent,
    NdisFileUploadPopupComponent,
    NdisOnboardingFileListWidgetComponent,
    NdisFileUploadWidgetComponent,
    GetStateNameByTypePipe,
    NdisDocumentWidgetComponent,
    SharedUpdatePaidNowInvoiceComponent
  ],
  imports: [
    CommonModule,
    CdkStepperModule,
    FormsModule,
    ReactiveFormsModule,
    
    // 3rd party
    NgbModule,
    CodeInputModule,

    // Material
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatRadioModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatStepperModule,
  
    // Other
    ImageCropperModule,
    SignaturePadModule,
    AgmCoreModule.forRoot({
      apiKey: environment.map.googleMapsKey,
      libraries: ['geometry', 'places']
    }),

    // ngx-currency
    NgxCurrencyModule.forRoot({
      align: "left",
      allowNegative: false,
      allowZero: false,
      decimal: ".",
      precision: 2,
      prefix: "", // prefix: "R$ ",
      suffix: "",
      thousands: ",",
      nullable: false,
      inputMode: CurrencyMaskInputMode.FINANCIAL
    }),
  ],
  exports: [
    StepperWidgetComponent,
    SharedFileUploadWidgetComponent,
    SharedDocumentUploadPopupComponent,
    SharedConfirmationPopupComponent,
    SharedSelectCategoryWidgetComponent,
    GetCategoryNamePipe,
    ArrayOrderPipe,
    FormatCardNumberPipe,
    SharedServiceDocumentUploadPopupComponent,
    SharedUpdateNamePopupComponent,
    SharedUpdateEmailPopupComponent,
    SharedDigitalSignaturePopupComponent,
    SharedAbnInfoPopupComponent,
    SharedUpdateLocationPopupComponent,
    SharedBusinessDocumentInfoPopupComponent,
    SharedUpdateServiceCategoryPopupComponent,
    SharedUpdateServiceCategorySpecialitiesPopupComponent,
    SharedServiceDocumentListWidgetComponent,
    SharedRatingsWidgetComponent,
    SharedCategoryRatesWidgetComponent,
    SharedBusinessDocumentWidgetComponent,
    SharedUpdateBusinessAddressPopupComponent,
    SharedUpdateBusinessPhonePopupComponent,
    SharedUpdateBusinessEmailPopupComponent,
    SharedUploadUserDucumentPopupComponent,
    SharedUserDocumentWidgetComponent,
    SharedVerifyMobileNumberPopupComponent,
    SharedDocumentStatusWidgetComponent,
    SharedUpdateQuestionPopupComponent,
    SharedSwmsDocsWidgetComponent,
    SharedTeamUserDocumentWidgetComponent,
    ComplianceStatusWidgetComponent,
    UserFileListWidgetComponent,
    SharedTermsConditionsComponent,
    HealthFileListWidgetComponent,
    SharedAlertPopupComponent,
    BusinessFileListWidgetComponent,
    HealthAndSafetyFileListWidgetComponent,

    IndemnityFileListWidgetComponent,
    IndemnityFileUploadPopupComponent,

    SwmsFileListWidgetComponent,
    SwmsFileUploadPopupComponent,

    LiabilityFileListWidgetComponent,
    LiabilityFileUploadPopupComponent,

    NdisFileListWidgetComponent,
    NdisFileUploadPopupComponent,

    //Pipes
    FormatMobileNumberPipe,
    GetCategoryIconPipe,
    GetPreferrenceAnswerPipe,
    CentsToDollerPipe,
    SetCheckedPipe,
    LinkSafePipe,
    GetStateNameByTypePipe,

    // Jobs
    SharedMediaPreviewWidgetComponent,
    SharedJobProgressWidgetComponent,
    SharedUserInfoWidgetComponent,
    SharedWelcomePageComponent,
    SharedMemberTermsConditionsComponent,
    SharedImageCropperPopupComponent,
    SharedPreferencesTextInputWidgetComponent,
    ServiceCategoryStatusNoteWidgetComponent,
    SharedServiceAreaWidgetComponent,
    SharedCategoryClassificationsWidgetComponent,
    SharedServiceDocumentLinkedWidgetComponent,
    SharedModuleMatrixWidgetComponent,
    NdisOnboardingFileListWidgetComponent
  ],
})
export class SharedModule {}
