export const environment = {
  production: false,
  endpointURL: 'https://gateway.fixby.xigenix.com', // Development API

  config : {
    location: 'xigenix-fixby',
    key: 'PnzDdD7cUqMDKXEFcKsKcwBe7NpQcuGtuOjlMkmM',
    keyId: 'AKIAQWL5IXWYYDYT3ZFW',
    region: 'ap-southeast-2',
    expireTimeInMinutes: 1,  // ( default : file urls expires in 1 min )
  },

  map: {
    googleMapsKey: 'AIzaSyBs3KBTiJ8XwjZqOfZhWDqEklq3i4EwOdA',
    geoLocations: {
      australia: {latitude: -26.01042, longitude: 133.9408053}
    },
    zoom: 4
  },

  stripe : { 
    key : 'pk_test_51IlmLpKueDECEAqNYprBWgAoPQzvXfNozDyIah0m7YHDaJz1ZtQ0dmjYhLqRNSld1dZGuXhGZfcPQtlLF2jZfUCC008BPQyWds'
  },

  ndisScreeningUrls : {
     VIC: 'https://www.service.vic.gov.au/services/national-disability-insurance-scheme',
     NSW: 'https://www.service.nsw.gov.au/ndiswc',
     SA: 'https://www.sa.gov.au/topics/rights-and-law/rights-and-responsibilities/screening-checks/screening-ndis',
     QLD: 'https://workerscreening.dsdsatsip.qld.gov.au',
     WA: 'https://www.wa.gov.au/organisation/department-of-communities/applying-ndis-check#how-to-apply'
  },
  ndisWorkingUrls : {
    VIC: 'https://www.vic.gov.au/working-with-children-check',
    NSW: 'https://www.service.nsw.gov.au/transaction/apply-for-a-working-with-children-check',
    SA: 'https://www.sa.gov.au/topics/rights-and-law/rights-and-responsibilities/screening-checks/screening-wwcc',
    QLD: 'https://ablis.business.gov.au/service/qld/blue-card-system-for-child-related-employment-and-businesses-also-known-as-the-working-with-children-check/4234',
    WA: 'https://www.wa.gov.au/organisation/department-of-communities/working-children-check-application-and-renewal-process'
  },

  showCompliance: true,
  isTestEnvironment: true 
};