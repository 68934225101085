import { Component, Input, OnChanges } from '@angular/core';
import { DocumentStatus } from 'src/app/core/enums/document.enum';
import { TradieService } from '../../../services/tradie/tradie.service';
@Component({
  selector: 'app-shared-service-document-linked-widget',
  templateUrl: './shared-service-document-linked-widget.component.html',
  styleUrls: ['./shared-service-document-linked-widget.component.scss']
})
export class SharedServiceDocumentLinkedWidgetComponent implements OnChanges{

  @Input() businessInfoId!: string
  @Input() serviceCategoryId!: string
  isLoading = false
  linkedDocumentsList: any[] =[]
  documentStatus = DocumentStatus
  
  constructor(private tradieService: TradieService) { }

  ngOnChanges(): void {
    this.getLinkedDocumentsByCategory(this.businessInfoId, this.serviceCategoryId)
  }

  private getLinkedDocumentsByCategory(businessInfoId: string , serviceCategoryId: string) {
    this.isLoading = true
    this.tradieService.getBusinessServiceDocumentLink(businessInfoId, serviceCategoryId).subscribe({
      next: response => {
        this.linkedDocumentsList = response.data       
        this.linkedDocumentsList = this.linkedDocumentsList.filter( e => e.serviceCategoryName != null)
        this.isLoading = false
      }, error: err => { }
    })
  }
}
